import Auth from './Auth';
import User from './User';
import PropertyProfile from './PropertyProfile';
import Persiana from './Persiana';
import Toldo90 from './Toldo90';
import ToldoVertical from './ToldoVertical';
import ToldoProyeccion from './ToldoProyeccion';
import Cortina from './Cortina';

const auth = new Auth();
const user = new User();
const propertyProfile = new PropertyProfile();
const persiana = new Persiana();
const toldo90 = new Toldo90();
const toldoVertical = new ToldoVertical();
const toldoProyeccion = new ToldoProyeccion();
const cortina = new Cortina();

export {
    auth,
    user,
    propertyProfile,
    persiana,
    toldo90,
    toldoVertical,
    toldoProyeccion,
    cortina
};

export default {
    auth,
    user,
    propertyProfile,
    persiana,
    toldo90,
    toldoVertical,
    toldoProyeccion,
    cortina
};
