import { useState } from "react";
import { StyleSheet, useWindowDimensions, Linking } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Colors } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import User from "../../models/User";
import { BackButton } from "../../components/Layout/Header";
import { StyledView, StyledButton, StyledIcon } from "../../components/UI/Styled";

import { Wrapper, Screen, Container, H4, LoadingModal } from "../../components/UI";
import { StyledModal } from "../../components/UI/Styled";
import PriceForm from "../../components/Profile/PriceForm";
import { autoLogin, refreshToken, logout } from "../../store/actions/auth";

const PricesScreen = ({ navigation }) => {
    const userAuth = useSelector((state) => state.auth);
    const accessToken = useSelector((state) => state.auth.token);

    const layout = useWindowDimensions();

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [error, setError] = useState(null);

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const dispatch = useDispatch();

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const { translate } = useLanguage();

    const updatePrices = async (document) => {
        try {
            toggleIsLoading();
            const data = new FormData();
            data.append("file", document.file);
            await user.sendPricesFile(data);
            toggleIsLoading();

            setAlert(translate("sentence", "SAVED"));
        } catch (e) {
            if (e?.response.status == 406) {
                toggleIsLoading();
                setError(e?.response?.data);
            } else {
                toggleIsLoading();
                setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            }
        }
    };

    const getFile = async () => {
        try {
            toggleIsLoading();
            var token = accessToken;
            try {
                await dispatch(autoLogin(accessToken));
            } catch (e) {
                try {
                    var response = await dispatch(refreshToken(userAuth.expirationTime, userAuth.refresh));
                    token = response;
                } catch (error) {
                    await dispatch(logout());
                }
            }
            var url = user.config.baseURL + "/" + user.customRoutes.downloadExampleFile.url;
            Linking.openURL(url + "?auth_token=" + token).catch((err) =>
                console.error("Ocurrio un error intente más tarde", err)
            );
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            toggleIsLoading();
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
        }
    };

    return (
        <Wrapper whiteNavbar={true}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!alert}
                size={layout.width > 768 ? "medium" : "regular"}
                title={translate("word", "INFO")}
                titleColor="info"
                onAccept={removeAlert}
                onPressOut={removeAlert}
                onRequestClose={removeAlert}
            >
                {alert}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!error}
                size={layout.width > 768 ? "medium" : "regular"}
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={styles.screen}>
                <Container style={styles.container}>
                    <StyledView style={styles.w100} flexDirection="row" justifyContent="space-between">
                        <StyledView style={styles.backButton}>
                            <BackButton style={styles.backButton} navigation={navigation} />
                        </StyledView>
                        <StyledView style={styles.exampleFileDiv}>
                            <StyledButton onPress={getFile} style={styles.exampleFile} buttonType="white" textSize={14}>
                                <StyledIcon name="download" iconType="FontAwesome" color="nummaBlue" size={14} />{" "}
                                {translate("word", "EXAMPLE_FILE")}
                            </StyledButton>
                        </StyledView>
                    </StyledView>
                    <H4 style={layout > 768 ? styles.h4 : styles.h4Mobile}>{translate("word", "PRICES")}</H4>
                    <PriceForm onSubmit={updatePrices} />
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    h4: {
        width: "100%",
        paddingHorizontal: Spacing.screen.paddingHorizontal,
        marginBottom: 10,
    },
    accountSettings: {
        marginTop: 30,
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    accountSettingsSectionContainer: {
        paddingHorizontal: 0,
    },
    accountSettingsItemContainer: {
        paddingHorizontal: 0,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    backButton: {
        alignSelf: "start",
    },
    h4Mobile: {
        width: "100%",
        paddingHorizontal: Spacing.screen.paddingHorizontal,
        marginTop: 48,
        textAlign: "center",
        marginBottom: 10,
    },
    exampleFileDiv: {
        alignSelf: "end",
    },
    exampleFile: {},
    w100: {
        width: "100%",
    },
});

export default PricesScreen;
