import Model from './Model';

export default class Cortina extends Model {
    boot() {
        super.boot();

        this.modelName = 'cortinas';
        this.config.customRoutes = [
            {
                name: 'update',
                type: 'patch',
                url: 'cortinas/{id}/'
            }
        ]
    }

    update = (id, params) => {
        return this.withParams(params).route('update', { id });
    };

}
