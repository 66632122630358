/* Common */

const SEE_ALL = 'Ver todas';

const SEE_MORE = 'Ver más';

const UNDER_CONSTRUCTION = 'En construcción';

const RESULTS_FOUND = 'Resultados encontrados';

const OUR_SUGGESTIONS = 'Nuestras sugerencias';

const MORE_RESULTS = 'Más resultados';

const LOAD_MORE_RESULTS = 'Cargar más resultados';

const USE_AS_DEFAULT = 'Usar como predeterminado';

/* Authentication */

const LOGIN = 'Iniciar sesión';

const WELCOME_BACK = 'Bienvenido de vuelta!';

const SIGN_IN = 'Iniciar sesión';

const LOG_IN = 'Entrar';

const SIGN_UP = 'Regístrate';

const SIGN_UP_TITLE = 'Registro';

const SIGN_OUT = 'Cerrar sesión';

const LOG_OUT = 'Cerrar sesión';

const REMEMBER_ME = 'Recordarme';

const FORGOT_PASSWORD = '¿Olvidaste tu contraseña?';

const FORGOT_MY_PASSWORD = 'Olvidé mi contraseña';

const DONT_HAVE_AN_ACCOUNT = '¿Nuevo miembro?';

const CREATE_AN_ACCOUNT = 'Crear una cuenta para poder ingresar';

const CREATE_ACCOUNT = 'Crear cuenta';

const ALREADY_REGISTERED = '¿Ya tienes una cuenta?';

const I_HAVE_READ_AND_AGREE_TO_THE = 'He leído y estoy de acuerdo con los';

const TERMS_OF_SERVICE = 'Términos y Condiciones';

const VERIFY_YOUR_ACCOUNT = 'Verifica tu cuenta';

const WE_HAVE_SENT_AN_EMAIL_TO = 'Hemos enviado un correo electrónico a';

const YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN =
    'Necesitas verificar tu cuenta para iniciar sesión.';

const IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL =
    'Si no ha recibido el correo electrónico de verificación, verifique su carpeta de "Spam" o "Correo no deseado". También puede hacer clic en el botón reenviar de abajo para recibir otro correo electrónico.';

const RESET_YOUR_PASSWORD = 'Restablece tu contraseña';

const RECOVERY_CODE = 'Código de recuperación';

const REMAINING_ATTEMPTS = 'Intentos restantes';

const RESET_PASSWORD = 'Restablecer contraseña';

const AUTHENTICATION_NEEDED_TO_ACCESS_SECTION =
    'Es necesario iniciar sesión para acceder a esta sección.';

/* Profile */

const MY_PROFILE = 'Mi perfil';

const MY_ACCOUNT = 'Mi cuenta';

const PERSONAL_INFORMATION = 'Información personal';

const PAYMENT_METHODS = 'Métodos de pago';

const CHANGE_PROFILE_PICTURE = 'Cambiar foto de perfil';

const ARE_YOU_SURE_YOU_WANT_TO_LOGOUT =
    '¿Estás seguro que quieres cerrar sesión?';

const SAVE = 'Guardar';

/* Projects*/

const ADD_PRODUCT = "Agregar producto";

const UPDATE_PRODUCT = "Actualizar producto";

const ADD_PROJECT = "Agregar proyecto";

const OWN_PROJECTS = "Mis proyectos";

const EDIT = "Editar";

const SEE = "Ver";

const DOWNLOAD_PDF = "PDF";

const DOWNLOAD_QUOTE_PDF = "Cotización";

const ESTATUS = "Estatus";

const PRODUCT = "Producto";

const QUANTITY = "Cantidad";

const AREA = "Area";

const COST = "Costo";

const CREATE_PROJECT = "Crear proyecto";

const UPDATE_PROJECT = "Actualizar proyecto";

const PLEASE_ENTER_PROJECT_NAME = "Por favor ingrese el nombre del proyecto"

const PLEASE_ENTER_CLIENT_NAME = "Por favor ingrese el nombre del cliente"

const PLEASE_ENTER_CLIENT_PHONE = "Por favor ingrese el teléfono del cliente"

const PLEASE_ENTER_CLIENT_EMAIL = "Por favor ingrese el email del cliente"

const PLEASE_ENTER_CLIENT_STREET = "Por favor ingrese la calle del cliente"

const PLEASE_ENTER_CLIENT_NUMBER = "Por favor ingrese el numero del cliente"

const PLEASE_ENTER_CLIENT_CITY = "Por favor ingrese la ciudad del cliente"

const PLEASE_ENTER_CLIENT_SUBURB = "Por favor ingrese la colonia del cliente"

const PLEASE_ENTER_CLIENT_STATE = "Por favor ingrese el estado del cliente"

const PLEASE_ENTER_CLIENT_ZIP_CODE = "Por favor ingrese el código postal del cliente"

const PLEASE_ENTER_CLIENT_DIRECTION = "Por favor ingrese la dirección del cliente"

const PLEASE_ENTER_A_VALID_NUMBER = 'Por favor, ingrese un número válido.';

const ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT = '¿Estás seguro que quieres eliminar este producto?';

const PLEASE_ENTER_VALUE_BETWEEN = 'Por favor, ingrese un valor entre ';

const MAX_LENGTH = 'Por favor, ingrese un texto mas corto. Max 1500. ';

const MAX_LENGTH_240 = 'Por favor, ingrese un texto mas corto. Max 240. ';

/* Company */

const TERMS_AND_CONDITIONS = 'Términos y condiciones';

/* Account */

const FULL_NAME = 'Nombre completo';

const DATE_OF_BIRTH = 'Fecha de nacimiento';

/* Settings */

const TIME_ZONE = 'Zona horaria';

/* Validations */

const PLEASE_ENTER_THIS_FIELD = 'Por favor, ingrese este campo.';

const PLEASE_ENTER_YOUR_EMAIL = 'Por favor, ingrese su correo electrónico.';

const PLEASE_ENTER_YOUR_PASSWORD = 'Por favor, ingrese su contraseña.';

const PLEASE_ENTER_YOUR_RECOVERY_CODE =
    'Por favor, ingrese el código de recuperación de 6 dígitos.';

const PLEASE_ENTER_YOUR_VERIFICATION_CODE =
    'Por favor, ingrese el código de verificación de 6 dígitos.';

const PLEASE_ENTER_YOUR_PHONE_NUMBER =
    'Por favor, ingrese su número telefónico.';

const PLEASE_ENTER_YOUR_FULL_NAME = 'Por favor, ingrese su nombre completo.';

const PLEASE_ENTER_YOUR_FIRST_NAME = 'Por favor, ingrese su nombre.';

const PLEASE_ENTER_YOUR_LAST_NAME = 'Por favor, ingrese su apellido.';

const PLEASE_ENTER_YOUR_SUBJECT = 'Por favor, ingrese su asunto.';

const PLEASE_ENTER_YOUR_MESSAGE = 'Por favor, ingrese su mensaje.';

const PLEASE_SELECT_YOUR_LANGUAGE = 'Por favor, seleccione su lenguaje.';

const PLEASE_SELECT_YOUR_TIME_ZONE = 'Por favor, seleccione su zona horaria.';

const PLEASE_SELECT_YOUR_CURRENCY = 'Por favor, seleccione su moneda.';

const PLEASE_SELECT_YOUR_COUNTRY = 'Por favor, seleccione su país.';

const PLEASE_SELECT_YOUR_STATE = 'Por favor, seleccione su estado.';

const PLEASE_SELECT_YOUR_CITY = 'Por favor, seleccione su ciudad.';

const PLEASE_ENTER_A_VALID_EMAIL =
    'Por favor, ingrese un correo electrónico válido.';

const PLEASE_ENTER_A_VALID_PHONE_NUMBER =
    'Por favor, ingrese un número telefónico válido.';

const PLEASE_ENTER_A_VALID_CARD_NUMBER =
    'Por favor, ingrese un número de tarjeta válido.';

const PLEASE_ENTER_A_VALID_RFC = 'Por favor, ingrese un RFC válido. Ej. ABCD123456XX0';

const PLEASE_ENTER_A_MODERATE_PASSWORD =
    'Por favor, ingrese una contraseña de al menos 8 caracteres con 1 letra minúscula, 1 letra mayúscula y 1 número.';

const PLEASE_ENTER_A_COMPLEX_PASSWORD =
    'Por favor, ingrese una contraseña de al menos 8 caracteres con 1 letra minúscula, 1 letra mayúscula, 1 número y un caracter especial.';

const PASSWORDS_DO_NOT_MATCH = 'Las contraseñas no coinciden.';

const WRONG_CURRENT_PASSWORD = 'Contraseña actual incorrecta.';

/* Alerts */

const SAVED = 'Datos guardados.';

const SOMETHING_WENT_WRONG = 'Algo salió mal.';

const INVALID_USERNAME_OR_PASSWORD = 'Usuario o contraseña incorrectos.';

const INVALID_EMAIL_OR_PASSWORD = 'Correo o contraseña incorrectos.';

const EMAIL_IS_ALREADY_REGISTERED = 'El correo electrónico ya está registrado.';

const UNREGISTERED_EMAIL = 'El correo electrónico no está registrado.';

const VERIFICATION_EMAIL_HAS_BEEN_RESENT =
    'Se ha reenvíado el correo de verificación.';

const RECOVERY_CODE_HAS_BEEN_RESENT =
    'Se ha reenvíado el código de recuperación.';

const YOUR_RECOVERY_CODE_IS_WRONG = 'Tu código de recuperación es incorrecto.';

const YOUR_VERIFICATION_CODE_IS_WRONG =
    'Tu código de verificación es incorrecto.';

const QUALITY_RECOMMENDED =
    'Se recomienda seleccionar una calidad para poder buscar los inmuebles que mejor se adapten a tus necesidades.';

const DO_YOU_WANT_TO_CONTINUE_ANYWAY = '¿Desea continuar de todas formas?';

const NO_PROPERTIES = 'Sin propiedades.';

const NO_PROPERTIES_FOUND =
    'Parece que no podemos encontrar ninguna propiedad que coincida con su búsqueda.';

const PLEASE_LOGIN_TO_CONTINUE = 'Por favor, inicia sesión para continuar.';

const OFFER_SENT_TO_PROPERTY_OWNER =
    'Se ha enviado tu oferta al dueño de la propiedad.';

const PROPERTY_ADDED = 'Propiedad agregada con éxito.';

const PROPERTY_CODE_COPIED = 'Código de la propiedad copiado.';

const ACCEPT_PROPERTY_APPOINTMENT = 'Aceptar cita de propiedad.';

const REJECT_PROPERTY_APPOINTMENT = 'Rechazar cita de propiedad.';

const ACCEPT_PROPERTY_OFFER = 'Aceptar oferta de propiedad.';

const REJECT_PROPERTY_OFFER = 'Rechazar oferta de propiedad.';

const PROPERTY_APPOINTMENT_ACCEPTED = 'Cita de propiedad aceptada.';

const PROPERTY_APPOINTMENT_REJECTED = 'Cita de propiedad rechazada.';

const PROPERTY_OFFER_ACCEPTED = 'Oferta de propiedad aceptada.';

const PROPERTY_OFFER_REJECTED = 'Oferta de propiedad rechazada.';

const THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL =
    'Los archivos se enviarán a tu correo electrónico.';

const THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL =
    'Los archivos han sido envíados a tu correo electrónico.';

const SERVICE_NOT_FOUND = 'Servicio no encontrado.';

const SERVICE_PLAN_PROPERTY_AD_WARNING =
    'Recuerda que el bloque seleccionado debe ser lo más cercano a las características de tu propiedad.\n\nSi el tiempo contratado no es suficiente para la recoleccion de informacion puede representar atrasos en el servicio.';

const NO_CARD_FOUND = 'No se encontró ninguna tarjeta.';

const CVC_DESCRIPTION =
    'El código CVC es un grupo de 3 o 4 números situado en el reverso de la tarjeta bancaria.';

const CVC_REQUIRED =
    'Esta información es requerida para realizar la transacción en línea y no es almacenada en ningún momento.';

const INVOICE_INFO_NOT_FOUND = 'No se encontró información de facturación.';

const FILE_REQUIRED = 'Archivo obligatorio.';

const PRICES_UPLOAD = 'Actualizar lista de precios';

export {
    SEE_ALL,
    SEE_MORE,
    UNDER_CONSTRUCTION,
    RESULTS_FOUND,
    OUR_SUGGESTIONS,
    MORE_RESULTS,
    LOAD_MORE_RESULTS,
    USE_AS_DEFAULT,
    LOGIN,
    WELCOME_BACK,
    SIGN_IN,
    LOG_IN,
    SIGN_UP,
    SIGN_UP_TITLE,
    SIGN_OUT,
    LOG_OUT,
    REMEMBER_ME,
    FORGOT_PASSWORD,
    FORGOT_MY_PASSWORD,
    DONT_HAVE_AN_ACCOUNT,
    CREATE_AN_ACCOUNT,
    CREATE_ACCOUNT,
    ALREADY_REGISTERED,
    I_HAVE_READ_AND_AGREE_TO_THE,
    TERMS_OF_SERVICE,
    VERIFY_YOUR_ACCOUNT,
    WE_HAVE_SENT_AN_EMAIL_TO,
    YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN,
    IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL,
    RESET_YOUR_PASSWORD,
    RECOVERY_CODE,
    REMAINING_ATTEMPTS,
    RESET_PASSWORD,
    AUTHENTICATION_NEEDED_TO_ACCESS_SECTION,
    MY_PROFILE,
    MY_ACCOUNT,
    PERSONAL_INFORMATION,
    PAYMENT_METHODS,
    CHANGE_PROFILE_PICTURE,
    ARE_YOU_SURE_YOU_WANT_TO_LOGOUT,
    SAVE,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    ADD_PROJECT,
    OWN_PROJECTS,
    EDIT,
    SEE,
    DOWNLOAD_PDF,
    DOWNLOAD_QUOTE_PDF,
    ESTATUS,
    PRODUCT,
    QUANTITY,
    AREA,
    COST,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    PLEASE_ENTER_PROJECT_NAME,
    PLEASE_ENTER_CLIENT_NAME,
    PLEASE_ENTER_CLIENT_PHONE,
    PLEASE_ENTER_CLIENT_EMAIL,
    PLEASE_ENTER_CLIENT_STREET,
    PLEASE_ENTER_CLIENT_NUMBER,
    PLEASE_ENTER_CLIENT_CITY,
    PLEASE_ENTER_CLIENT_SUBURB,
    PLEASE_ENTER_CLIENT_STATE,
    PLEASE_ENTER_CLIENT_ZIP_CODE,
    PLEASE_ENTER_CLIENT_DIRECTION,
    PLEASE_ENTER_A_VALID_NUMBER,
    ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT,
    PLEASE_ENTER_VALUE_BETWEEN,
    MAX_LENGTH,
    MAX_LENGTH_240,
    TERMS_AND_CONDITIONS,
    FULL_NAME,
    DATE_OF_BIRTH,
    TIME_ZONE,
    PLEASE_ENTER_THIS_FIELD,
    PLEASE_ENTER_YOUR_EMAIL,
    PLEASE_ENTER_YOUR_PASSWORD,
    PLEASE_ENTER_YOUR_RECOVERY_CODE,
    PLEASE_ENTER_YOUR_VERIFICATION_CODE,
    PLEASE_ENTER_YOUR_PHONE_NUMBER,
    PLEASE_ENTER_YOUR_FULL_NAME,
    PLEASE_ENTER_YOUR_FIRST_NAME,
    PLEASE_ENTER_YOUR_LAST_NAME,
    PLEASE_ENTER_YOUR_SUBJECT,
    PLEASE_ENTER_YOUR_MESSAGE,
    PLEASE_SELECT_YOUR_LANGUAGE,
    PLEASE_SELECT_YOUR_TIME_ZONE,
    PLEASE_SELECT_YOUR_CURRENCY,
    PLEASE_SELECT_YOUR_COUNTRY,
    PLEASE_SELECT_YOUR_STATE,
    PLEASE_SELECT_YOUR_CITY,
    PLEASE_ENTER_A_VALID_EMAIL,
    PLEASE_ENTER_A_VALID_PHONE_NUMBER,
    PLEASE_ENTER_A_VALID_CARD_NUMBER,
    PLEASE_ENTER_A_VALID_RFC,
    PLEASE_ENTER_A_MODERATE_PASSWORD,
    PLEASE_ENTER_A_COMPLEX_PASSWORD,
    PASSWORDS_DO_NOT_MATCH,
    WRONG_CURRENT_PASSWORD,
    SAVED,
    SOMETHING_WENT_WRONG,
    INVALID_USERNAME_OR_PASSWORD,
    INVALID_EMAIL_OR_PASSWORD,
    EMAIL_IS_ALREADY_REGISTERED,
    UNREGISTERED_EMAIL,
    VERIFICATION_EMAIL_HAS_BEEN_RESENT,
    RECOVERY_CODE_HAS_BEEN_RESENT,
    YOUR_RECOVERY_CODE_IS_WRONG,
    YOUR_VERIFICATION_CODE_IS_WRONG,
    QUALITY_RECOMMENDED,
    DO_YOU_WANT_TO_CONTINUE_ANYWAY,
    NO_PROPERTIES,
    NO_PROPERTIES_FOUND,
    PLEASE_LOGIN_TO_CONTINUE,
    OFFER_SENT_TO_PROPERTY_OWNER,
    PROPERTY_ADDED,
    PROPERTY_CODE_COPIED,
    ACCEPT_PROPERTY_APPOINTMENT,
    REJECT_PROPERTY_APPOINTMENT,
    ACCEPT_PROPERTY_OFFER,
    REJECT_PROPERTY_OFFER,
    PROPERTY_APPOINTMENT_ACCEPTED,
    PROPERTY_APPOINTMENT_REJECTED,
    PROPERTY_OFFER_ACCEPTED,
    PROPERTY_OFFER_REJECTED,
    THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL,
    THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL,
    SERVICE_NOT_FOUND,
    SERVICE_PLAN_PROPERTY_AD_WARNING,
    NO_CARD_FOUND,
    CVC_DESCRIPTION,
    CVC_REQUIRED,
    INVOICE_INFO_NOT_FOUND,
    FILE_REQUIRED,
    PRICES_UPLOAD
};

export default {
    
    SEE_ALL,
    SEE_MORE,
    UNDER_CONSTRUCTION,
    RESULTS_FOUND,
    OUR_SUGGESTIONS,
    MORE_RESULTS,
    LOAD_MORE_RESULTS,
    USE_AS_DEFAULT,
    LOGIN,
    WELCOME_BACK,
    SIGN_IN,
    LOG_IN,
    SIGN_UP,
    SIGN_UP_TITLE,
    SIGN_OUT,
    LOG_OUT,
    REMEMBER_ME,
    FORGOT_PASSWORD,
    FORGOT_MY_PASSWORD,
    DONT_HAVE_AN_ACCOUNT,
    CREATE_AN_ACCOUNT,
    CREATE_ACCOUNT,
    ALREADY_REGISTERED,
    I_HAVE_READ_AND_AGREE_TO_THE,
    TERMS_OF_SERVICE,
    VERIFY_YOUR_ACCOUNT,
    WE_HAVE_SENT_AN_EMAIL_TO,
    YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN,
    IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL,
    RESET_YOUR_PASSWORD,
    RECOVERY_CODE,
    REMAINING_ATTEMPTS,
    RESET_PASSWORD,
    AUTHENTICATION_NEEDED_TO_ACCESS_SECTION,
    MY_PROFILE,
    MY_ACCOUNT,
    PERSONAL_INFORMATION,
    PAYMENT_METHODS,
    CHANGE_PROFILE_PICTURE,
    ARE_YOU_SURE_YOU_WANT_TO_LOGOUT,
    SAVE,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    ADD_PROJECT,
    OWN_PROJECTS,
    EDIT,
    SEE,
    DOWNLOAD_PDF,
    DOWNLOAD_QUOTE_PDF,
    ESTATUS,
    PRODUCT,
    QUANTITY,
    AREA,
    COST,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    PLEASE_ENTER_PROJECT_NAME,
    PLEASE_ENTER_CLIENT_NAME,
    PLEASE_ENTER_CLIENT_PHONE,
    PLEASE_ENTER_CLIENT_EMAIL,
    PLEASE_ENTER_CLIENT_STREET,
    PLEASE_ENTER_CLIENT_NUMBER,
    PLEASE_ENTER_CLIENT_CITY,
    PLEASE_ENTER_CLIENT_SUBURB,
    PLEASE_ENTER_CLIENT_STATE,
    PLEASE_ENTER_CLIENT_ZIP_CODE,
    PLEASE_ENTER_CLIENT_DIRECTION,
    PLEASE_ENTER_A_VALID_NUMBER,
    ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT,
    PLEASE_ENTER_VALUE_BETWEEN,
    MAX_LENGTH,
    MAX_LENGTH_240,
    TERMS_AND_CONDITIONS,
    FULL_NAME,
    DATE_OF_BIRTH,
    TIME_ZONE,
    PLEASE_ENTER_THIS_FIELD,
    PLEASE_ENTER_YOUR_EMAIL,
    PLEASE_ENTER_YOUR_PASSWORD,
    PLEASE_ENTER_YOUR_RECOVERY_CODE,
    PLEASE_ENTER_YOUR_VERIFICATION_CODE,
    PLEASE_ENTER_YOUR_PHONE_NUMBER,
    PLEASE_ENTER_YOUR_FULL_NAME,
    PLEASE_ENTER_YOUR_FIRST_NAME,
    PLEASE_ENTER_YOUR_LAST_NAME,
    PLEASE_ENTER_YOUR_SUBJECT,
    PLEASE_ENTER_YOUR_MESSAGE,
    PLEASE_SELECT_YOUR_LANGUAGE,
    PLEASE_SELECT_YOUR_TIME_ZONE,
    PLEASE_SELECT_YOUR_CURRENCY,
    PLEASE_SELECT_YOUR_COUNTRY,
    PLEASE_SELECT_YOUR_STATE,
    PLEASE_SELECT_YOUR_CITY,
    PLEASE_ENTER_A_VALID_EMAIL,
    PLEASE_ENTER_A_VALID_PHONE_NUMBER,
    PLEASE_ENTER_A_VALID_CARD_NUMBER,
    PLEASE_ENTER_A_VALID_RFC,
    PLEASE_ENTER_A_MODERATE_PASSWORD,
    PLEASE_ENTER_A_COMPLEX_PASSWORD,
    PASSWORDS_DO_NOT_MATCH,
    WRONG_CURRENT_PASSWORD,
    SAVED,
    SOMETHING_WENT_WRONG,
    INVALID_USERNAME_OR_PASSWORD,
    INVALID_EMAIL_OR_PASSWORD,
    EMAIL_IS_ALREADY_REGISTERED,
    UNREGISTERED_EMAIL,
    VERIFICATION_EMAIL_HAS_BEEN_RESENT,
    RECOVERY_CODE_HAS_BEEN_RESENT,
    YOUR_RECOVERY_CODE_IS_WRONG,
    YOUR_VERIFICATION_CODE_IS_WRONG,
    QUALITY_RECOMMENDED,
    DO_YOU_WANT_TO_CONTINUE_ANYWAY,
    NO_PROPERTIES,
    NO_PROPERTIES_FOUND,
    PLEASE_LOGIN_TO_CONTINUE,
    OFFER_SENT_TO_PROPERTY_OWNER,
    PROPERTY_ADDED,
    PROPERTY_CODE_COPIED,
    ACCEPT_PROPERTY_APPOINTMENT,
    REJECT_PROPERTY_APPOINTMENT,
    ACCEPT_PROPERTY_OFFER,
    REJECT_PROPERTY_OFFER,
    PROPERTY_APPOINTMENT_ACCEPTED,
    PROPERTY_APPOINTMENT_REJECTED,
    PROPERTY_OFFER_ACCEPTED,
    PROPERTY_OFFER_REJECTED,
    THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL,
    THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL,
    SERVICE_NOT_FOUND,
    SERVICE_PLAN_PROPERTY_AD_WARNING,
    NO_CARD_FOUND,
    CVC_DESCRIPTION,
    CVC_REQUIRED,
    INVOICE_INFO_NOT_FOUND,
    FILE_REQUIRED,
    PRICES_UPLOAD
};