const PRIMARY = {
    REGULAR: 'roboto-regular',
    BOLD: 'roboto-bold',
    ITALIC: 'roboto-italic',
    BLACK: 'roboto-black',
    LIGHT: 'roboto-light',
    THIN: 'roboto-thin',
    BOLD_ITALIC: 'roboto-bold-italic',
    BLACK_ITALIC: 'roboto-black-italic',
    LIGHT_ITALIC: 'roboto-light-italic',
    THIN_ITALIC: 'roboto-thin-italic',
    TITLE: 'montserrat',
    TITLE_BOLD: 'montserrat-bold'
};

export { PRIMARY };

export default { PRIMARY };
