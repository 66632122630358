const device = { minWidth: 320 };

const smallDevice = { width: '100%', minWidth: 400, maxWidth: 750 };

const mediumDevice = { width: '100%', minWidth: 750, maxWidth: 1280 };

const largeDevice = { width: '100%', minWidth: 1280, maxWidth: 1580 };

const keyboard = { offset: 100 };

const screen = {
    minWidth: smallDevice.minWidth - 20,
    maxWidth: smallDevice.maxWidth - 20,
    paddingHorizontal: 20,
    paddingVertical: 20
};

const smallScreen = {
    minWidth: smallDevice.minWidth - 20,
    maxWidth: smallDevice.maxWidth - 20,
    paddingHorizontal: 10,
    paddingVertical: 20
};

const mediumScreen = {
    minWidth: mediumDevice.minWidth - 20,
    maxWidth: mediumDevice.maxWidth - 20,
    paddingHorizontal: 10,
    paddingVertical: 20
};

const largeScreen = {
    minWidth: largeDevice.minWidth - 20,
    paddingHorizontal: 10,
    paddingVertical: 20
};

const container = { width: '100%' };

const containerWeb = { width: '70%' };

const fluidView = { width: '100%' };

const logo = { width: 300, height: 300 };

const headerLogo = { width: 120, height: 42.9, marginBottom: 5 };

const headerIcon = { size: 30 };

const bottomTabsLabel = { marginBottom: 2 };

const bottomTabsIcon = { size: 25 };

const card = {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
};

const smallCard = {
    width: screen.minWidth * (1 / 2),
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
};

const mediumCard = {
    width: screen.minWidth * (3 / 4),
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
};

const largeCard = {
    width: "100%",
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 5,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 }
};

const webCard = {
    width: "60%",
    paddingHorizontal: 35,
    paddingVertical: 25,
    borderRadius: 15,
    shadowRadius: 10,
    shadowOffset: { width: 0, height: 2 }
};

const h1 = { fontSize: 30 };

const h2 = { fontSize: 28 };

const h2Border = { width: 100, height: 4, marginTop: 5, borderRadius: 5 };

const h3 = { fontSize: 23 };

const h4 = { fontSize: 20 };

const h5 = { fontSize: 18 };

const h6 = { fontSize: 16 };

const label = { fontSize: 14 };

const paragraph = { fontSize: 16 };

const text = { fontSize: 16 };

const inputContainer = {
    minWidth: screen.minWidth * (1 / 4),
    minHeight: 40,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 5
};

const input = { fontSize: 16 };

const smallInputContainer = {
    width: screen.minWidth * (1 / 3),
    minHeight: 40,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 5
};

const smallInput = { fontSize: 16 };

const mediumInputContainer = {
    width: screen.minWidth * (2 / 3),
    minHeight: 40,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 5
};

const mediumInput = { fontSize: 16 };

const largeInputContainer = {
    width: screen.minWidth,
    minHeight: 40,
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderWidth: 1,
    borderRadius: 5
};

const largeInput = { fontSize: 16 };

const inputIcon = { size: 20, marginLeft: 10, marginRight: 10 };

const checkboxLabel = {
    fontSize: 15,
    marginLeft: 3,
    marginRight: 3
};

const toggleLabel = {
    fontSize: 15,
    marginLeft: 3,
    marginRight: 3
};

const buttonContainer = {
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 7
};

const buttonText = { fontSize: 18 };

const smallButtonContainer = {
    width: screen.minWidth * (1 / 3),
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRadius: 7
};

const smallButtonText = { fontSize: 16 };

const mediumButtonContainer = {
    width: screen.minWidth * (2 / 3),
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 7
};

const mediumButtonText = { fontSize: 18 };

const largeButtonContainer = {
    width: screen.minWidth,
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 7
};

const largeButtonText = { fontSize: 20 };

const fieldTitle = { fontSize: 17 };

const fieldLabel = { fontSize: 14 };

const listItemText = { marginVertical: 8, fontSize: 16 };

const selectList = { minHeight: 60, paddingHorizontal: 20 };

const selectListSectionTitle = { fontSize: 17 };

const selectListItemTitle = { fontSize: 16 };

const selectListItemTitleIcon = { size: 25, marginRight: 5 };

const selectListItemCheckIcon = { size: 25, marginLeft: 5 };

const settingsList = { minHeight: 60, paddingHorizontal: 10 };

const settingsListSectionTitle = { fontSize: 17 };

const settingsListItemTitle = { fontSize: 16 };

const settingsListItemTitleIcon = { size: 25, marginRight: 5 };

const settingsListItemInfo = { fontSize: 15 };

const settingsListItemNavArrowIcon = { size: 20, marginLeft: 5 };

const accordionHeader = {
    paddingHorizontal: 10,
    paddingVertical: 15,
    borderWidth: 1
};

const accordionTitle = { fontSize: 16 };

const accordionOpenIcon = { size: 20, marginLeft: 5 };

const accordionCloseIcon = { size: 20, marginLeft: 5 };

const accordionBody = {
    paddingHorizontal: 10,
    paddingVertical: 10,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1
};

const accordionDescription = { fontSize: 16 };

const progressBar = { height: 10, borderRadius: 5 };

const icon = { size: 20 };

const divider = { height: 1 };

export {
    device,
    smallDevice,
    mediumDevice,
    largeDevice,
    keyboard,
    screen,
    smallScreen,
    mediumScreen,
    largeScreen,
    container,
    containerWeb,
    fluidView,
    logo,
    headerLogo,
    headerIcon,
    bottomTabsLabel,
    bottomTabsIcon,
    card,
    smallCard,
    mediumCard,
    largeCard,
    webCard,
    h1,
    h2,
    h2Border,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    inputContainer,
    input,
    smallInputContainer,
    smallInput,
    mediumInputContainer,
    mediumInput,
    largeInputContainer,
    largeInput,
    inputIcon,
    checkboxLabel,
    toggleLabel,
    buttonContainer,
    buttonText,
    smallButtonContainer,
    smallButtonText,
    mediumButtonContainer,
    mediumButtonText,
    largeButtonContainer,
    largeButtonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectList,
    selectListSectionTitle,
    selectListItemTitle,
    selectListItemTitleIcon,
    selectListItemCheckIcon,
    settingsList,
    settingsListSectionTitle,
    settingsListItemTitle,
    settingsListItemTitleIcon,
    settingsListItemInfo,
    settingsListItemNavArrowIcon,
    accordionHeader,
    accordionTitle,
    accordionOpenIcon,
    accordionCloseIcon,
    accordionBody,
    accordionDescription,
    progressBar,
    icon,
    divider
};

export default {
    device,
    smallDevice,
    mediumDevice,
    largeDevice,
    keyboard,
    screen,
    smallScreen,
    mediumScreen,
    largeScreen,
    container,
    containerWeb,
    fluidView,
    logo,
    headerLogo,
    headerIcon,
    bottomTabsLabel,
    bottomTabsIcon,
    card,
    smallCard,
    mediumCard,
    largeCard,
    webCard,
    h1,
    h2,
    h2Border,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    inputContainer,
    input,
    smallInputContainer,
    smallInput,
    mediumInputContainer,
    mediumInput,
    largeInputContainer,
    largeInput,
    inputIcon,
    checkboxLabel,
    toggleLabel,
    buttonContainer,
    buttonText,
    smallButtonContainer,
    smallButtonText,
    mediumButtonContainer,
    mediumButtonText,
    largeButtonContainer,
    largeButtonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectList,
    selectListSectionTitle,
    selectListItemTitle,
    selectListItemTitleIcon,
    selectListItemCheckIcon,
    settingsList,
    settingsListSectionTitle,
    settingsListItemTitle,
    settingsListItemTitleIcon,
    settingsListItemInfo,
    settingsListItemNavArrowIcon,
    accordionHeader,
    accordionTitle,
    accordionOpenIcon,
    accordionCloseIcon,
    accordionBody,
    accordionDescription,
    progressBar,
    icon,
    divider
};
