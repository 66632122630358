import Colors from './colors';
import Spacing from './spacing';
import Typography from './typography';

const regular = {
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        minWidth: Spacing.inputContainer.minWidth,
        minHeight: Spacing.inputContainer.minHeight,
        paddingHorizontal: Spacing.inputContainer.paddingHorizontal,
        paddingVertical: Spacing.inputContainer.paddingVertical,
        borderWidth: Spacing.inputContainer.borderWidth,
        borderRadius: Spacing.inputContainer.borderRadius,
        borderColor: Colors.inputContainer.borderColor,
        backgroundColor: Colors.inputContainer.background
    },
    text: {
        flex: 1,
        ...Typography.input
    },
    icon: {}
};

const small = {
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: Spacing.smallInputContainer.width,
        minHeight: Spacing.smallInputContainer.minHeight,
        paddingHorizontal: Spacing.smallInputContainer.paddingHorizontal,
        paddingVertical: Spacing.smallInputContainer.paddingVertical,
        borderWidth: Spacing.smallInputContainer.borderWidth,
        borderRadius: Spacing.smallInputContainer.borderRadius,
        borderColor: Colors.inputContainer.borderColor,
        backgroundColor: Colors.inputContainer.background
    },
    text: {
        flex: 1,
        ...Typography.smallInput
    },
    icon: {}
};

const medium = {
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: Spacing.mediumInputContainer.width,
        minHeight: Spacing.mediumInputContainer.minHeight,
        paddingHorizontal: Spacing.mediumInputContainer.paddingHorizontal,
        paddingVertical: Spacing.mediumInputContainer.paddingVertical,
        borderWidth: Spacing.mediumInputContainer.borderWidth,
        borderRadius: Spacing.mediumInputContainer.borderRadius,
        borderColor: Colors.inputContainer.borderColor,
        backgroundColor: Colors.inputContainer.background
    },
    text: {
        flex: 1,
        ...Typography.mediumInput
    },
    icon: {}
};

const large = {
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: Spacing.largeInputContainer.width,
        minHeight: Spacing.largeInputContainer.minHeight,
        paddingHorizontal: Spacing.largeInputContainer.paddingHorizontal,
        paddingVertical: Spacing.largeInputContainer.paddingVertical,
        borderWidth: Spacing.largeInputContainer.borderWidth,
        borderRadius: Spacing.largeInputContainer.borderRadius,
        borderColor: Colors.inputContainer.borderColor,
        backgroundColor: Colors.inputContainer.background
    },
    text: {
        flex: 1,
        ...Typography.largeInput
    },
    icon: {}
};

export { regular, small, medium, large };

export default { regular, small, medium, large };
