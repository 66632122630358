import { useState, useEffect } from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { RegularExpressions } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import User from "../../models/User";

import { Wrapper, Screen, Container, FluidView, LoadingModal, Field } from "../../components/UI";
import { StyledModal, StyledButton } from "../../components/UI/Styled";
import FormTextInput from "../../components/Profile/FormTextInput";
import { BackButton } from "../../components/Layout/Header";
import { StyledView } from "../../components/UI/Styled";
import FormButton from "../../components/Profile/FormButton";

const isIos = Platform.OS === "ios";

const UpdateEmailScreen = ({ navigation, route }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const accessToken = useSelector((state) => state.auth.token);

    const dispatch = useDispatch();

    const layout = useWindowDimensions();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: { email: route.params?.initialEmail ?? "" },
    });

    const { translate } = useLanguage();

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeError = () => {
        setError(null);
    };

    const cancelHandler = () => {
        navigation.goBack();
    };

    const nextHandler = async (formData) => {
        try {
            toggleIsLoading();

            await user.updateMe({ email: formData.email });

            toggleIsLoading();

            navigation.navigate("VerifyEmail", { email: formData.email });
        } catch (e) {
            toggleIsLoading();

            switch (e?.response?.status) {
                case 400:
                    setError(translate("sentence", "EMAIL_IS_ALREADY_REGISTERED"));
                    break;
                case 401:
                    dispatch(appActions.notReady());
                    break;
                default:
                    setError(translate("sentence", "SOMETHING_WENT_WRONG"));
                    break;
            }
        }
    };

    const CancelButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={cancelHandler}
            style={styles.cancelButtonContainer}
        >
            {translate("verb", "CANCEL")}
        </StyledButton>
    );

    const NextButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={handleSubmit(nextHandler)}
            style={styles.nextButtonContainer}
        >
            {translate("word", "NEXT")}
        </StyledButton>
    );

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => CancelButton,
            headerRight: () => NextButton,
        });
    }, []);

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} {...{ navigation }} />
                    </StyledView>
                    <FluidView style={layout.width > 768 ? styles.inputsContainer : styles.inputsContainerMobile}>
                        <Controller
                            control={control}
                            name="email"
                            rules={{ required: true, pattern: RegularExpressions.EMAIL }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    title={translate("word", "EMAIL")}
                                    label={
                                        errors.email?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_YOUR_EMAIL")
                                            : errors.email?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_EMAIL")
                                            : ""
                                    }
                                    labelColor={errors.email ? "danger" : undefined}
                                >
                                    <FormTextInput
                                        inputType={errors.email ? "danger" : ""}
                                        keyboardType="email-address"
                                        returnKeyType="done"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        placeholder={translate("word", "EMAIL")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                    </FluidView>
                    <FormButton
                        disabled={isLoading}
                        isLoading={isLoading}
                        style={styles.formButton}
                        onPress={handleSubmit(nextHandler)}
                    >
                        {translate("sentence", "SAVE")}
                    </FormButton>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    cancelButtonContainer: {
        marginLeft: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    nextButtonContainer: {
        marginRight: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    inputsContainer: {
        marginTop: 20,
    },
    backButton: {
        alignSelf: "start",
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    inputsContainerMobile: {
        marginTop: 50,
    }
});

export default UpdateEmailScreen;
