import { isValidElement } from 'react';
import { StyleSheet, TouchableOpacity, View, Text } from 'react-native';

import { Colors, Fonts, Buttons } from '../../../styles';

import Loading from '../Loading';

const getStylesFromProps = ({
    buttonType,
    disabled,
    textFont,
    textSize,
    textColor
}) => {
    const styles = { container: {}, text: {}, loadingIcon: {} };

    if (!!disabled) {
        styles.text.opacity = Colors.buttonText.opacity;
    }

    if (typeof textFont !== 'undefined') {
        styles.text.fontFamily = Fonts.primary[textFont] ?? textFont;
    }

    if (typeof textSize !== 'undefined') {
        styles.text.fontSize = textSize;
    }

    if (typeof textColor !== 'undefined') {
        styles.text.color = Colors.app[textColor] ?? textColor;
    }

    if (Colors.app[buttonType]) {
        styles.container.backgroundColor = Colors.app[buttonType];

        switch (buttonType) {
            case 'default':
            case 'white':
            case 'shadowGray':
            case 'lightGray':
            case 'secondary':
                styles.text.color = Colors.app.black;
                styles.loadingIcon.color = Colors.app.black;
                break;
            default:
                styles.text.color = Colors.app.white;
                styles.loadingIcon.color = Colors.app.white;
                break;
        }
    }

    if (typeof textColor !== 'undefined') {
        styles.text.color = Colors.app[textColor] ?? textColor;
    }

    return styles;
};

const StyledButton = (props) => {
    const { children, width } = props;

    const buttonStyle = Buttons[props.size] ?? Buttons['regular'];

    const {
        container: containerStyle,
        text: textStyle,
        loadingIcon: loadingIconStyle
    } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        touch: {
            width: width ?? width,
            ...StyleSheet.flatten(props.styleContainer)
        },
        container: {
            ...buttonStyle.container,
            ...containerStyle,
            ...StyleSheet.flatten(props.style)
        },
        text: {
            ...buttonStyle.text,
            ...textStyle,
            ...props.textStyle
        }
    });

    const content = props.isLoading ? (
        <Loading
            size={props.loadingIconSize ?? 'small'}
            color={
                loadingIconStyle.color ??
                props.loadingIconColor ??
                buttonStyle.text.color
            }
            style={width && styles.touch}
        />
    ) : isValidElement(children) ? (
        children
    ) : (
        <Text style={styles.text}>{children}</Text>
    );

    return (
        <TouchableOpacity
            activeOpacity={props.activeOpacity ?? Colors.buttonContainer.opacity}
            disabled={!!props.disabled}
            onPress={props.onPress}
            style={styles.touch}
        >
            <View style={styles.container}>{content}</View>
        </TouchableOpacity>
    );
};

export default StyledButton;
