import { StyleSheet } from 'react-native';
import FluidView from './FluidView';

import { Colors, Spacing } from '../../styles';

const getStylesFromProps = ({ color, size }) => {
    const styles = { divider: {} };

    if (typeof color !== 'undefined') {
        styles.divider.backgroundColor = Colors.app[color] ?? color;
    }

    if (typeof size !== 'undefined') {
        styles.divider.height = size;
    }

    return styles;
};

const Divider = (props) => {
    const { divider: dividerStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        divider: {
            backgroundColor: Colors.divider.backgroundColor,
            height: Spacing.divider.height,
            ...dividerStyle,
            ...props.style
        }
    });

    return <FluidView {...props} style={styles.divider} />;
};

export default Divider;
