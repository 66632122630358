import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';

import MainNavigator from './MainNavigator';
import AuthNavigator from './AuthNavigator';
import ProfileNavigator from './ProfileNavigator';
import CompanyNavigator from './CompanyNavigator';
import ProjectNavigator from './ProjectNavigator';
import CalculatorNavigator from './CalculatorNavigator';

import NotFoud from '../../screens/NotFound';

const Stack = createStackNavigator();

const ContentNavigator = () => {
    const isAuth = useSelector((state) => !!state.auth.token);

    return (
        <Stack.Navigator screenOptions={{ headerShown: false }}>
            {!isAuth && <Stack.Screen name="AuthNav" component={AuthNavigator} />}
            <Stack.Group>
                <Stack.Screen name="ProjectNav" component={ProjectNavigator} />
                <Stack.Screen name="ProfileNav" component={ProfileNavigator} />
                <Stack.Screen name="CalculatorNav" component={CalculatorNavigator} />
                <Stack.Screen name="MainNav" component={MainNavigator} />
            </Stack.Group>
            <Stack.Screen name="CompanyNav" component={CompanyNavigator} />
            <Stack.Screen name="NotFound" component={NotFoud} />

            {/* <Stack.Screen name="ProfileNav" component={ProfileNavigator} />
            <Stack.Screen name="CompanyNav" component={CompanyNavigator} />
            <Stack.Screen name="NotificationsNav" component={NotificationsNavigator} />
            <Stack.Screen name="FormsNav" component={FormsNavigator} />
            <Stack.Screen name="UtilsNav" component={UtilsNavigator} /> */}
        </Stack.Navigator>
    );
};

export default ContentNavigator;
