import { StyleSheet } from 'react-native';

import { Spacing } from '../../styles';

import { StyledButton } from '../UI/Styled';

const FormButton = (props) => {
    return (
        <StyledButton
            buttonType="primary"
            textSize={22}
            {...props}
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            {props.children}
        </StyledButton>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '80%',
        height: 57,
        minWidth: Spacing.screen.minWidth - 40,
        maxWidth: Spacing.screen.maxWidth - 40
    }
});

export default FormButton;
