/* Common */

const ACCEPT = 'Accept';

const CONFIRM = 'Confirm';

const CANCEL = 'Cancel';

const REJECT = 'Reject';

const CLOSE = 'Close';

const EXPLORE = 'Explore';

const RENT = 'Rent';

const BUY = 'Buy';

const PAY = 'Pay';

const CONTRACT = 'Contract';

/* CRUD */

const CREATE = 'Create';

const UPDATE = 'Update';

const DELETE = 'Delete';

const SAVE = 'Save';

const STORE = 'Store';

const ADD = 'Add';

const EDIT = 'Edit';

const CHANGE = 'Change';

const REMOVE = 'Remove';

const RESET = 'Reset';

const REGISTER = 'Register';

const SEND = 'Send';

const RESEND = 'Resend';

const SEARCH = 'Search';

const SELECT = 'Select';

const PICK = 'Pick';

/* PROJECTS */

const ACTIVES = 'Active';

const HISTORIC = 'Historic';

const ACTIVE = 'Active';

const FINISHED = 'Finished';

export {
    ACCEPT,
    CONFIRM,
    CANCEL,
    REJECT,
    CLOSE,
    EXPLORE,
    RENT,
    BUY,
    PAY,
    CONTRACT,
    CREATE,
    UPDATE,
    DELETE,
    SAVE,
    STORE,
    ADD,
    EDIT,
    CHANGE,
    REMOVE,
    RESET,
    REGISTER,
    SEND,
    RESEND,
    SEARCH,
    SELECT,
    PICK,
    ACTIVES,
    HISTORIC,
    ACTIVE,
    FINISHED
};

export default {
    ACCEPT,
    CONFIRM,
    CANCEL,
    REJECT,
    CLOSE,
    EXPLORE,
    RENT,
    BUY,
    PAY,
    CONTRACT,
    CREATE,
    UPDATE,
    DELETE,
    SAVE,
    STORE,
    ADD,
    EDIT,
    CHANGE,
    REMOVE,
    RESET,
    REGISTER,
    SEND,
    RESEND,
    SEARCH,
    SELECT,
    PICK,
    ACTIVES,
    HISTORIC,
    ACTIVE,
    FINISHED
};
