/* Common */

const YES = "Sí";

const NO = "No";

const OK = "Ok";

const NEW = "Nuevo";

const OLD = "Antiguo";

const QUALITY = "Calidad";

const TYPE = "Tipo";

const COST = "Costo";

const RENT = "Renta";

const BUY = "Compra";

const TOTAL = "Total";

const LOCATION = "Ubicación";

const INFORMATION = "Información";

const ASSISTANCE = "Asistencia";

const DATE = "Fecha";

const YEAR = "Año";

const MONTH = "Mes";

const DAY = "Día";

const HOUR = "Hora";

const MINUTE = "Minuto";

const SECOND = "Segundo";

const MORE = "Más";

const DOCUMENT = "Documento";

const DOCUMENTS = "Documentos";

const OTHER = "Otro";

const OTHERS = "Otros";

const NAME = "Nombre";

const LASTNAME = "Apellido";

const NAMES = "Nombres";

const CODE = "Código";

const CODES = "Códigos";

const NICKNAME = "Apodo";

/* Verb */

const KNOW = "Conoce";

/* Objects */

const CAMERA = "Cámara";

const GALLERY = "Galería";

const BEDROOM = "Recámara";

const BATHROOM = "Baño";

const GARAGE = "Cochera";

const PARKING_LOT = "Estacionamiento";

const POOL = "Alberca";

/* Sections */

const MENU = "Menú";

const PROFILE = "Perfil";

const HOME = "Inicio";

const TODAY = "Hoy";

const EXPLORE = "Explora";

const PROPERTIES = "Propiedades";

const SERVICES = "Servicios";

const MESSAGES = "Mensajes";

/* Profile */

const SECURITY = "Seguridad";

const SETTINGS = "Ajustes";

const EXAMPLE_FILE = "Archivo de ejemplo";

/* Account */

const CURP = "CURP";

const ADDRESS = "Dirección";

const DOMICILE = "Domicilio";

const PHONE_NUMBER = "Número telefónico";

/* Settings */

const LANGUAGE = "Lenguaje";

const CURRENCY = "Moneda";

const NOTIFICATIONS = "Notificaciones";

/* Explore */

const PROPERTY = "Propiedad";

const FEATURES = "Características";

const PLACE = "Lugar";

const LABEL = "Etiqueta";

const LABELS = "Etiquetas";

const ZONE = "Zona";

const ZONES = "Zonas";

const AVENUE = "Avenida";

const AVENUES = "Avenidas";

const ELEMENTS = "Elementos";

const SUBURB = "Colonia";

const SUBURBS = "Colonias";

const TERRAIN = "Terreno";

const BUILDING = "Construcción";

const SERVICE = "Servicio";

const SCORE = "Calificación";

const FILTER = "Filtro";

const FILTERS = "Filtros";

const CITY = "Ciudad";

const CITIES = "Ciudades";

const PRICE = "Precio";

const PRICES = "Precios";

const ROOM = "Habitación";

const ROOMS = "Habitaciones";

const FLOOR = "Piso";

const FLOORS = "Pisos";

/* Properties */

const OWN = "Propias";

const MANAGED = "Administradas";

const DEVELOPMENT = "Desarrollo";

const CONVERSATION = "Conversación";

const DATA_SHEET = "Ficha técnica";

const COUNTRY = "País";

const COUNTRIES = "Países";

const STATE = "Estado";

const STATES = "Estados";

const STREET = "Calle";

const STREETS = "Calles";

const EXT_NUMBER = "Núm. ext";

const INT_NUMBER = "Núm. int";

const ZIP_CODE = "Código postal";

/* Services */

const EXTERNAL = "Externos";

const PLAN = "Plan";

const PLANS = "Planes";

const APPOINTMENT = "Cita";

const APPOINTMENTS = "Citas";

const CONTRACT = "Contrato";

const CONTRACTS = "Contratos";

const EXPIRATION = "Vencimiento";

/* Form */

const SUCCESS = "Éxito";

const ERROR = "Error";

const DANGER = "Peligro";

const WARNING = "Advertencia";

const INFO = "Info";

const MODAL = "Modal";

const ALERT = "Alerta";

const DIALOG = "Diálogo";

const SUBJECT = "Asunto";

const MESSAGE = "Mensaje";

const FRONT = "Adelante";

const NEXT = "Siguiente";

const BACK = "Atrás";

const RETURN = "Regresar";

const LOADING = "Cargando";

const MINIMUM = "Mínimo";

const MAXIMUM = "Máximo";

const RESULT = "Resultado";

const RESULTS = "Resultados";

const REQUIREMENT = "Requerimiento";

const REQUIREMENTS = "Requerimientos";

const CLAUSE = "Cláusula";

const CLAUSES = "Cláusulas";

const ADVANTAGE = "Ventaja";

const ADVANTAGES = "Ventajas";

const DISADVANTAGE = "Desventaja";

const DISADVANTAGES = "Desventajas";

const OFFER = "Oferta";

const OFFERS = "Ofertas";

/* Fields */

const USER = "Usuario";

const EMAIL = "Correo electrónico";

const CURRENT_PASSWORD = "Contraseña actual";

const PASSWORD = "Contraseña";

const CONFIRM_PASSWORD = "Confirmación de contraseña";

const RECOVERY_CODE = "Código de recuperación";

const VERIFICATION_CODE = "Código de verificación";

const RFC = "Rfc";

/* Project */

const PROJECT_NAME = "Proyecto";

const CLIENT_NAME = "Nombre";

const CLIENT_PHONE = "Telefono";

const CLIENT_EMAIL = "Email";

const NUMBER = "Número";

const DIRECTION = "Dirección";

const OPTIONS = "Opciones";

const QUANTITY = "Cantidad";

const AREA = "Área";

const NOTES = "Notas";

const MODEL = "Modelo";

const COLOR = "Color";

const WIDTH = "Ancho";

const HEIGTH = "Alto";

const PROJECTION = "Proyección";

const CALCULATEBY = "Cotizar por: ";

const TYPE_INSTALLATION = "Tipo de instalación";

const TYPE_PERSIANA = "Tipo de persiana";

const INSTALLATION = "Instalación";

const WALL = "Muro";

const CEILING = "Techo";

const CONFECTION = "Confección";

const CONSUMPTION = "Consumo";

const ROLL_WIDTH = "Ancho del rollo";

const BASTILLAS = "Bastillas";

const TOTAL_WIDTH = "Ancho total";

const TOTAL_HEIGTH = "Altura total";

const FOLDS = "Lienzos";

const UNIT_COST = "Costo por unidad";

const TOTAL_COST = "Costo total";

const ADDITIONAL_DATA = "Información Adicional";

const AUTOMATION = "Automatización";

const CONTROL = "Control";

const CONTROL_SIDE = "Lado del control";

const CONTROL_HEIGTH = "Altura del control";

const CRANK = "Manivela";

const NO_PROJECTS = "No hay proyectos";

const IVA = "IVA";

const DISCOUNT = "Descuento";

const ADVANCE = "Anticipo";
/* Calculator */

const CALCULATOR = "Calculadora";

const PERSIANA = "Persiana";

const TOLDO_90 = "Toldo 90°";

const TOLDO_VERTICAL = "Toldo vertical";

const TOLDO_PROYECCION = "Toldo proyeccion";

const CORTINA = "Cortina";

const LEFT = "Izquierda";

const RIGHT = "Derecha";

const RIGHTLEFT = "Agrupación a los lados";

const STRUCTURE_COLOR = "Perfilería";

const TEJIDO = "Tejido";

const SELLING_POLICIES = "Políticas de venta";

/* Others */

const OF = "de";

export {
    YES,
    NO,
    OK,
    NEW,
    OLD,
    QUALITY,
    TYPE,
    COST,
    RENT,
    BUY,
    TOTAL,
    LOCATION,
    INFORMATION,
    ASSISTANCE,
    DATE,
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MORE,
    DOCUMENT,
    DOCUMENTS,
    OTHER,
    OTHERS,
    NAME,
    LASTNAME,
    NAMES,
    CODE,
    CODES,
    NICKNAME,
    KNOW,
    CAMERA,
    GALLERY,
    BEDROOM,
    BATHROOM,
    GARAGE,
    PARKING_LOT,
    POOL,
    MENU,
    PROFILE,
    HOME,
    TODAY,
    EXPLORE,
    PROPERTIES,
    SERVICES,
    MESSAGES,
    SECURITY,
    SETTINGS,
    EXAMPLE_FILE,
    CURP,
    ADDRESS,
    DOMICILE,
    PHONE_NUMBER,
    LANGUAGE,
    CURRENCY,
    NOTIFICATIONS,
    PROPERTY,
    FEATURES,
    PLACE,
    LABEL,
    LABELS,
    ZONE,
    ZONES,
    AVENUE,
    AVENUES,
    ELEMENTS,
    SUBURB,
    SUBURBS,
    TERRAIN,
    BUILDING,
    SERVICE,
    SCORE,
    FILTER,
    FILTERS,
    CITY,
    CITIES,
    PRICE,
    PRICES,
    ROOM,
    ROOMS,
    FLOOR,
    FLOORS,
    OWN,
    MANAGED,
    DEVELOPMENT,
    CONVERSATION,
    DATA_SHEET,
    COUNTRY,
    COUNTRIES,
    STATE,
    STATES,
    STREET,
    STREETS,
    EXT_NUMBER,
    INT_NUMBER,
    ZIP_CODE,
    EXTERNAL,
    PLAN,
    PLANS,
    APPOINTMENT,
    APPOINTMENTS,
    CONTRACT,
    CONTRACTS,
    EXPIRATION,
    SUCCESS,
    ERROR,
    DANGER,
    WARNING,
    INFO,
    MODAL,
    ALERT,
    DIALOG,
    SUBJECT,
    MESSAGE,
    FRONT,
    NEXT,
    BACK,
    RETURN,
    LOADING,
    MINIMUM,
    MAXIMUM,
    RESULT,
    RESULTS,
    REQUIREMENT,
    REQUIREMENTS,
    CLAUSE,
    CLAUSES,
    ADVANTAGE,
    ADVANTAGES,
    DISADVANTAGE,
    DISADVANTAGES,
    OFFER,
    OFFERS,
    USER,
    EMAIL,
    CURRENT_PASSWORD,
    PASSWORD,
    CONFIRM_PASSWORD,
    RECOVERY_CODE,
    VERIFICATION_CODE,
    RFC,
    PROJECT_NAME,
    CLIENT_NAME,
    CLIENT_PHONE,
    CLIENT_EMAIL,
    NUMBER,
    DIRECTION,
    OPTIONS,
    QUANTITY,
    AREA,
    NOTES,
    MODEL,
    COLOR,
    WIDTH,
    HEIGTH,
    PROJECTION,
    CALCULATEBY,
    TYPE_INSTALLATION,
    TYPE_PERSIANA,
    INSTALLATION,
    WALL,
    CEILING,
    CONFECTION,
    CONSUMPTION,
    ROLL_WIDTH,
    BASTILLAS,
    TOTAL_WIDTH,
    TOTAL_HEIGTH,
    FOLDS,
    UNIT_COST,
    TOTAL_COST,
    ADDITIONAL_DATA,
    AUTOMATION,
    CONTROL,
    CONTROL_SIDE,
    CONTROL_HEIGTH,
    CRANK,
    NO_PROJECTS,
    IVA,
    DISCOUNT,
    ADVANCE,
    CALCULATOR,
    PERSIANA,
    TOLDO_90,
    TOLDO_VERTICAL,
    TOLDO_PROYECCION,
    CORTINA,
    LEFT,
    RIGHT,
    RIGHTLEFT,
    STRUCTURE_COLOR,
    TEJIDO,
    SELLING_POLICIES,
    OF,
};

export default {
    YES,
    NO,
    OK,
    NEW,
    OLD,
    QUALITY,
    TYPE,
    COST,
    RENT,
    BUY,
    TOTAL,
    LOCATION,
    INFORMATION,
    ASSISTANCE,
    DATE,
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MORE,
    DOCUMENT,
    DOCUMENTS,
    OTHER,
    OTHERS,
    NAME,
    LASTNAME,
    NAMES,
    CODE,
    CODES,
    NICKNAME,
    KNOW,
    CAMERA,
    GALLERY,
    BEDROOM,
    BATHROOM,
    GARAGE,
    PARKING_LOT,
    POOL,
    MENU,
    PROFILE,
    HOME,
    TODAY,
    EXPLORE,
    PROPERTIES,
    SERVICES,
    MESSAGES,
    SECURITY,
    SETTINGS,
    EXAMPLE_FILE,
    CURP,
    ADDRESS,
    DOMICILE,
    PHONE_NUMBER,
    LANGUAGE,
    CURRENCY,
    NOTIFICATIONS,
    PROPERTY,
    FEATURES,
    PLACE,
    LABEL,
    LABELS,
    ZONE,
    ZONES,
    AVENUE,
    AVENUES,
    ELEMENTS,
    SUBURB,
    SUBURBS,
    TERRAIN,
    BUILDING,
    SERVICE,
    SCORE,
    FILTER,
    FILTERS,
    CITY,
    CITIES,
    PRICE,
    PRICES,
    ROOM,
    ROOMS,
    FLOOR,
    FLOORS,
    OWN,
    MANAGED,
    DEVELOPMENT,
    CONVERSATION,
    DATA_SHEET,
    COUNTRY,
    COUNTRIES,
    STATE,
    STATES,
    STREET,
    STREETS,
    EXT_NUMBER,
    INT_NUMBER,
    ZIP_CODE,
    EXTERNAL,
    PLAN,
    PLANS,
    APPOINTMENT,
    APPOINTMENTS,
    CONTRACT,
    CONTRACTS,
    EXPIRATION,
    SUCCESS,
    ERROR,
    DANGER,
    WARNING,
    INFO,
    MODAL,
    ALERT,
    DIALOG,
    SUBJECT,
    MESSAGE,
    FRONT,
    NEXT,
    BACK,
    RETURN,
    LOADING,
    MINIMUM,
    MAXIMUM,
    RESULT,
    RESULTS,
    REQUIREMENT,
    REQUIREMENTS,
    CLAUSE,
    CLAUSES,
    ADVANTAGE,
    ADVANTAGES,
    DISADVANTAGE,
    DISADVANTAGES,
    OFFER,
    OFFERS,
    USER,
    EMAIL,
    CURRENT_PASSWORD,
    PASSWORD,
    CONFIRM_PASSWORD,
    RECOVERY_CODE,
    VERIFICATION_CODE,
    RFC,
    PROJECT_NAME,
    CLIENT_NAME,
    CLIENT_PHONE,
    CLIENT_EMAIL,
    NUMBER,
    DIRECTION,
    OPTIONS,
    QUANTITY,
    AREA,
    NOTES,
    MODEL,
    COLOR,
    WIDTH,
    HEIGTH,
    PROJECTION,
    CALCULATEBY,
    TYPE_INSTALLATION,
    TYPE_PERSIANA,
    INSTALLATION,
    WALL,
    CEILING,
    CONFECTION,
    CONSUMPTION,
    ROLL_WIDTH,
    BASTILLAS,
    TOTAL_WIDTH,
    TOTAL_HEIGTH,
    FOLDS,
    UNIT_COST,
    TOTAL_COST,
    ADDITIONAL_DATA,
    AUTOMATION,
    CONTROL,
    CONTROL_SIDE,
    CONTROL_HEIGTH,
    CRANK,
    NO_PROJECTS,
    IVA,
    DISCOUNT,
    ADVANCE,
    CALCULATOR,
    PERSIANA,
    TOLDO_90,
    TOLDO_VERTICAL,
    TOLDO_PROYECCION,
    CORTINA,
    LEFT,
    RIGHT,
    RIGHTLEFT,
    STRUCTURE_COLOR,
    TEJIDO,
    SELLING_POLICIES,
    OF,
};
