import { Platform, Image, View } from "react-native";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { createStackNavigator } from "@react-navigation/stack";

import { Colors, Spacing, Typography } from "../../styles";

import { ProfileButton } from "../../components/Layout/Header";
import HomeScreen from "../../screens/Home/HomeScreen";

const Stack = createStackNavigator();

const defaultScreenOptions = (navData) => {
    return {
        headerStyle: {
            backgroundColor: Colors.header.background,
        },
        headerTitle: () => (
            <Image
                style={{
                    width: Spacing.headerLogo.width,
                    height: Spacing.headerLogo.height,
                    marginBottom: Spacing.headerLogo.marginBottom,
                }}
                source={
                    Platform.OS === "ios"
                        ? require("../../assets/images/logo-white.png")
                        : require("../../assets/images/logo.png")
                }
            />
        ),
        headerTitleStyle: { ...Typography.headerTitle },
        headerTitleAlign: "center",
        headerBackTitleStyle: { ...Typography.headerBackTitle },
        headerTintColor: Colors.header.tint,
        headerRight: () => (
            <View>
                <ProfileButton {...navData} />
            </View>
        ),
        headerShown: false,
    };
};

const MainNavigator = ({ navigation }) => {
    const accessToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            if (!accessToken) {
                navigation.navigate("AuthNav");
            }
        });

        return unsubscribe;
    }, [navigation]);

    return (
        <Stack.Navigator screenOptions={defaultScreenOptions}>
            <Stack.Screen name="Home" component={HomeScreen} />
        </Stack.Navigator>
    );
};

export default MainNavigator;
