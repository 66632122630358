import { merge } from 'lodash';
import { Auth as RapidAuth } from 'rapid.js';

import { API_BASE_URL, API_VERSION } from '../config/api';

export default class Auth extends RapidAuth {
    boot() {
        this.baseURL = `${API_BASE_URL}/${API_VERSION}`;
        this.modelName = 'Auth';

        this.config.trailingSlash = true;

        this.config.auth = merge(this.config.auth, {
            routes: {
                login: 'token/',
                logout: 'logout/',
                auth: 'auth/',
                register: 'register/'
            },
            modelPrefix: true
        });

        this.config.customRoutes = [
            {
                name: 'verificationEmail',
                type: 'post',
                url: 'auth/verification-email/send/'
            },
            {
                name: 'passwordRecovery',
                type: 'post',
                url: 'auth/password/recovery/'
            },
            {
                name: 'passwordToken',
                type: 'post',
                url: 'auth/password/token/'
            },
            {
                name: 'passwordReset',
                type: 'post',
                url: 'auth/password/reset/'
            },
            {
                name: 'me',
                type: 'get',
                url: 'auth/me/'
            },
            {
                name: 'refreshToken',
                type: 'post',
                url: 'auth/token/refresh/'
            },
        ];
    }

    sendVerificationEmail = (params) => {
        return this.withParams(params).route('verificationEmail');
    };

    recoverPassword = (params) => {
        return this.withParams(params).route('passwordRecovery');
    };

    fetchPasswordToken = (params) => {
        return this.withParams(params).route('passwordToken');
    };

    resetPassword = (params) => {
        return this.withParams(params).route('passwordReset');
    };
    
    me = () => {
        return this.route('me');
    };

    refreshToken = (params) => {
        return this.withParams(params).route('refreshToken');
    };
}
