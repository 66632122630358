import { APP_NAME_ENV, APP_VERSION_ENV, APP_COUNTRY_ENV, APP_LANGUAGE_ENV, APP_TIME_ZONE_ENV, APP_CURRENCY_ENV } from "@env"

const APP_NAME = APP_NAME_ENV ?? 'Perisanas';
const APP_VERSION = APP_VERSION_ENV ?? 'v1';
const APP_COUNTRY = APP_COUNTRY_ENV ?? 'MX';
const APP_LANGUAGE = APP_LANGUAGE_ENV ?? 'es';
const APP_TIME_ZONE = APP_TIME_ZONE_ENV ?? 'America/Mexico_City';
const APP_CURRENCY = APP_CURRENCY_ENV ?? 'MXN';

export {
  APP_NAME,
  APP_VERSION,
  APP_COUNTRY,
  APP_LANGUAGE,
  APP_TIME_ZONE,
  APP_CURRENCY
};

export default {
  APP_NAME,
  APP_VERSION,
  APP_COUNTRY,
  APP_LANGUAGE,
  APP_TIME_ZONE,
  APP_CURRENCY
};
