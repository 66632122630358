import { createSlice } from '@reduxjs/toolkit';
import { forIn } from 'lodash';

const initialState = { token: null, expirationTime: null, refresh: null };

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setData: (state, action) => {
            forIn(action.payload, (value, key) => {
                state[key] = value;
            });
        },
        authenticate: (state, action) => {
            state.token = action.payload.token;
            state.expirationTime = action.payload.expirationTime;
            state.refresh = action.payload.refresh;
        },
        logout: (state) => {
            forIn(initialState, (value, key) => {
                state[key] = value;
            });
        }
    }
});

export const authActions = authSlice.actions;

export default authSlice;
