import { useState } from "react";
import { StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native";

import { Colors, Fonts } from "../../constants";

import { useLanguage } from "../../hooks";

import FormPersiana from "../../components/Projects/FromPersina";
import FromToldo90 from "../../components/Projects/FromToldo90";
import FromToldoVertical from "../../components/Projects/FromToldoVertical";
import FromToldoProyeccion from "../../components/Projects/FromToldoProyeccion";
import FromCortina from "../../components/Projects/FromCortina";

import { Wrapper, Screen, Container, LoadingModal, Card, H1 } from "../../components/UI";
import {
    StyledView,
    StyledButton,
    StyledSelect,
    StyledModal,
    StyledText,
    StyledIcon,
} from "../../components/UI/Styled";

const CalculatorScreen = () => {
    const { translate } = useLanguage();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTypeToldo, setSelectedTypeToldo] = useState("0");
    const [selectedType, setSelectedType] = useState(0);
    const [error, setError] = useState(null);
    const [additionals, setAdditionals] = useState(null);
    const [showFormMobilePersiana, setShowFormMobilePersiana] = useState(false);
    const [showFormMobileToldo, setShowFormMobileToldo] = useState(false);
    const [showFormMobileCortina, setShowFormMobileCortina] = useState(false);

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const onSubmit = (data) => {
        // console.log("onSubmit",data);
    };

    const removeError = () => {
        setError(null);
    };

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <Screen style={!isMobile ? styles.screen : styles.screenMobile}>
                <StyledModal
                    modalType="alert"
                    visible={!!error}
                    size="medium"
                    title={translate("word", "ERROR")}
                    titleColor="danger"
                    onAccept={removeError}
                    onPressOut={removeError}
                    onRequestClose={removeError}
                >
                    {error}
                </StyledModal>
                <Container>
                    {!isMobile ? (
                        <Card size="large" style={styles.titleCard}>
                            <StyledView style={styles.title}>
                                <H1 size={50} color={Colors.BLACK}>
                                    {translate("word", "CALCULATOR")}
                                </H1>
                            </StyledView>
                            <StyledView
                                style={styles.containerW100}
                                flexDirection="row"
                                justifyContent="space-around"
                                alignItems="baseline"
                            >
                                <Card size="regular" width="30%" style={styles.titleCard}>
                                    <StyledButton
                                        buttonType={selectedType == 0 ? "primary" : "secondary"}
                                        style={styles.buttonTitle}
                                        width="100%"
                                        textFont="titleBold"
                                        onPress={() => setSelectedType(0)}
                                    >
                                        {translate("word", "PERSIANA")}
                                    </StyledButton>
                                    <StyledView style={styles.containerW100}>
                                        {selectedType == 0 && (
                                            <FormPersiana
                                                inCalculator={true}
                                                onSubmit={onSubmit}
                                                {...{ isLoading, additionals, setAdditionals }}
                                            />
                                        )}
                                    </StyledView>
                                </Card>
                                <Card size="regular" width="30%" style={styles.titleCard}>
                                    <TouchableOpacity style={styles.containerW100} onPress={() => setSelectedType(1)}>
                                        <StyledSelect
                                            backgroundColor={selectedType == 1 ? Colors.PRIMARY : Colors.SECONDARY}
                                            textColorStyle={selectedType == 1 ? Colors.WHITE : Colors.BLACK}
                                            style={styles.selectTitle}
                                            textStyle={{
                                                textAlign: "center",
                                                fontFamily: Fonts.PRIMARY.TITLE_BOLD,
                                                width: "100%",
                                            }}
                                            selectedValue={selectedTypeToldo}
                                            onValueChange={(itemValue, itemIndex) => setSelectedTypeToldo(itemValue)}
                                            data={[
                                                { id: 0, label: translate("word", "TOLDO_90"), value: 0 },
                                                { id: 1, label: translate("word", "TOLDO_VERTICAL"), value: 1 },
                                                { id: 2, label: translate("word", "TOLDO_PROYECCION"), value: 2 },
                                            ]}
                                        ></StyledSelect>
                                    </TouchableOpacity>
                                    <StyledView style={styles.containerW100}>
                                        {selectedType == 1 &&
                                            (selectedTypeToldo == "0" ? (
                                                <FromToldo90
                                                    inCalculator={true}
                                                    onSubmit={onSubmit}
                                                    {...{ isLoading, setError, additionals, setAdditionals }}
                                                />
                                            ) : selectedTypeToldo == "1" ? (
                                                <FromToldoVertical
                                                    inCalculator={true}
                                                    onSubmit={onSubmit}
                                                    {...{ isLoading, setError, additionals, setAdditionals }}
                                                />
                                            ) : (
                                                selectedTypeToldo == "2" && (
                                                    <FromToldoProyeccion
                                                        inCalculator={true}
                                                        onSubmit={onSubmit}
                                                        {...{ isLoading, setError, additionals, setAdditionals }}
                                                    />
                                                )
                                            ))}
                                    </StyledView>
                                </Card>
                                <Card size="regular" width="30%" style={styles.titleCard}>
                                    <StyledButton
                                        buttonType={selectedType == 2 ? "primary" : "secondary"}
                                        style={styles.buttonTitle}
                                        width="100%"
                                        textFont="titleBold"
                                        onPress={() => setSelectedType(2)}
                                    >
                                        {translate("word", "CORTINA")}
                                    </StyledButton>
                                    <StyledView style={styles.containerW100}>
                                        {selectedType == 2 && (
                                            <FromCortina
                                                inCalculator={true}
                                                onSubmit={onSubmit}
                                                {...{ isLoading, additionals, setAdditionals }}
                                            />
                                        )}
                                    </StyledView>
                                </Card>
                            </StyledView>
                        </Card>
                    ) : (
                        <Card size="large" style={styles.titleCardMobile}>
                            <StyledView
                                style={styles.containerW100}
                                flexDirection="colum"
                                justifyContent="space-around"
                                alignItems="baseline"
                            >
                                <StyledView style={styles.containerCalcMobile}>
                                    <TouchableOpacity
                                        style={styles.containerW100}
                                        onPress={() => {
                                            setSelectedType(0);
                                            setShowFormMobilePersiana(!showFormMobilePersiana);
                                            setShowFormMobileToldo(false);
                                            setShowFormMobileCortina(false);
                                        }}
                                    >
                                        <StyledView style={styles.containerW100} flexDirection="row">
                                            <StyledView style={styles.iconMobile}>
                                                <StyledIcon
                                                    name="edit"
                                                    iconType="FontAwesome"
                                                    size={24}
                                                    color="nummaBlue"
                                                    style={styles.iconMobile}
                                                />
                                            </StyledView>
                                            <StyledText style={styles.textButtonMobile}>
                                                {translate("word", "PERSIANA")}
                                            </StyledText>
                                            <StyledView style={styles.iconOpenMobile}>
                                                {showFormMobilePersiana ? (
                                                    <StyledIcon
                                                        name="angle-up"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                ) : (
                                                    <StyledIcon
                                                        name="plus"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                )}
                                            </StyledView>
                                        </StyledView>
                                    </TouchableOpacity>
                                    <StyledView>
                                        {showFormMobilePersiana && selectedType == 0 && (
                                            <FormPersiana
                                                inCalculator={true}
                                                onSubmit={onSubmit}
                                                {...{ isLoading, additionals, setAdditionals }}
                                            />
                                        )}
                                    </StyledView>
                                </StyledView>
                                <StyledView style={styles.containerCalcMobile}>
                                    <TouchableOpacity
                                        style={styles.containerW100}
                                        onPressOut={() => {
                                            setSelectedType(1);
                                            setShowFormMobileToldo(!showFormMobileToldo);
                                            setShowFormMobilePersiana(false);
                                            setShowFormMobileCortina(false);
                                        }}
                                    >
                                        <StyledView style={styles.containerW100} flexDirection="row">
                                            <StyledView style={styles.iconMobile}>
                                                <StyledIcon
                                                    name="edit"
                                                    iconType="FontAwesome"
                                                    size={24}
                                                    color="nummaBlue"
                                                    style={styles.iconMobile}
                                                />
                                            </StyledView>
                                            <StyledText style={styles.textButtonMobile}>
                                                <StyledSelect
                                                    backgroundColor={Colors.WHITE}
                                                    textColorStyle={selectedType == 1 ? Colors.WHITE : Colors.BLACK}
                                                    style={styles.selectTitleMobile}
                                                    width="100%"
                                                    textStyle={{
                                                        textAlign: "start",
                                                        fontFamily: Fonts.PRIMARY.TITLE_BOLD,
                                                    }}
                                                    selectedValue={selectedTypeToldo}
                                                    onValueChange={(itemValue, itemIndex) => {
                                                        setSelectedTypeToldo(itemValue);
                                                        setShowFormMobileToldo(true);
                                                    }}
                                                    data={[
                                                        { id: 0, label: translate("word", "TOLDO_90"), value: 0 },
                                                        { id: 1, label: translate("word", "TOLDO_VERTICAL"), value: 1 },
                                                        {
                                                            id: 2,
                                                            label: translate("word", "TOLDO_PROYECCION"),
                                                            value: 2,
                                                        },
                                                    ]}
                                                ></StyledSelect>
                                            </StyledText>
                                            <StyledView style={styles.iconOpenMobile}>
                                                {showFormMobileToldo ? (
                                                    <StyledIcon
                                                        name="angle-up"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                ) : (
                                                    <StyledIcon
                                                        name="plus"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                )}
                                            </StyledView>
                                        </StyledView>
                                    </TouchableOpacity>
                                    <StyledView>
                                        {showFormMobileToldo &&
                                            selectedType == 1 &&
                                            (selectedTypeToldo == "0" ? (
                                                <FromToldo90
                                                    inCalculator={true}
                                                    onSubmit={onSubmit}
                                                    {...{ isLoading, setError, additionals, setAdditionals }}
                                                />
                                            ) : selectedTypeToldo == "1" ? (
                                                <FromToldoVertical
                                                    inCalculator={true}
                                                    onSubmit={onSubmit}
                                                    {...{ isLoading, setError, additionals, setAdditionals }}
                                                />
                                            ) : (
                                                selectedTypeToldo == "2" && (
                                                    <FromToldoProyeccion
                                                        inCalculator={true}
                                                        onSubmit={onSubmit}
                                                        {...{ isLoading, setError, additionals, setAdditionals }}
                                                    />
                                                )
                                            ))}
                                    </StyledView>
                                </StyledView>
                                <StyledView style={styles.containerCalcMobile}>
                                    <TouchableOpacity
                                        style={styles.containerW100}
                                        onPress={() => {
                                            setSelectedType(2);
                                            setShowFormMobileCortina(!showFormMobileCortina);
                                            setShowFormMobilePersiana(false);
                                            setShowFormMobileToldo(false);
                                        }}
                                    >
                                        <StyledView style={styles.containerW100} flexDirection="row">
                                            <StyledView style={styles.iconMobile}>
                                                <StyledIcon
                                                    name="edit"
                                                    iconType="FontAwesome"
                                                    size={24}
                                                    color="nummaBlue"
                                                    style={styles.iconMobile}
                                                />
                                            </StyledView>
                                            <StyledText style={styles.textButtonMobile}>
                                                {translate("word", "CORTINA")}
                                            </StyledText>
                                            <StyledView style={styles.iconOpenMobile}>
                                                {showFormMobileCortina ? (
                                                    <StyledIcon
                                                        name="angle-up"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                ) : (
                                                    <StyledIcon
                                                        name="plus"
                                                        iconType="FontAwesome"
                                                        size={14}
                                                        color="nummaBlue"
                                                    />
                                                )}
                                            </StyledView>
                                        </StyledView>
                                    </TouchableOpacity>
                                    <StyledView>
                                        {showFormMobileCortina && selectedType == 2 && (
                                            <FromCortina
                                                inCalculator={true}
                                                onSubmit={onSubmit}
                                                {...{ isLoading, additionals, setAdditionals }}
                                            />
                                        )}
                                    </StyledView>
                                </StyledView>
                            </StyledView>
                        </Card>
                    )}
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    titleCard: {
        alignItems: "center",
        marginTop: 30,
        paddingVertical: 20,
        paddingHorizontal: 20,
    },
    title: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: 20,
    },
    containerW100: {
        width: "100%",
    },
    buttonTitle: {
        width: "100%",
        borderColor: Colors.SECONDARY,
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
    selectTitle: {
        width: "100%",
        borderColor: Colors.SECONDARY,
        backgroundColor: Colors.SECONDARY,
        paddingVertical: 15,
        paddingHorizontal: 15,
    },
    titleCardMobile: {
        alignItems: "center",
        paddingVertical: 10,
        paddingHorizontal: "5%",
        height: "100%",
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    containerCalcMobile: {
        marginVertical: 5,
        width: "100%",
    },
    iconMobile: {
        backgroundColor: Colors.SECONDARY,
        padding: 13,
        borderRadius: "100%",
    },
    textButtonMobile: {
        marginVertical: "auto",
        marginLeft: 10,
        fontFamily: Fonts.PRIMARY.TITLE_BOLD,
        width: "100%",
    },
    iconOpenMobile: {
        marginVertical: "auto",
        marginLeft: "auto",
    },
    selectTitleMobile: {
        borderColor: Colors.WHITE,
        width: "100%",
        paddingHorizontal: 0,
    },
});

export default CalculatorScreen;
