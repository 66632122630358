import Model from './Model';

export default class Project extends Model {
    boot() {
        super.boot();

        this.modelName = 'Projects';

        this.config.customRoutes = [
            {
                name: 'allActive',
                type: 'get',
                url: 'projects/allactive/'
            },
            {
                name: 'allHistoric',
                type: 'get',
                url: 'projects/allhistoric/'
            },
            {
                name: 'fetch',
                type: 'get',
                url: 'projects/{id}/'
            },
            {
                name: 'update',
                type: 'patch',
                url: 'projects/{id}/'
            },
            {
                name: 'generatePdf',
                type: 'get',
                url: 'projects/{id}/generate-pdf/'
            },
            {
                name: 'generateQuotePdf',
                type: 'get',
                url: 'projects/{id}/generate-pdf-client/'
            }
        ];
    }

    allActive = (params) => {
        return this.withParams(params).route('allActive');
    };

    allHistoric = (params) => {
        return this.withParams(params).route('allHistoric');
    };

    all = (params) => {
        return this.withParams(params).route('all');
    };

    fetch = (id, params) => {
        return this.withParams(params).route('fetch', { id });
    };

    update = (id, params) => {
        return this.withParams(params).route('update', { id });
    };

    generatePdf = (id) => {
        return this.route('generatePdf', { id });
    };

    generateQuotePdf = (id) => {
        return this.route('generatePdf', { id });
    };

}
