import { StyleSheet, View, TextInput } from 'react-native';

import { Colors, Fonts, Spacing, Icons, Inputs } from '../../../styles';

import StyledIcon from './StyledIcon';

const getStylesFromProps = ({
    inputType,
    iconPosition,
    disabled,
    textFont,
    textSize,
    textColor
}) => {
    const styles = { container: {}, text: {}, icon: {} };

    if (iconPosition === 'left') {
        styles.container.flexDirection = 'row-reverse';
        styles.icon.marginRight = Spacing.inputIcon.marginRight;
    } else {
        styles.container.flexDirection = 'row';
        styles.icon.marginLeft = Spacing.inputIcon.marginLeft;
    }

    if (!!disabled) {
        styles.text.opacity = Colors.input.opacity;
        styles.icon.opacity = Colors.inputIcon.opacity;
    }

    if (typeof textFont !== 'undefined') {
        styles.text.fontFamily = Fonts.primary[textFont] ?? textFont;
    }

    if (typeof textSize !== 'undefined') {
        styles.text.fontSize = textSize;
    }

    if (typeof textColor !== 'undefined') {
        styles.text.color = Colors.app[textColor] ?? textColor;
    }

    if (Colors.app[inputType]) {
        styles.container.borderColor = Colors.app[inputType];
    }

    return styles;
};

const StyledTextInput = (props) => {
    const { inputType, iconName, showPassword, iconType } = props;

    const inputStyle = Inputs[props.size] ?? Inputs['regular'];

    const {
        container: containerStyle,
        text: textStyle,
        icon: iconStyle
    } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        container: {
            ...inputStyle.container,
            ...containerStyle,
            ...props.style
        },
        text: {
            ...inputStyle.text,
            ...textStyle,
            ...StyleSheet.flatten(props.textStyle)
        },
        icon: {
            ...inputStyle.icon,
            ...iconStyle,
            ...props.iconStyle
        },
        showPasswordIcon: {
            ...inputStyle.icon,
            ...iconStyle,
            ...props.showPasswordIconStyle
        },
        hidePasswordIcon: {
            ...inputStyle.icon,
            ...iconStyle,
            ...props.hidePasswordIconStyle
        }
    });

    const InputIcon = (
        <StyledIcon
            name={iconName}
            iconType={iconType}
            size={props.iconSize ?? Spacing.inputIcon.size}
            color={Colors.app[inputType] ?? props.iconColor ?? Colors.input.icon}
            style={styles.icon}
            onPress={props.onIcon}
        />
    );

    const ShowPasswordIcon = (
        <StyledIcon
            name={props.showPasswordIconName ?? Icons.input.showPassword}
            size={props.showPasswordIconSize ?? Spacing.inputIcon.size}
            color={
                Colors.app[inputType] ??
                props.showPasswordIconColor ??
                Colors.input.icon
            }
            style={styles.showPasswordIcon}
            onPress={props.onShowPassword}
        />
    );

    const HidePasswordIcon = (
        <StyledIcon
            name={props.hidePasswordIconName ?? Icons.input.hidePassword}
            size={props.hidePasswordIconSize ?? Spacing.inputIcon.size}
            color={
                Colors.app[inputType] ??
                props.hidePasswordIconColor ??
                Colors.input.icon
            }
            style={styles.hidePasswordIcon}
            onPress={props.onHidePassword}
        />
    );

    return (
        <View style={styles.container}>
            <TextInput
                placeholderTextColor={Colors.input.placeholderText}
                selectionColor={Colors.input.selection}
                editable={!props.disabled}
                multiline={!!props.multiline}
                numberOfLines={4}
                {...props}
                secureTextEntry={
                    typeof showPassword !== 'undefined'
                        ? !!showPassword
                        : props.secureTextEntry
                }
                style={styles.text}
            />
            {typeof showPassword !== 'undefined' && !showPassword && HidePasswordIcon}
            {typeof showPassword !== 'undefined' && showPassword && ShowPasswordIcon}
            {iconName && InputIcon}
        </View>
    );
};

export default StyledTextInput;
