import { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, Pressable, ScrollView } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { useLanguage } from "../../hooks";

import { Colors } from "../../constants";

import { appActions } from "../../store/slices/app";

import { StyledSelect, StyledModal, StyledView, StyledIcon } from "../UI/Styled";

import { H3 } from "../../components/UI";

import FormPersiana from "./FromPersina";
import FromToldo90 from "./FromToldo90";
import FromToldoVertical from "./FromToldoVertical";
import FromToldoProyeccion from "./FromToldoProyeccion";
import FromCortina from "./FromCortina";

import Persiana from "../../models/Persiana";
import Toldo90 from "../../models/Toldo90";
import ToldoVertical from "../../models/ToldoVertical";
import ToldoProyeccion from "../../models/ToldoProyeccion";
import Cortina from "../../models/Cortina";

const FormProduct = (props) => {
    const {
        project_id,
        isLoading,
        setIsLoading,
        setError,
        setAlert,
        toggle,
        fetchDataDetail,
        selectedProduct,
        additionals,
        setAdditionals,
    } = props;
    const isActive = props.isActive != undefined ? props.isActive : 0;
    const [selectedType, setSelectedType] = useState("1");

    const dispatch = useDispatch();
    // const [additionals, setAdditionals] = useState([]);

    const layout = useWindowDimensions();

    const { translate } = useLanguage();

    const accessToken = useSelector((state) => state.auth.token);

    var persiana = new Persiana({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldo90 = new Toldo90({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldoVertical = new ToldoVertical({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldoProyeccion = new ToldoProyeccion({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var cortina = new Cortina({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const onSubmitPersiana = async (data) => {
        setIsLoading(true);
        try {
            toggle();
            data["additionals_persiana"] = additionals;
            if (selectedProduct) {
                await persiana.update(selectedProduct.id, { ...data });
            } else {
                await persiana.create({ ...data, project: project_id });
            }

            setIsLoading(false);
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const onSubmitToldo90 = async (data) => {
        setIsLoading(true);
        try {
            toggle();
            data["additionals_t90"] = additionals;
            if (selectedProduct) {
                await toldo90.update(selectedProduct.id, { ...data });
            } else {
                await toldo90.create({ ...data, project: project_id });
            }

            setIsLoading(false);
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const onSubmitToldoVertical = async (data) => {
        setIsLoading(true);
        try {
            toggle();
            data["additionals_tvertical"] = additionals;
            if (selectedProduct) {
                const result = await toldoVertical.update(selectedProduct.id, { ...data });
            } else {
                const result = await toldoVertical.create({ ...data, project: project_id });
            }

            setIsLoading(false);
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const onSubmitToldoProyeccion = async (data) => {
        setIsLoading(true);
        try {
            toggle();
            data["additionals_tproyeccion"] = additionals;
            if (selectedProduct) {
                const result = await toldoProyeccion.update(selectedProduct.id, { ...data });
            } else {
                const result = await toldoProyeccion.create({ ...data, project: project_id });
            }

            setIsLoading(false);
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const onSubmitCortina = async (data) => {
        setIsLoading(true);
        try {
            toggle();
            data["additionals_cortina"] = additionals;
            if (selectedProduct) {
                const result = await cortina.update(selectedProduct.id, { ...data });
            } else {
                const result = await cortina.create({ ...data, project: project_id });
            }

            setIsLoading(false);
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const formSelection = (selectedType) => {
        switch (selectedType) {
            case "1":
                return (
                    <FormPersiana
                        onSubmit={onSubmitPersiana}
                        data={selectedProduct}
                        {...{ isLoading, additionals, setAdditionals, isActive }}
                    />
                );
                break;
            case "2":
                return (
                    <FromToldo90
                        onSubmit={onSubmitToldo90}
                        data={selectedProduct}
                        {...{ isLoading, setError, additionals, setAdditionals, isActive }}
                    />
                );
                break;
            case "3":
                return (
                    <FromToldoVertical
                        onSubmit={onSubmitToldoVertical}
                        data={selectedProduct}
                        {...{ isLoading, setError, additionals, setAdditionals, isActive }}
                    />
                );
                break;
            case "4":
                return (
                    <FromToldoProyeccion
                        onSubmit={onSubmitToldoProyeccion}
                        data={selectedProduct}
                        {...{ isLoading, setError, additionals, setAdditionals, isActive }}
                    />
                );
                break;
            case "5":
                return (
                    <FromCortina
                        onSubmit={onSubmitCortina}
                        data={selectedProduct}
                        {...{ isLoading, additionals, setAdditionals, isActive }}
                    />
                );
                break;
            default:
                return <></>;
                break;
        }
    };

    useEffect(() => {
        if (selectedProduct) {
            setSelectedType(selectedProduct.type);
        }
    }, [selectedProduct]);

    return (
        <StyledModal
            visible={!!props.visible}
            size={layout.width > 768 ? "web" : "large"}
            title={translate("sentence", "ADD_PRODUCT")}
            cardStyle={
                layout.width > 768
                    ? { maxWidth: "50%", height: "80%" }
                    : { height: "85%", marginTop: "auto", backgroundColor: Colors.LIGHT_GRAY }
            }
            onAccept={props.toggle}
            onRequestClose={props.toggle}
        >
            <StyledView style={styles.container100} alignItems="end">
                <Pressable onPress={props.toggle}>
                    <StyledIcon name="close" iconType="FontAwesome" color={Colors.DARK_GRAY} />
                </Pressable>
            </StyledView>
            {selectedProduct ? (
                <H3 style={styles.select}>{translate("sentence", "UPDATE_PRODUCT")}</H3>
            ) : (
                <H3 style={styles.select}>{translate("sentence", "ADD_PRODUCT")}</H3>
            )}
            <StyledSelect
                selectedValue={selectedType}
                onValueChange={(itemValue, itemIndex) => setSelectedType(itemValue)}
                enabled={!selectedProduct}
                data={[
                    { id: 1, label: "Persiana", value: 1 },
                    { id: 2, label: "Toldo 90°", value: 2 },
                    { id: 3, label: "Toldo vertical", value: 3 },
                    { id: 4, label: "Toldo proyeccion", value: 4 },
                    { id: 5, label: "Cortinas", value: 5 },
                ]}
            ></StyledSelect>
            <ScrollView style={styles.container100}>{formSelection(selectedType)}</ScrollView>
        </StyledModal>
    );
};

const styles = StyleSheet.create({
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
        paddingRight: 6,
    },
    select: {
        marginBottom: 10,
    },
});

export default FormProduct;
