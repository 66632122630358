import { createSlice } from '@reduxjs/toolkit';
import { forIn } from 'lodash';

import {
    APP_NAME,
    APP_VERSION,
    APP_COUNTRY,
    APP_LANGUAGE,
    APP_TIME_ZONE,
    APP_CURRENCY
} from '../../config/app';

const initialState = {
    isReady: false,
    name: APP_NAME,
    version: APP_VERSION,
    country: APP_COUNTRY,
    language: APP_LANGUAGE,
    timeZone: APP_TIME_ZONE,
    currency: APP_CURRENCY
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setData: (state, action) => {
            forIn(action.payload, (value, key) => {
                state[key] = value;
            });
        },
        isReady: (state) => {
            state.isReady = true;
        },
        notReady: (state) => {
            state.isReady = false;
        }
    }
});

export const appActions = appSlice.actions;

export default appSlice;
