import { useSelector } from "react-redux";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";

import ContentNavigator from "./ContentNavigator/ContentNavigator";

import StartupScreen from "../screens/StartupScreen";

const AppNavigator = () => {
    const isReady = useSelector((state) => state.app.isReady);

    const prefix = Linking.makeUrl("/");
    const linking = {
        prefixes: [prefix],
        config: {
            screens: {
                AuthNav: {
                    screens: {
                        SignIn: { path: "/login" },
                        VerificationEmail: "/verificacion-email",
                        ForgotPassword: "/olvide-contrasena",
                        RecoveryCode: "/codigo-recuperacion",
                        ResetPassword: "/reiniciar-contrasena",
                    },
                },
                MainNav: {
                    screens: {
                        Home: { path: "/home" },
                    },
                },
                ProfileNav: {
                    initialRouteName: "Profile",
                    screens: {
                        Profile: { path: "/perfil" },
                        Account: { path: "/cuenta" },
                        Security: { path: "/seguridad" },
                        UpdateEmail: { path: "/actualiza-email" },
                        VerifyEmail: { path: "/verifica-email" },
                        UpdatePassword: { path: "/actializa-contrasena" },
                        Prices: { path: "/precios" },
                    },
                },
                ProjectNav: {
                    path: "/proyectos",
                    initialRouteName: "Projects",
                    screens: {
                        Projects: { path: "/" },
                        Details: { path: "/:project_id" },
                    },
                },
                CalculatorNav: {
                    path: "/calculadora",
                    initialRouteName: "Calculator",
                    screens: {
                        Calculator: { path: "/" },
                    },
                },
                NotFound: "*",
            },
        },
    };

    return (
        <NavigationContainer
            linking={linking}
            documentTitle={{
                formatter: (options, route) => {
                    var name;
                    switch (route?.name) {
                        case "AuthNav":
                            name = "";
                            break;
                        case "SignIn":
                            name = "Acceder";
                            break;
                        case "VerificationEmail":
                            name = "Verifica tu cuenta";
                            break;
                        case "ForgotPassword":
                            name = "Restablece tu contraseña";
                            break;
                        case "RecoveryCode":
                            name = "Código de recuperación";
                            break;
                        case "ResetPassword":
                            name = "Restablecer contraseña";
                            break;
                        case "ProfileNav":
                            name = "Mi perfil";
                            break;
                        case "Profile":
                            name = "Mi perfil";
                            break;
                        case "Account":
                            name = "Mi cuenta";
                            break;
                        case "Security":
                            name = "Seguridad";
                            break;
                        case "UpdateEmail":
                            name = "Actualiza tu correo";
                            break;
                        case "VerifyEmail":
                            name = "Verifica tu correo";
                            break;
                        case "UpdatePassword":
                            name = "Actualiza tu contraseña";
                            break;
                        case "Prices":
                            name = "Precios";
                            break;
                        case "ProjectNav":
                            name = "Mis proyectos";
                            break;
                        case "Projects":
                            name = "Mis proyectos";
                            break;
                        case "Details":
                            name = "Detalles";
                            break;
                        case "CalculatorNav":
                            name = "Calculadora";
                            break;
                        case "Calculator":
                            name = "Calculadora";
                            break;
                        default:
                            name = route?.name;
                            break;
                    }
                    return `${name} - Numma`;
                },
            }}
        >
            {isReady && <ContentNavigator />}
            {!isReady && <StartupScreen />}
        </NavigationContainer>
    );
};

export default AppNavigator;
