import { useState } from 'react';
import { Platform, StyleSheet, Pressable, Text } from 'react-native';
import { useForm, Controller } from 'react-hook-form';

import { Colors, RegularExpressions, Fonts } from '../../../constants';

import { useLanguage } from '../../../hooks';

import { FluidView, Paragraph, Field } from '../../UI';
import { StyledText, StyledSwitch } from '../../UI/Styled';
import FormTextInput from '../FormTextInput';
import FormButton from '../FormButton';

const isIos = Platform.OS === 'ios';

const SignInForm = (props) => {
    const { isLoading, message } = props;

    const [showPassword, setShowPassword] = useState(true);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        defaultValues: {
            email: '',
            password: '',
            rememberMe: false
        }
    });

    const { translate } = useLanguage();

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            <FluidView style={styles.messageContainer}>
                {message && <Paragraph>{message}</Paragraph>}
            </FluidView>
            <FluidView>
                <Controller
                    control={control}
                    name="email"
                    rules={{ required: true, pattern: RegularExpressions.EMAIL }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.email?.type === 'required'
                                    ? translate('sentence', 'PLEASE_ENTER_YOUR_EMAIL')
                                    : errors.email?.type === 'pattern'
                                        ? translate('sentence', 'PLEASE_ENTER_A_VALID_EMAIL')
                                        : ''
                            }
                            title={translate('word', 'EMAIL')}
                            titleFont={Fonts.PRIMARY.REGULAR}
                            labelColor={errors.email ? 'danger' : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.email ? 'danger' : ''}
                                iconName={isIos ? 'ios-person-outline' : 'md-person-outline'}
                                keyboardType="email-address"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate('word', 'EMAIL')}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="password"
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.password?.type === 'required'
                                    ? translate('sentence', 'PLEASE_ENTER_YOUR_PASSWORD')
                                    : ''
                            }
                            labelColor={errors.password ? 'danger' : undefined}
                            title={
                                <FluidView style={styles.extrasContainer}> 
                                    <Text>{translate('word', 'PASSWORD')}</Text>
                                    <Pressable onPress={props.onForgotPassword}>
                                        <StyledText size={15} color="nummaBlue">
                                            {translate('sentence', 'FORGOT_MY_PASSWORD')}
                                        </StyledText>
                                    </Pressable>
                                </FluidView> }
                            titleFont={Fonts.PRIMARY.REGULAR}
                        >
                            <FormTextInput
                                inputType={errors.password ? 'danger' : ''}
                                iconName={isIos ? 'ios-key-outline' : 'md-key-outline'}
                                showPassword={showPassword}
                                returnKeyType="done"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate('word', 'PASSWORD')}
                                value={value}
                                onShowPassword={toggleShowPassword}
                                onHidePassword={toggleShowPassword}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
            </FluidView>
            <FormButton
                disabled={isLoading}
                isLoading={isLoading}
                style={styles.formButton}
                onPress={handleSubmit(props.onSubmit)}
            >
                {translate('sentence', 'LOG_IN')}
            </FormButton>
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY
    },
    messageContainer: {
        marginBottom: 20
    },
    formField: {
        marginBottom: 20
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30
    },
    extrasContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20
    },
    fromContainer: {
        backgroundColor: Colors.SECONDARY,
        width: "100%"
    }
});

export default SignInForm;
