import { useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useForm, Controller } from "react-hook-form";

import { RegularExpressions } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { auth } from "../../models";

import { Wrapper, Screen, Container, FluidView, LoadingModal, H3, Field, ViewBackButton } from "../../components/UI";
import { StyledModal } from "../../components/UI/Styled";
import FormTextInput from "../../components/Auth/FormTextInput";
import FormButton from "../../components/Auth/FormButton";

const ForgotPasswordScreen = ({ navigation }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onBlur", defaultValues: { email: "" } });

    const { translate } = useLanguage();

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeError = () => {
        setError(null);
    };

    const submitHandler = async ({ email }) => {
        try {
            toggleIsLoading();

            await auth.recoverPassword({ email });

            toggleIsLoading();

            navigation.navigate("RecoveryCode", { email });
        } catch (e) {
            toggleIsLoading();

            switch (e?.response?.status) {
                case 400:
                    setError(translate("sentence", "UNREGISTERED_EMAIL"));
                    break;
                default:
                    setError(translate("sentence", "SOMETHING_WENT_WRONG"));
                    break;
            }
        }
    };

    return (
        <Wrapper navbar={false}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container style={styles.container}>
                    <ViewBackButton navigation={navigation} />
                    <Container style={styles.ForgotContainer}>
                        <H3>{translate("sentence", "RESET_YOUR_PASSWORD")}</H3>
                        <FluidView style={styles.inputsContainer}>
                            <Controller
                                control={control}
                                name="email"
                                rules={{ required: true, pattern: RegularExpressions.EMAIL }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Field
                                        label={
                                            errors.email?.type === "required"
                                                ? translate("sentence", "PLEASE_ENTER_YOUR_EMAIL")
                                                : errors.email?.type === "pattern"
                                                ? translate("sentence", "PLEASE_ENTER_A_VALID_EMAIL")
                                                : ""
                                        }
                                        labelColor={errors.email ? "danger" : undefined}
                                    >
                                        <FormTextInput
                                            inputType={errors.email ? "danger" : ""}
                                            keyboardType="email-address"
                                            returnKeyType="done"
                                            autoCorrect={false}
                                            autoCapitalize="none"
                                            placeholder={translate("word", "EMAIL")}
                                            value={value}
                                            onChangeText={onChange}
                                            onBlur={onBlur}
                                        />
                                    </Field>
                                )}
                            />
                        </FluidView>
                        <FormButton
                            disabled={isLoading}
                            isLoading={isLoading}
                            style={styles.formButton}
                            onPress={handleSubmit(submitHandler)}
                        >
                            {translate("word", "NEXT")}
                        </FormButton>
                    </Container>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    ForgotContainer: {
        width: "90%",
        maxWidth: Spacing.screen.maxWidth - 40,
        marginTop: 20,
    },
    inputsContainer: {
        marginTop: 50,
    },
    formButton: {
        marginTop: 20,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

export default ForgotPasswordScreen;
