import { useEffect } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import CurrencyInput from "react-native-currency-input";

import { FluidView, Field, RequiredIcon } from "../UI";
import { StyledButton, StyledIcon } from "../UI/Styled";
import { useLanguage } from "../../hooks";
import FormTextInput from "./FormTextInput";
import { useForm, Controller } from "react-hook-form";
import { RegularExpressions } from "../../constants";

const FormAdditionals = (props) => {
    const { additionals, setAdditionals, setErrorForm, clearErrors, setTotalAdditional } = props;
    const isActive = props.isActive ?? true;

    const { translate } = useLanguage();
    const layout = useWindowDimensions();

    const addAdditional = () => {
        setAdditionals([...additionals, { name: "", cost: "" }]);
        updateTotalAdditional(additionals);
    };

    const {
        control,
        formState: { errors, isValid },
        reset,
    } = useForm({
        mode: "onBlur",
    });

    const updateTotalAdditional = (additions) => {
        var additionalsCost = additions.reduce(
            (sum, e) => sum + (isNaN(parseFloat(e.cost)) ? 0 : parseFloat(e.cost)),
            0
        );
        setTotalAdditional(additionalsCost);
    };

    const updateAdditionalElement = (index, name, value) => {
        let tempAdditionals = additionals;
        if (name == "name") {
            tempAdditionals[index].name = value;
        } else {
            tempAdditionals[index].cost = value;
        }
        setAdditionals(tempAdditionals);

        if (name == "cost") {
            updateTotalAdditional(tempAdditionals);
        }
    };

    const deleteAdditionalElement = (index) => {
        let tempAdditionals = additionals.map((object) => ({ ...object }));
        tempAdditionals.splice(index, 1);
        setAdditionals(tempAdditionals);
        let newDefaul = {};
        for (let i in tempAdditionals) {
            newDefaul["name" + i] = tempAdditionals[i].name;
            newDefaul["cost" + i] = tempAdditionals[i].cost;
        }
        updateTotalAdditional(tempAdditionals);
        reset(newDefaul);
    };

    useEffect(() => {
        if (isValid) {
            clearErrors("additionals");
        } else {
            setErrorForm("additionals", { type: "custom", message: "Error con los addicionales" });
        }
    }, [isValid]);

    useEffect(() => {
        updateTotalAdditional(additionals);
    }, []);

    const forms = additionals.map((e, i) => {
        return (
            <FluidView key={i} flexDirection="row" justifyContent="space-around">
                <FluidView style={styles.fromContainer}>
                    <Controller
                        control={control}
                        name={"name" + i}
                        defaultValue={e.name ?? ""}
                        rules={{ required: true }}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Field
                                label={
                                    errors["name" + i]?.type === "required"
                                        ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                        : ""
                                }
                                title={
                                    <>
                                        {translate("word", "NAME")}
                                        <RequiredIcon />
                                    </>
                                }
                                labelColor={errors["name" + i] ? "danger" : undefined}
                                style={styles.formField}
                            >
                                <FormTextInput
                                    inputType={errors["name" + i] ? "danger" : ""}
                                    iconName={isActive?"edit":null}
                                    iconType="FontAwesome"
                                    disabled={!isActive}
                                    returnKeyType="next"
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    placeholder={translate("word", "NAME")}
                                    value={value}
                                    onChangeText={(e) => {
                                        updateAdditionalElement(i, "name", e);
                                        onChange(e);
                                    }}
                                    onBlur={onBlur}
                                />
                            </Field>
                        )}
                    />
                </FluidView>
                <FluidView style={styles.fromContainer}>
                    <Controller
                        control={control}
                        name={"cost" + i}
                        defaultValue={e.cost ?? 0}
                        rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Field
                                label={
                                    errors["cost" + i]?.type === "required"
                                        ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                        : errors["cost" + i]?.type === "pattern"
                                        ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                        : ""
                                }
                                title={
                                    <>
                                        {translate("word", "COST")}
                                        <RequiredIcon />
                                    </>
                                }
                                labelColor={errors["cost" + i] ? "danger" : undefined}
                                style={styles.formField}
                            >
                                <CurrencyInput
                                    renderTextInput={(textInputProps) => (
                                        <FormTextInput
                                            {...textInputProps}
                                            iconName={isActive?"edit":null}
                                            iconType="FontAwesome"
                                            keyboardType="decimal-pad"
                                            style={styles.currencyInput}
                                        />
                                    )}
                                    placeholder={translate("word", "COST")}
                                    disabled={!isActive}
                                    value={value}
                                    onBlur={onBlur}
                                    onChangeValue={(e) => {
                                        updateAdditionalElement(i, "cost", e);
                                        onChange(e);
                                    }}
                                    prefix="$"
                                    delimiter=","
                                    separator="."
                                    precision={2}
                                    minValue={0}
                                    style={
                                        layout.width > 768
                                            ? styles.fromContainerCurrency
                                            : styles.fromContainerCurrencyMobile
                                    }
                                />
                            </Field>
                        )}
                    />
                </FluidView>
                {isActive && (
                    <FluidView style={styles.fromContainerButton}>
                        <FluidView style={styles.buttonContainer}>
                            <StyledButton
                                buttonType={layout.width > 768 ? "white" : "lightGray"}
                                textSize={16}
                                textFont="regular"
                                textColor="nummaBlue"
                                onPress={() => {
                                    deleteAdditionalElement(i);
                                }}
                            >
                                <StyledIcon name="trash" iconType="FontAwesome" color="nummaBlue" />
                            </StyledButton>
                        </FluidView>
                    </FluidView>
                )}
            </FluidView>
        );
    });

    return (
        <FluidView style={styles.container}>
            {isActive && (
                <StyledButton
                    buttonType={layout.width > 768 ? "white" : "lightGray"}
                    textSize={18}
                    textColor="nummaBlue"
                    style={styles.addButton}
                    onPress={addAdditional}
                    width={230}
                >
                    <StyledIcon iconType="Ionicons" name="add-circle-outline" size={18} color="nummaBlue" />{" "}
                    {translate("word", "ADDITIONAL_DATA")}
                </StyledButton>
            )}
            <FluidView>{additionals && forms}</FluidView>
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container: {
        marginTop: 20,
        alignItems: "center",
    },
    addButton: {
        width: 240,
    },
    fromContainer: {
        width: "42%",
    },
    fromContainerButton: {
        width: "10%",
    },
    buttonContainer: {
        marginBottom: 0,
        marginTop: "auto",
    },
    fromContainerCurrency: {
        paddingHorizontal: 0,
        paddingTop: 15,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 18,
        paddingLeft: 15,
    },
    fromContainerCurrencyMobile: {
        paddingHorizontal: 0,
        paddingTop: 15,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 14,
        paddingLeft: 15,
    },    
    currencyInput: {
        paddingRight: 10,
    },
});

export default FormAdditionals;
