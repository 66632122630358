import { StyleSheet, useWindowDimensions } from "react-native";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { Wrapper, Screen, Container, H4, SettingsList } from "../../components/UI";

import { BackButton } from "../../components/Layout/Header";
import { StyledView } from "../../components/UI/Styled";

const SecurityScreen = ({ navigation }) => {
    const { translate } = useLanguage();

    const layout = useWindowDimensions();

    const securitySettingsData = [
        {
            id: "password",
            title: translate("word", "PASSWORD"),
            info: translate("verb", "EDIT"),
            onPress: () => {
                navigation.navigate("UpdatePassword");
            },
        },
    ];

    return (
        <Wrapper>
            <Screen style={styles.screen}>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} {...{ navigation }} />
                    </StyledView>
                    <H4 style={layout.width > 768 ? styles.h4 : styles.h4Mobile}>{translate("word", "SECURITY")}</H4>
                    <SettingsList
                        data={securitySettingsData}
                        style={styles.securitySettings}
                        itemContainerStyle={styles.securitySettingsItemContainer}
                    />
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    h4: {
        width: "100%",
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    securitySettings: {
        marginTop: 30,
    },
    securitySettingsItemContainer: {
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    backButton: {
        alignSelf: "start",
    },
    h4Mobile: {
        marginTop: 50,
    },
});

export default SecurityScreen;
