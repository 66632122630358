import { isValidElement } from 'react';
import { StyleSheet } from 'react-native';
import { forIn } from 'lodash';

import { Colors, Spacing, Typography, Icons } from '../../styles';

import { HighlightOnTouch } from '../Device';
import FluidView from './FluidView';
import { StyledView, StyledText, StyledIcon } from './Styled';

const getDefaultPropsFromProps = ({
    sectionTitleFont,
    sectionTitleSize,
    sectionTitleColor,
    itemTouchableActiveOpacity,
    itemTouchableUnderlayColor,
    itemTouchableBackgroundColor,
    itemTitleFont,
    itemTitleSize,
    itemTitleColor,
    itemTitleIconSize,
    itemTitleIconColor,
    itemInfoFont,
    itemInfoSize,
    itemInfoColor,
    itemNavArrowIconSize,
    itemNavArrowIconColor,
    onSelectItem
}) => {
    const props = {
        sectionContainer: {},
        sectionTitle: {},
        itemTouchable: {},
        itemContainer: {},
        itemTitle: {},
        itemTitleIcon: {},
        itemInfo: {},
        itemNavArrowIcon: {}
    };

    props.sectionTitle = {
        font: sectionTitleFont ?? Typography.settingsListSectionTitle.fontFamily,
        size: sectionTitleSize ?? Typography.settingsListSectionTitle.fontSize,
        color: sectionTitleColor ?? Typography.settingsListSectionTitle.color
    };

    props.itemTouchable = {
        activeOpacity:
            itemTouchableActiveOpacity ?? Colors.settingsListItemTouchable.opacity,
        underlayColor:
            itemTouchableUnderlayColor ??
            Colors.settingsListItemTouchable.underlayColor,
        backgroundColor:
            itemTouchableBackgroundColor ??
            Colors.settingsListItemTouchable.backgroundColor,
        onPress: onSelectItem
    };

    props.itemTitle = {
        font: itemTitleFont ?? Typography.settingsListItemTitle.fontFamily,
        size: itemTitleSize ?? Typography.settingsListItemTitle.fontSize,
        color: itemTitleColor ?? Typography.settingsListItemTitle.color
    };

    props.itemTitleIcon = {
        size: itemTitleIconSize ?? Spacing.settingsListItemTitleIcon.size,
        color: itemTitleIconColor ?? Colors.settingsListItemTitleIcon.icon
    };

    props.itemInfo = {
        font: itemInfoFont ?? Typography.settingsListItemInfo.fontFamily,
        size: itemInfoSize ?? Typography.settingsListItemInfo.fontSize,
        color: itemInfoColor ?? Typography.settingsListItemInfo.color
    };

    props.itemNavArrowIcon = {
        size: itemNavArrowIconSize ?? Spacing.settingsListItemNavArrowIcon.size,
        color: itemNavArrowIconColor ?? Colors.settingsListItemNavArrowIcon.icon
    };

    return props;
};

const getContent = (data, defaultProps, defaultStyles) => {
    let Content = [];

    forIn(data, (section, sectionKey) => {
        section = { key: sectionKey, ...section };

        if (section.title) {
            Content = Content.concat(
                getSectionComponent(section, defaultProps, defaultStyles)
            );
        }

        Content = Content.concat(
            section.data.map((item) => {
                return getItemComponent(
                    {
                        key: `${section.key}_${typeof item.key !== 'undefined' ? item.key : item.id
                            }`,
                        ...item
                    },
                    defaultProps,
                    defaultStyles
                );
            })
        );
    });

    return Content;
};

const getSectionComponent = (section, defaultProps, defaultStyles) => {
    const styles = StyleSheet.create({
        container: {
            ...defaultStyles.sectionContainer,
            ...section.containerStyle
        },
        title: {
            ...defaultStyles.sectionTitle,
            ...section.titleStyle
        }
    });

    return (
        <FluidView
            key={section.key}
            justifyContent="center"
            style={styles.container}
        >
            <StyledText
                font={section.titleFont ?? defaultProps.sectionTitle.font}
                size={section.titleSize ?? defaultProps.sectionTitle.size}
                color={section.titleColor ?? defaultProps.sectionTitle.color}
                style={styles.title}
            >
                {section.title}
            </StyledText>
        </FluidView>
    );
};

const getItemComponent = (item, defaultProps, defaultStyles) => {
    const { hasNavArrow = true } = item;

    const styles = StyleSheet.create({
        touchable: {
            ...defaultStyles.itemTouchable,
            ...item.touchableStyle
        },
        container: {
            ...defaultStyles.itemContainer,
            ...item.containerStyle
        },
        title: {
            ...defaultStyles.itemTitle,
            ...item.titleStyle
        },
        titleIcon: {
            ...defaultStyles.itemTitleIcon,
            ...item.titleIconStyle
        },
        info: {
            ...defaultStyles.itemInfo,
            ...item.infoStyle
        },
        navArrowIcon: {
            ...defaultStyles.itemNavArrowIcon,
            ...item.navArrowIconStyle
        }
    });

    return (
        <HighlightOnTouch
            key={item.key}
            activeOpacity={
                item.touchableActiveOpacity ?? defaultProps.itemTouchable.activeOpacity
            }
            underlayColor={
                item.touchableUnderlayColor ?? defaultProps.itemTouchable.underlayColor
            }
            backgroundColor={
                item.touchableBackgroundColor ??
                defaultProps.itemTouchable.backgroundColor
            }
            style={styles.touchable}
            onPress={item.onPress}
        >
            <FluidView>
                <FluidView
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={styles.container}
                >
                    <StyledView
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {item.titleIconName && (
                            <StyledIcon
                                name={item.titleIconName}
                                size={item.titleIconSize ?? defaultProps.itemTitleIcon.size}
                                color={item.titleIconColor ?? defaultProps.itemTitleIcon.color}
                                style={styles.titleIcon}
                            />
                        )}
                        <StyledText
                            font={item.titleFont ?? defaultProps.itemTitle.font}
                            size={item.titleSize ?? defaultProps.itemTitle.size}
                            color={item.titleColor ?? defaultProps.itemTitle.color}
                            style={styles.title}
                        >
                            {item.title}
                        </StyledText>
                    </StyledView>
                    <StyledView
                        flexDirection="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        {isValidElement(item.info) ? (
                            item.info
                        ) : (
                            <StyledText
                                font={item.infoFont ?? defaultProps.itemInfo.font}
                                size={item.infoSize ?? defaultProps.itemInfo.size}
                                color={item.infoColor ?? defaultProps.itemInfo.color}
                                style={styles.info}
                            >
                                {item.info ?? ''}
                            </StyledText>
                        )}
                        {!!hasNavArrow && (
                            <StyledIcon
                                name={Icons.settingsList.navArrow}
                                size={
                                    item.itemNavArrowIconSize ??
                                    defaultProps.itemNavArrowIcon.size
                                }
                                color={
                                    item.itemNavArrowIconColor ??
                                    defaultProps.itemNavArrowIcon.color
                                }
                                style={styles.navArrowIcon}
                            />
                        )}
                    </StyledView>
                </FluidView>
            </FluidView>
        </HighlightOnTouch>
    );
};

const SettingsList = (props) => {
    let { data = [] } = props;

    if (Array.isArray(data)) {
        data = { default: { data } };
    }

    const styles = StyleSheet.create({
        container: {
            ...props.style
        }
    });

    const defaultProps = getDefaultPropsFromProps(props);

    const defaultStyles = StyleSheet.create({
        sectionContainer: {
            minHeight: Spacing.settingsList.minHeight,
            paddingHorizontal: Spacing.settingsList.paddingHorizontal,
            ...props.sectionContainerStyle
        },
        sectionTitle: {
            ...props.sectionTitleStyle
        },
        itemTouchable: {
            ...props.itemTouchableStyle
        },
        itemContainer: {
            minHeight: Spacing.settingsList.minHeight,
            paddingHorizontal: Spacing.settingsList.paddingHorizontal,
            ...props.itemContainerStyle
        },
        itemTitle: {
            ...props.itemTitleStyle
        },
        itemTitleIcon: {
            marginRight: Spacing.settingsListItemTitleIcon.marginRight,
            ...props.itemTitleIconStyle
        },
        itemInfo: {
            ...props.itemInfoStyle
        },
        itemNavArrowIcon: {
            marginLeft: Spacing.settingsListItemNavArrowIcon.marginLeft,
            ...props.itemNavArrowIconStyle
        }
    });

    return (
        <FluidView {...props} style={styles.container}>
            {getContent(data, defaultProps, defaultStyles)}
        </FluidView>
    );
};

export default SettingsList;
