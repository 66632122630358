import { useState, React, useEffect } from 'react';
import { StyleSheet, Pressable, useWindowDimensions, View, Image } from "react-native";

import { useDispatch } from 'react-redux';

import { Spacing, Colors } from '../../styles';

import authActions from '../../store/actions/auth';

import { useLanguage } from '../../hooks';

import {
    Wrapper,
    Screen,
    Container,
    FluidView,
    LoadingModal,
    H1,
    Card,
    H5
} from '../../components/UI';
import { StyledModal, StyledText } from '../../components/UI/Styled';
import SignInForm from '../../components/Auth/SignIn/SignInForm';

const SignInScreen = ({ navigation, route }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorVerify, setErrorVerify] = useState(false);
    const [email, setEmail] = useState(null);
    
    const dispatch = useDispatch();

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const { translate } = useLanguage();

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeError = () => {
        setError(null);
        if (errorVerify) {
            navigation.navigate('VerificationEmail', { email });
        }
    };

    const loginHandler = async ({ email, password }) => {
        try {
            toggleIsLoading();

            await dispatch(authActions.login(email, password));

            toggleIsLoading();

            navigation.navigate('ProjectNav');
        } catch (e) {
            toggleIsLoading();
            if (!!e.isnt_verified) {
                setEmail(email);
                setErrorVerify(true);
                setError(translate('sentence', 'YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN'));
            }else{
                switch (e?.response?.status) {
                    case 401:
                        setError(translate('sentence', 'INVALID_USERNAME_OR_PASSWORD'));
                        break;
                    default:
                        setError(translate('sentence', 'SOMETHING_WENT_WRONG'));
                        break;
                }
            }
        }
    };

    const forgotPasswordHandler = () => {
        navigation.navigate('ForgotPassword');
    };

    return (
        <Wrapper navbar={false}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate('word', 'ERROR')}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container>
                    <Card style={isWeb ? styles.displayCardWeb : styles.displayCard} size={isWeb ? "web" : "large"}>
                        <View style={styles.header}>
                            <Image style={styles.logo} source={require('../../assets/images/logo.png')}/>
                            <H5 color={Colors.app.shadowGray}>{translate('sentence', 'WELCOME_BACK')}</H5>
                            <H1 color={Colors.app.black} size={60} >{translate('sentence', 'LOGIN')}</H1>
                        </View>
                        <SignInForm
                            isLoading={isLoading}
                            message={route.params?.message}
                            style={styles.signInForm}
                            onSubmit={loginHandler}
                            onForgotPassword={forgotPasswordHandler}
                        />
                        <FluidView
                            justifyContent="flex-start"
                            alignItems="center"
                            style={styles.extrasContainer}
                        >
                            <StyledText font="bold" size={16} align="center">
                                {translate('sentence', 'DONT_HAVE_AN_ACCOUNT')}
                            </StyledText>
                        </FluidView>
                    </Card>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth
    },
    signInForm: {
        marginTop: 20
    },
    signUpButton: {
        marginTop: 12
    },
    displayCardWeb: {
        alignSelf: "start",
        marginTop: "auto",
        marginBottom: "auto"
    },
    displayCard: {
        alignSelf: "center",
        height: "100%"
    },
    logo: {
        width: 140,
        height: 100
    },
    header: {
        width: "100%",
        alignItems: 'start',
        marginTop: 35
    },
    extrasContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

export default SignInScreen;
