import { Platform, Image } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { Colors, Spacing, Typography } from '../../styles';

import { BackButton } from '../../components/Layout/Header';

import TermsAndConditionsScreen from '../../screens/Company/TermsAndConditionsScreen';

const Stack = createStackNavigator();

const defaultScreenOptions = (navData) => {
  return {
    headerStyle: {
      backgroundColor: Colors.header.background
    },
    headerTitle: () => (
      <Image
        style={{
          width: Spacing.headerLogo.width,
          height: Spacing.headerLogo.height,
          marginBottom: Spacing.headerLogo.marginBottom
        }}
        source={
          Platform.OS === 'ios'
            ? require('../../assets/images/logo-white.png')
            : require('../../assets/images/logo-white.png')
        }
      />
    ),
    headerTitleStyle: { ...Typography.headerTitle },
    headerTitleAlign: 'center',
    headerBackTitleStyle: { ...Typography.headerBackTitle },
    headerTintColor: Colors.header.tint,
    headerLeft: () => <BackButton {...navData} />,
    headerShown: false 

  };
};

const CompanyNavigator = () => {
  return (
    <Stack.Navigator screenOptions={defaultScreenOptions}>
      <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen}/>
    </Stack.Navigator>
  );
};

export default CompanyNavigator;
