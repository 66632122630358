import { Views } from '../../styles';

import { StyledView } from './Styled';

import { useWindowDimensions } from "react-native";

const Container = (props) => {
    const layout = useWindowDimensions();

    return (
        <StyledView
            flex={Views.container.flex}
            justifyContent={Views.container.justifyContent}
            alignItems={Views.container.alignItems}
            width={layout.width > 768 ? Views.containerWeb.width : Views.container.width}
            {...props}
        >
            {props.children}
        </StyledView>
    );
};

export default Container;
