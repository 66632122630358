import Colors from './colors';
import Spacing from './spacing';
import Typography from './typography';

const regular = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: Spacing.buttonContainer.paddingHorizontal,
        paddingVertical: Spacing.buttonContainer.paddingVertical,
        borderRadius: Spacing.buttonContainer.borderRadius,
        backgroundColor: Colors.buttonContainer.background
    },
    text: {
        ...Typography.buttonText
    }
};

const small = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Spacing.smallButtonContainer.width,
        paddingHorizontal: Spacing.smallButtonContainer.paddingHorizontal,
        paddingVertical: Spacing.smallButtonContainer.paddingVertical,
        borderRadius: Spacing.smallButtonContainer.borderRadius,
        backgroundColor: Colors.buttonContainer.background
    },
    text: {
        ...Typography.smallButtonText
    }
};

const medium = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Spacing.mediumButtonContainer.width,
        paddingHorizontal: Spacing.mediumButtonContainer.paddingHorizontal,
        paddingVertical: Spacing.mediumButtonContainer.paddingVertical,
        borderRadius: Spacing.mediumButtonContainer.borderRadius,
        backgroundColor: Colors.buttonContainer.background
    },
    text: {
        ...Typography.mediumButtonText
    }
};

const large = {
    container: {
        justifyContent: 'center',
        alignItems: 'center',
        width: Spacing.largeButtonContainer.width,
        paddingHorizontal: Spacing.largeButtonContainer.paddingHorizontal,
        paddingVertical: Spacing.largeButtonContainer.paddingVertical,
        borderRadius: Spacing.largeButtonContainer.borderRadius,
        backgroundColor: Colors.buttonContainer.background
    },
    text: {
        ...Typography.largeButtonText
    }
};

export { regular, small, medium, large };

export default { regular, small, medium, large };
