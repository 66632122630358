import { useState } from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useForm, Controller } from "react-hook-form";

import { RegularExpressions } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { auth } from "../../models";

import { Wrapper, Screen, Container, FluidView, LoadingModal, H3, Field } from "../../components/UI";
import { StyledModal } from "../../components/UI/Styled";
import FormTextInput from "../../components/Auth/FormTextInput";
import FormButton from "../../components/Auth/FormButton";

const RecoveryCodeScreen = ({ navigation, route }) => {
    const email = route.params?.email;

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [error, setError] = useState(null);

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: "onBlur", defaultValues: { recoveryCode: "" } });

    const { translate } = useLanguage();

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const resendRecoveryCode = async () => {
        try {
            toggleIsLoading();

            await auth.recoverPassword({ email });

            toggleIsLoading();

            setAlert(translate("sentence", "RECOVERY_CODE_HAS_BEEN_RESENT"));
        } catch (e) {
            toggleIsLoading();

            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
        }
    };

    const submitHandler = async ({ recoveryCode }) => {
        try {
            toggleIsLoading();

            const {
                data: { token },
            } = await auth.fetchPasswordToken({ email, recovery_code: recoveryCode });

            toggleIsLoading();

            navigation.navigate("ResetPassword", { token });
        } catch (e) {
            toggleIsLoading();

            switch (e?.response?.status) {
                case 400:
                    setError(
                        `${translate("sentence", "YOUR_RECOVERY_CODE_IS_WRONG")}\n\n${translate(
                            "sentence",
                            "REMAINING_ATTEMPTS"
                        )}: ${response.data.remaining_attempts ?? "0"}`
                    );
                    break;
                default:
                    setError(translate("sentence", "SOMETHING_WENT_WRONG"));
                    break;
            }
        }
    };

    return (
        <Wrapper navbar={false}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!alert}
                size="medium"
                title={translate("word", "INFO")}
                titleColor="info"
                onAccept={removeAlert}
                onPressOut={removeAlert}
                onRequestClose={removeAlert}
            >
                {alert}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container style={styles.container}>
                    <H3>{translate("word", "RECOVERY_CODE")}</H3>
                    <Container style={styles.InnerContainer}>
                        <FluidView style={styles.inputsContainer}>
                            <Controller
                                control={control}
                                name="recoveryCode"
                                rules={{
                                    required: true,
                                    pattern: RegularExpressions.RECOVERY_CODE,
                                }}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Field
                                        label={translate("sentence", "PLEASE_ENTER_YOUR_RECOVERY_CODE")}
                                        labelColor={errors.recoveryCode ? "danger" : undefined}
                                    >
                                        <FormTextInput
                                            inputType={errors.recoveryCode ? "danger" : ""}
                                            iconName={Platform.OS === "ios" ? "ios-mail" : "md-mail"}
                                            keyboardType="numeric"
                                            returnKeyType="done"
                                            autoCorrect={false}
                                            autoCapitalize="none"
                                            placeholder={translate("word", "RECOVERY_CODE")}
                                            value={value}
                                            onIcon={resendRecoveryCode}
                                            onChangeText={onChange}
                                            onBlur={onBlur}
                                        />
                                    </Field>
                                )}
                            />
                        </FluidView>
                        <FormButton
                            disabled={isLoading}
                            isLoading={isLoading}
                            style={styles.formButton}
                            onPress={handleSubmit(submitHandler)}
                        >
                            {translate("word", "NEXT")}
                        </FormButton>
                    </Container>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    InnerContainer: {
        width: "90%",
        maxWidth: Spacing.screen.maxWidth - 40,
        marginTop: 20,
    },
    inputsContainer: {
        marginTop: 50,
    },
    formButton: {
        marginTop: 20,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

export default RecoveryCodeScreen;
