import Model from './Model';

export default class PropertyProfile extends Model {
    boot() {
        super.boot();

        this.modelName = 'PropertyProfiles';

        this.config.customRoutes = [
            {
                name: 'all',
                type: 'get',
                url: 'property-profiles/all/'
            }
        ];
    }

    all = (params) => {
        return this.withParams(params).route('all');
    };
}
