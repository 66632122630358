import { createSlice } from '@reduxjs/toolkit';
import { forIn } from 'lodash';

const initialState = {
    id: null,
    firstName: null,
    lastName: null,
    fullName: null,
    dateOfBirth: null,
    curp: null,
    email: null,
    phoneNumber: null,
    profilePicture: null,
    address: null,
    rfc: null,
    settings: {
        language: null,
        timeZone: null,
        currency: null
    }
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setData: (state, action) => {
            forIn(action.payload, (value, key) => {
                state[key] = value;
            });
        },
        setSettings: (state, action) => {
            forIn(action.payload, (value, key) => {
                state.settings[key] = value;
            });
        },
        resetData: (state) => {
            forIn(initialState, (value, key) => {
                state[key] = value;
            });
        }
    }
});

export const userActions = userSlice.actions;

export default userSlice;
