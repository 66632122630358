import { useState, useEffect, useCallback } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import CurrencyInput from "react-native-currency-input";

import { useLanguage } from "../../hooks";

import { Colors, RegularExpressions } from "../../constants";

import { appActions } from "../../store/slices/app";

import { StyledText, StyledSelect, StyledSwitch } from "../../components/UI/Styled";
import { Loading } from "../../components/UI";
import { useForm, Controller } from "react-hook-form";
import { FluidView, Field, RequiredIcon } from "../UI";
import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import Toldo90 from "../../models/Toldo90";
import FormAdditionals from "./FormAdditionals";

const FromToldo90 = (props) => {
    const { isLoading, setError, additionals, setAdditionals } = props;
    const isActive = props.isActive != undefined ? props.isActive == 0 : true;
    const inCalculator = props.inCalculator ?? false;
    const [isLoadingLocal, setIsLoadingLocal] = useState(false);
    const [data, setData] = useState({});
    const [modelsOptions, setModelsOptions] = useState([]);
    const [totalAdditional, setTotalAdditional] = useState(0);

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const dispatch = useDispatch();

    const { translate } = useLanguage();

    const INSTALLATIONS_CHOICES = [
        { id: 0, label: translate("word", "WALL"), value: 0 },
        { id: 1, label: translate("word", "CEILING"), value: 1 },
    ];
    const CONTROL_SIDE_CHOICES = [
        { id: 0, label: "--", value: 0 },
        { id: 1, label: translate("word", "LEFT"), value: 1 },
        { id: 2, label: translate("word", "RIGHT"), value: 2 },
        { id: 3, label: translate("word", "RIGHTLEFT"), value: 3 },
    ];

    const accessToken = useSelector((state) => state.auth.token);

    var toldo90 = new Toldo90({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const PROJECTION_CHOICES = [
        { id: 0, label: "1.00", value: 0 },
        { id: 1, label: "1.40", value: 1 },
    ];

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        setError: setErrorForm,
        clearErrors,
        resetField,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            quantity: props.data?.quantity ?? 1,
            projection: props.data?.projection ?? 0,
            width: props.data?.width ?? "",
            cost: props.data?.cost ?? 0,
            area: props.data?.area ?? "",
            notes: props.data?.notes ?? "",
            unit_cost: props.data?.unit_cost ?? "",
            total_cost: props.data?.total_cost ?? "",

            type_installation: props.data?.type_installation ?? 0,
            crank: props.data?.crank ?? false,
            automation: props.data?.automation ?? false,
            control: props.data?.control ?? false,
            control_side: props.data?.control_side ?? 1,

            structure_color: props.data?.structure_color ?? "",
            location: props.data?.location ?? "",
            t90_model: props.data?.t90_model ?? modelsOptions[0],
        },
    });

    const fetchData = useCallback(async () => {
        setIsLoadingLocal(true);
        try {
            const result = await toldo90.data();
            setData(result.data);
            const models = await toldo90.models();
            var options = models.data.map((e) => {
                return { id: e.id, label: e.name, value: e.id };
            });
            setModelsOptions(options);
            if (!props.data) {
                resetField("t90_model", { defaultValue: options[0]?.id });
            }
            setIsLoadingLocal(false);
        } catch (e) {
            setIsLoadingLocal(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    }, []);

    useEffect(() => {
        var t90_model = parseInt(watch("t90_model"));
        var selectedProjection = parseInt(watch("projection"));
        var heigth = parseFloat(PROJECTION_CHOICES[selectedProjection]?.label);
        var width = parseFloat(watch("width"));
        heigth = isNaN(heigth) ? 0 : heigth;
        width = isNaN(width) ? 0 : width;
        setValue("area", (heigth * width).toFixed(2));
        if (data) {
            var costSetted = false;
            for (let j = 0; j < data.length; j++) {
                const element = data[j];
                if (
                    element.model == t90_model &&
                    element.projection == selectedProjection &&
                    parseFloat(element.width_min) <= width &&
                    parseFloat(element.width_max) >= width
                ) {
                    setValue("unit_cost", parseFloat(element.cost).toFixed(2));
                    costSetted = true;
                }
            }
            if (!costSetted) {
                resetField("unit_cost");
            }
        }
    }, [watch("t90_model"), watch("projection"), watch("width")]);

    useEffect(() => {
        var unit_cost = parseFloat(watch("unit_cost"));
        var quantity = parseFloat(watch("quantity"));
        unit_cost = isNaN(unit_cost) ? 0 : unit_cost;
        quantity = isNaN(quantity) ? 0 : quantity;
        setValue("total_cost", (unit_cost * quantity + totalAdditional * quantity).toFixed(2));
    }, [watch("unit_cost"), watch("quantity"), totalAdditional]);

    useEffect(() => {
        fetchData();
        return fetchData;
    }, [fetchData]);

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            {isLoadingLocal ? (
                <FluidView style={styles.loadingContainer}>
                    <Loading size="large" color={Colors.PRIMARY} style={StyleSheet.flatten(styles.loading)} />
                    <StyledText font="bold" size={16} color="darkGray">
                        {`${"LOADING"}...`}
                    </StyledText>
                </FluidView>
            ) : (
                <FluidView justifyContent="flex-start" alignItems="center">
                    <FluidView>
                        <Controller
                            control={control}
                            name="quantity"
                            rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.quantity?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.quantity?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : ""
                                    }
                                    title={
                                        <>
                                            {translate("word", "QUANTITY")}
                                            <RequiredIcon />
                                        </>
                                    }
                                    labelColor={errors.quantity ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <FormTextInput
                                        inputType={errors.quantity ? "danger" : ""}
                                        iconName={isActive ? "edit" : null}
                                        iconType="FontAwesome"
                                        disabled={!isActive}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        keyboardType="numeric"
                                        style={styles.fromContainer}
                                        placeholder={translate("word", "QUANTITY")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="t90_model"
                            rules={{ required: true }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.t90_model?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={
                                        <>
                                            {translate("word", "MODEL")}
                                            <RequiredIcon />
                                        </>
                                    }
                                    labelColor={errors.t90_model ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSelect
                                        disabled={!isActive}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        data={modelsOptions}
                                        style={styles.fromContainer}
                                        textStyle={styles.fromContainerSelect}
                                        textSize={isMobile ? 14 : 16}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="width"
                            rules={{
                                required: true,
                                pattern: RegularExpressions.DIGITS_FLOAT,
                                validate: (value) => value >= 1 && value <= 4,
                            }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.width?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.width?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : errors.width?.type === "validate"
                                            ? translate("sentence", "PLEASE_ENTER_VALUE_BETWEEN") + " 1 y 4"
                                            : ""
                                    }
                                    title={
                                        <>
                                            {translate("word", "WIDTH")}
                                            <RequiredIcon />
                                        </>
                                    }
                                    labelColor={errors.width ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <FormTextInput
                                        inputType={errors.width ? "danger" : ""}
                                        iconName={isActive ? "edit" : null}
                                        iconType="FontAwesome"
                                        disabled={!isActive}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        keyboardType="decimal-pad"
                                        style={styles.fromContainer}
                                        placeholder={translate("word", "WIDTH")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="projection"
                            rules={{ required: true }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.projection?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.projection?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : ""
                                    }
                                    title={
                                        <>
                                            {translate("word", "PROJECTION")}
                                            <RequiredIcon />
                                        </>
                                    }
                                    labelColor={errors.projection ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSelect
                                        disabled={!isActive}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        data={PROJECTION_CHOICES}
                                        style={styles.fromContainer}
                                        textStyle={styles.fromContainerSelect}
                                        textSize={isMobile ? 14 : 16}
                                    />
                                </Field>
                            )}
                        />

                        <Controller
                            control={control}
                            name="automation"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.automation?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "AUTOMATION")}
                                    labelColor={errors.automation ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSwitch
                                        style={styles.checkBox}
                                        disabled={!isActive}
                                        value={value}
                                        onValueChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="crank"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.crank?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "CRANK")}
                                    labelColor={errors.crank ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSwitch
                                        style={styles.checkBox}
                                        disabled={!isActive}
                                        value={value}
                                        onValueChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="control"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.control?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "CONTROL")}
                                    labelColor={errors.control ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSwitch
                                        style={styles.checkBox}
                                        disabled={!isActive}
                                        value={value}
                                        onValueChange={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="control_side"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.control_side?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "CONTROL_SIDE")}
                                    labelColor={errors.control_side ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSelect
                                        disabled={!isActive}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        data={CONTROL_SIDE_CHOICES}
                                        style={styles.fromContainer}
                                        textStyle={styles.fromContainerSelect}
                                        textSize={isMobile ? 14 : 16}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="structure_color"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.structure_color?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "STRUCTURE_COLOR")}
                                    labelColor={errors.structure_color ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <FormTextInput
                                        inputType={errors.structure_color ? "danger" : ""}
                                        iconName={isActive ? "edit" : null}
                                        iconType="FontAwesome"
                                        disabled={!isActive}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        style={styles.fromContainer}
                                        placeholder={translate("word", "STRUCTURE_COLOR")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="location"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.location?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "LOCATION")}
                                    labelColor={errors.location ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <FormTextInput
                                        inputType={errors.location ? "danger" : ""}
                                        iconName={isActive ? "edit" : null}
                                        iconType="FontAwesome"
                                        disabled={!isActive}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        style={styles.fromContainer}
                                        placeholder={translate("word", "LOCATION")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="type_installation"
                            rules={{ required: false }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.type_installation?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.type_installation?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : ""
                                    }
                                    title={translate("word", "TYPE_INSTALLATION")}
                                    labelColor={errors.type_installation ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <StyledSelect
                                        disabled={!isActive}
                                        selectedValue={value}
                                        onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                        data={INSTALLATIONS_CHOICES}
                                        style={styles.fromContainer}
                                        textStyle={styles.fromContainerSelect}
                                        textSize={isMobile ? 14 : 16}
                                    />
                                </Field>
                            )}
                        />

                        <Controller
                            control={control}
                            name="area"
                            rules={{ required: true }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.area?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : ""
                                    }
                                    title={translate("word", "AREA")}
                                    labelColor={errors.area ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <FormTextInput
                                        inputType={errors.area ? "danger" : ""}
                                        disabled={true}
                                        style={styles.fromContainer}
                                        placeholder={translate("word", "AREA")}
                                        value={value}
                                        onChangeText={onChange}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="unit_cost"
                            rules={{ required: true }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.unit_cost?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.unit_cost?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : ""
                                    }
                                    title={translate("word", "UNIT_COST")}
                                    labelColor={errors.unit_cost ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <CurrencyInput
                                        renderTextInput={(textInputProps) => (
                                            <FormTextInput
                                                {...textInputProps}
                                                iconName={isActive ? "edit" : null}
                                                iconType="FontAwesome"
                                                keyboardType="decimal-pad"
                                                style={styles.currencyInput}
                                            />
                                        )}
                                        placeholder={translate("word", "UNIT_COST")}
                                        disabled={true}
                                        value={value}
                                        onBlur={onBlur}
                                        onChangeValue={onChange}
                                        prefix="$"
                                        delimiter=","
                                        separator="."
                                        precision={2}
                                        minValue={0}
                                        style={
                                            isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency
                                        }
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="total_cost"
                            rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                            style={styles.extrasContainer}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    label={
                                        errors.total_cost?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                            : errors.total_cost?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                            : ""
                                    }
                                    title={translate("word", "TOTAL_COST")}
                                    labelColor={errors.total_cost ? "danger" : undefined}
                                    style={isMobile ? styles.formFieldMobile : styles.formField}
                                    titleStyle={isMobile && styles.labelMobile}
                                    styleInput={isMobile && styles.containerInput}
                                >
                                    <CurrencyInput
                                        renderTextInput={(textInputProps) => (
                                            <FormTextInput
                                                {...textInputProps}
                                                iconName={isActive ? "edit" : null}
                                                iconType="FontAwesome"
                                                keyboardType="decimal-pad"
                                                style={styles.currencyInput}
                                            />
                                        )}
                                        placeholder={translate("word", "TOTAL_COST")}
                                        disabled={true}
                                        value={value}
                                        onBlur={onBlur}
                                        onChangeValue={onChange}
                                        prefix="$"
                                        delimiter=","
                                        separator="."
                                        precision={2}
                                        minValue={0}
                                        style={
                                            isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency
                                        }
                                    />
                                </Field>
                            )}
                        />
                        {!inCalculator && (
                            <Controller
                                control={control}
                                name="notes"
                                rules={{ required: false, maxLength: 240 }}
                                style={styles.extrasContainer}
                                render={({ field: { value, onChange, onBlur } }) => (
                                    <Field
                                        label={
                                            errors.notes?.type === "required"
                                                ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                                : errors.notes?.type === "maxLength"
                                                ? translate("sentence", "MAX_LENGTH_240")
                                                : ""
                                        }
                                        title={translate("word", "NOTES")}
                                        labelColor={errors.notes ? "danger" : undefined}
                                        style={styles.formField}
                                    >
                                        <FormTextInput
                                            inputType={errors.notes ? "danger" : ""}
                                            iconName={isActive ? "edit" : null}
                                            iconType="FontAwesome"
                                            disabled={!isActive}
                                            multiline={true}
                                            returnKeyType="next"
                                            autoCorrect={false}
                                            autoCapitalize="none"
                                            style={styles.fromContainer}
                                            placeholder={translate("word", "NOTES")}
                                            value={value}
                                            onChangeText={onChange}
                                            onBlur={onBlur}
                                        />
                                    </Field>
                                )}
                            />
                        )}
                        {!inCalculator && (
                            <FormAdditionals
                                {...{
                                    additionals,
                                    setAdditionals,
                                    setErrorForm,
                                    clearErrors,
                                    setTotalAdditional,
                                    isActive,
                                }}
                            />
                        )}
                    </FluidView>
                    {isActive && !inCalculator && (
                        <FormButton
                            buttonType="nummaBlue"
                            disabled={isLoading}
                            isLoading={isLoading}
                            style={styles.formButton}
                            onPress={handleSubmit(props.onSubmit)}
                        >
                            {translate("sentence", "SAVE")}
                        </FormButton>
                    )}
                </FluidView>
            )}
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    formField: {
        marginTop: 10,
    },
    formButton: {
        marginTop: 10,
    },
    extrasContainer: {},
    fromContainer: {
        backgroundColor: Colors.NUMMA_YELLOW,
        width: "100%",
    },
    fromContainerSelect: {
        backgroundColor: Colors.NUMMA_YELLOW,
        width: "100%",
    },
    loadingContainer: {
        marginTop: 30,
        alignItems: "center",
    },
    fromContainerCurrency: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingHorizontal: 0,
        paddingTop: 15,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 18,
        paddingLeft: 15,
    },
    checkBox: {
        justifyContent: "center",
    },
    fromContainerCurrencyMobile: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingHorizontal: 0,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 14,
        paddingLeft: 15,
    },
    formFieldMobile: {
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    fromContainerMobile: {
        width: "100%",
    },
    labelMobile: {
        marginVertical: "auto",
        width: "35%",
    },
    containerInput: {
        width: "65%",
    },
    currencyInput: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingRight: 10,
    },
});

export default FromToldo90;
