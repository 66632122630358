import { Platform } from 'react-native';
import { Button } from 'react-native';

import { Colors } from '../../../constants';

import { Spacing } from '../../../styles';

import { StyledIcon } from '../../UI/Styled';

const CustomButton = (props) => {
    return (
        <Button
            IconComponent={StyledIcon}
            iconSize={Spacing.headerIcon.size}
            color={Platform.OS === 'ios' ? Colors.WHITE : Colors.WHITE}
            {...props}
        />
    );
};

export default CustomButton;
