import Model from './Model';

export default class ToldoVertical extends Model {
    boot() {
        super.boot();

        this.modelName = 'toldo-vertical';
        this.config.customRoutes = [
            {
                name: 'data',
                type: 'get',
                url: 'data/t-vertical'
            },
            {
                name: 'update',
                type: 'patch',
                url: 'toldo-vertical/{id}/'
            }
        ]
    }

    data = (params) => {
        return this.withParams(params).route('data');
    };

    update = (id, params) => {
        return this.withParams(params).route('update', { id });
    };
}
