import { StyleSheet, ScrollView, Platform } from "react-native";

import { AvoidKeyboard, DismissKeyboard } from "../../components/Device";
import { StyledView, StyledText } from "../../components/UI/Styled";
import { Navbar } from "../../components/Layout/Header";

const Wrapper = (props) => {
    const { scroll = true, navbar = true, whiteNavbar = false } = props;

    const content = scroll ? (
        <ScrollView contentContainerStyle={styles.scroll}>{props.children}</ScrollView>
    ) : (
        props.children
    );

    var IsWeb = Platform.OS == "web";
    return (
        <AvoidKeyboard>
            {IsWeb && navbar && <Navbar whiteNavbar={whiteNavbar} />}
            {content}
        </AvoidKeyboard>
    );
};

const styles = StyleSheet.create({
    scroll: {
        flexGrow: 1,
    },
});

export default Wrapper;
