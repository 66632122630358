import { StyleSheet } from "react-native";

import { Typography } from "../../styles";

import FluidView from "./FluidView";
import { StyledText, StyledView } from "./Styled";

const Field = (props) => {
    const { title, label } = props;

    const styles = StyleSheet.create({
        containerInput: {
            ...StyleSheet.flatten(props.styleInput),
        },
        container: {
            ...StyleSheet.flatten(props.style),
        },
        title: {
            marginBottom: 10,
            ...StyleSheet.flatten(props.titleStyle),
        },
        label: {
            marginTop: 5,
            textAlign: "justify",
            ...props.labelStyle,
        },
    });

    return (
        <FluidView style={styles.container}>
            {!!title && (
                <StyledText
                    font={props.titleFont ?? Typography.fieldTitle.fontFamily}
                    size={props.titleSize ?? Typography.fieldTitle.fontSize}
                    color={props.titleColor ?? Typography.fieldTitle.color}
                    style={styles.title}
                >
                    {title}
                </StyledText>
            )}
            <StyledView style={styles.containerInput}>
                {props.children}
                {!!label && (
                    <StyledText
                        font={props.labelFont ?? Typography.fieldLabel.fontFamily}
                        size={props.labelSize ?? Typography.fieldLabel.fontSize}
                        color={props.labelColor ?? Typography.fieldLabel.color}
                        style={styles.label}
                    >
                        {label}
                    </StyledText>
                )}
            </StyledView>
        </FluidView>
    );
};

export default Field;
