import { useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Colors, Fonts } from "../../constants";

import { Spacing } from "../../styles";

import { userActions } from "../../store/slices/user";
import authActions from "../../store/actions/auth";

import { appActions } from "../../store/slices/app";

import { useLanguage } from "../../hooks";

import User from "../../models/User";
import { Typography } from "../../styles";

import { Wrapper, Screen, Container, LoadingModal, H1, H3, SettingsList, Divider, Card } from "../../components/UI";
import ProfilePicture from "../../components/Profile/Profile/ProfilePicture";
import ProfileLogout from "../../components/Profile/Profile/ProfileLogout";
import { StyledView } from "../../components/UI/Styled";

const ProfileScreen = ({ navigation, route }) => {
    const [isLoading, setIsLoading] = useState(false);

    const accessToken = useSelector((state) => state.auth.token);
    const userState = useSelector((state) => state.user);

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const dispatch = useDispatch();

    const { translate } = useLanguage();

    const profileSettingsData = [
        {
            id: "account",
            title: translate("sentence", "MY_ACCOUNT"),
            onPress: () => {
                navigation.navigate("Account");
            },
        },
        {
            id: "security",
            title: translate("word", "SECURITY"),
            onPress: () => {
                navigation.navigate("Security");
            },
        },
        {
            id: "prices",
            title: translate("word", "PRICES"),
            onPress: () => {
                navigation.navigate("Prices");
            },
        },
    ];

    const companySettingsData = [
        {
            id: "termsAndConditions",
            title: translate("sentence", "TERMS_AND_CONDITIONS"),
            onPress: () => {
                navigation.navigate("CompanyNav", { screen: "TermsAndConditions" });
            },
        },
    ];

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const imageTakenHandler = async (image) => {
        try {
            const { data: meResponseData } = await user.updateMe({ image });

            dispatch(userActions.setData({ profilePicture: meResponseData.image }));
        } catch (e) {
            console.log(e);
            dispatch(appActions.notReady());
        }
    };

    const imagePickedHandler = async (image) => {
        try {
            toggleIsLoading();

            const { data: meResponseData } = await user.updateMe({ image });

            dispatch(userActions.setData({ profilePicture: meResponseData.image }));
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            dispatch(appActions.notReady());
        }
    };

    const logoutHandler = async () => {
        try {
            toggleIsLoading();

            await dispatch(authActions.logout());

            setIsLoading(false);
            navigation.navigate("AuthNav");
        } catch (e) {
            toggleIsLoading();
        }
    };

    return (
        <Wrapper whiteNavbar={true}>
            <LoadingModal visible={isLoading} />
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container
                    flexDirection={isWeb ? "row" : "colum"}
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <StyledView>
                        <ProfilePicture
                            profilePicture={userState.profilePicture}
                            style={styles.profilePicture}
                            onPreviewImage={imagePickedHandler}
                            onImageTaken={imageTakenHandler}
                            onImagePicked={imagePickedHandler}
                        />
                        <H3 font={Fonts.PRIMARY.TITLE} color={Colors.BLACK} size={28}>
                            {userState.firstName} {userState.lastName}
                        </H3>
                        <ProfileLogout style={styles.profileLogout} onLogout={logoutHandler} />
                    </StyledView>
                    <Card
                        style={isWeb ? styles.displayCardWeb : styles.displayCard}
                        size={isWeb ? "web" : "large"}
                        width="70%"
                        height={isWeb ? 448 : 350}
                    >
                        <H1 size={50} color={Colors.BLACK}>
                            {translate("sentence", "MY_PROFILE")}
                        </H1>
                        <StyledView style={styles.containerForm}>
                            <SettingsList
                                data={profileSettingsData}
                                style={styles.profileSettings}
                                itemContainerStyle={styles.profileSettingsItemContainer}
                            />
                            <Divider style={styles.divider} color="shadowGray" />
                            <SettingsList
                                data={companySettingsData}
                                style={styles.companySettings}
                                itemContainerStyle={styles.companySettingsItemContainer}
                            />
                        </StyledView>
                    </Card>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    container: {
        minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth,
    },
    profilePicture: {
        marginTop: 30,
        cursor: "pointer",
    },
    profileLogout: {
        marginTop: 30,
        cursor: "pointer",
    },
    profileSettings: {
        marginTop: 30,
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    profileSettingsItemContainer: {
        paddingHorizontal: 0,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    divider: {
        marginTop: 50,
        marginBottom: 30,
    },
    companySettings: {
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    companySettingsItemContainer: {
        paddingHorizontal: 0,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    displayCardWeb: {
        alignSelf: "start",
        marginTop: "auto",
        marginBottom: "auto",
        alignItems: "start",
    },
    displayCard: {
        alignSelf: "center",
        marginTop: 15,
        width: "100%",
    },
    containerForm: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
});

export default ProfileScreen;
