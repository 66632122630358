import { StyleSheet, TouchableWithoutFeedback, Image } from 'react-native';
import { useSelector } from 'react-redux';
import { Button } from 'react-native';

import { Icons } from '../../../styles';

import CustomButton from './CustomButton';

const ProfileButton = ({ navigation }) => {
    const isAuth = useSelector((state) => !!state.auth.token);
    const userState = useSelector((state) => state.user);

    return isAuth ? (
        <TouchableWithoutFeedback
            accessible={false}
            onPress={() => {
                navigation.navigate('ProfileNav');
            }}
        >
            <Image
                source={
                    userState.profilePicture
                        ? { uri: userState.profilePicture }
                        : require('../../../assets/images/profile-picture.png')
                }
                style={styles.profilePicture}
            />
        </TouchableWithoutFeedback>
    ) : (
        <Button HeaderButtonComponent={CustomButton}
            title={'PROFILE'}
            iconName={Icons.header.profile}
            onPress={() => {
                navigation.navigate('AuthNav');
            }}
        >
        </Button>
    );
};

const styles = StyleSheet.create({
    profilePicture: {
        width: 30,
        height: 30,
        marginRight: 20,
        borderRadius: 15,
        overflow: 'hidden'
    }
});

export default ProfileButton;
