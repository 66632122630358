import { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';

import { Spacing } from '../../styles';

import { useLanguage } from '../../hooks';

import { Wrapper, Screen, Container, H3, Paragraph } from '../../components/UI';
import { CloseButton } from '../../components/Layout/Header';
import { BackButton } from '../../components/Layout/Header';
import { StyledView } from '../../components/UI/Styled';

const TermsAndConditionsScreen = ({ navigation, route }) => {

    const { translate } = useLanguage();

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => undefined,
            headerRight: () => <CloseButton {...{ navigation, route }} />
        });
    }, []);

    return (
        <Wrapper>
            <Screen>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} {...{ navigation }} />
                    </StyledView>
                    <H3>{translate('sentence', 'TERMS_AND_CONDITIONS')}</H3>
                    <Paragraph style={styles.paragraph}>Terminos y condiciones</Paragraph>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth
    },
    paragraph: {
        marginTop: 30
    },
    backButton: {
        alignSelf: "start"
    }
});

export default TermsAndConditionsScreen;
