import { StyleSheet, Text } from 'react-native';
import { capitalize, forIn } from 'lodash';

import { Colors, Fonts, Typography } from '../../../styles';

const getStylesFromProps = ({
    font,
    letterSpacing,
    color,
    text,
    lineHeight,
    size,
    align
}) => {
    const styles = { text: {} };

    if (typeof font !== 'undefined') {
        if (typeof font === 'object') {
            forIn(font, (fontValue, fontKey) => {
                styles.text[`font${capitalize(fontKey)}`] =
                    fontKey === 'family'
                        ? Fonts.primary[fontKey] ?? fontValue
                        : fontValue;
            });
        } else {
            styles.text.fontFamily = Fonts.primary[font] ?? font;
        }
    }

    if (typeof letterSpacing !== 'undefined') {
        styles.text.letterSpacing = letterSpacing;
    }

    if (typeof color !== 'undefined') {
        styles.text.color = Colors.app[color] ?? color;
    }

    if (typeof text !== 'undefined') {
        forIn(text, (textValue, textKey) => {
            styles.text[`text${capitalize(textKey)}`] = textValue;
        });
    }

    if (typeof lineHeight !== 'undefined') {
        styles.text.lineHeight = lineHeight;
    }

    if (typeof size !== 'undefined') {
        styles.text.fontSize = size;
    }

    if (typeof align !== 'undefined') {
        styles.text.textAlign = align;
    }

    return styles;
};

const StyledText = (props) => {
    const { text: textStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        text: {
            ...Typography.text,
            ...textStyle,
            ...StyleSheet.flatten(props.style)
        }
    });

    return (
        <Text {...props} style={styles.text}>
            {props.children}
        </Text>
    );
};

export default StyledText;
