import { configureStore } from '@reduxjs/toolkit';

import appSlice from './slices/app';
import authSlice from './slices/auth';
import userSlice from './slices/user';

const store = configureStore({
    reducer: {
        app: appSlice.reducer,
        auth: authSlice.reducer,
        user: userSlice.reducer
    }
});

export default store;
