import { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, Pressable, Linking, ScrollView } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { Popable } from "react-native-popable";

import { Table, TableWrapper, Row, Rows } from "react-native-table-component";

import { Colors, Fonts } from "../../constants";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import { Wrapper, Screen, Container, LoadingModal, Card, H1, H3, H5 } from "../../components/UI";
import { StyledView, StyledButton, StyledIcon, StyledModal, StyledText } from "../../components/UI/Styled";
import Project from "../../models/Project";
import ProjectForm from "../../components/Projects/ProjectForm";
import { autoLogin, refreshToken, logout } from "../../store/actions/auth";
import Pagination from "../../components/Projects/Pagination";

const ProjectScreen = ({ navigation }) => {
    const userAuth = useSelector((state) => state.auth);
    const accessToken = useSelector((state) => state.auth.token);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);
    const [viewSelected, setViewSelected] = useState(true);
    const [create, setCreate] = useState(false);
    const [dataTable, setDataTable] = useState([]);
    const [mobileData, setMobileData] = useState([]);
    const [paginationState, setPaginationState] = useState({ page: 1, nextLink: null, previousLink: null });

    const dispatch = useDispatch();

    const project = new Project({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const { translate } = useLanguage();

    const tableHead = [
        translate("word", "PROJECT_NAME"),
        translate("word", "CLIENT_NAME"),
        translate("word", "DIRECTION"),
        translate("word", "OPTIONS"),
    ];

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const toggleCreateModal = () => {
        setCreate((prevIsLoading) => !prevIsLoading);
    };

    const showActive = () => {
        if (!viewSelected) {
            setViewSelected(true);
        }
    };

    const showHistory = () => {
        if (viewSelected) {
            setViewSelected(false);
        }
    };

    const nextPage = () => {
        var page = paginationState.page + 1;
        setPaginationState({ ...paginationState, page: page });
    };

    const previousPage = () => {
        if (paginationState.page - 1 > 0) {
            var page = paginationState.page - 1;
            setPaginationState({ ...paginationState, page: page });
        }
    };

    const fetchDataActive = async (params) => {
        setIsLoading(true);
        try {
            params = params ? params : paginationState;
            const result = await project.allActive(params);
            setPaginationState({
                page: params.page,
                nextLink: result.data?.next !== null,
                previousLink: result.data?.previous !== null,
            });
            generateTable(result.data);
            setIsLoading(false);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const fetchDataHistoric = async (params) => {
        setIsLoading(true);
        try {
            params = params ? params : paginationState;
            const result = await project.allHistoric(params);
            setPaginationState({
                page: params.page,
                nextLink: result.data?.next !== null,
                previousLink: result.data?.previous !== null,
            });
            generateTable(result.data);
            setIsLoading(false);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const downloadProject = async (project_id, client) => {
        setIsLoading(true);
        try {
            var url;
            if (client) {
                url =
                    project.config.baseURL +
                    "/" +
                    project.customRoutes.generateQuotePdf.url.replace("{id}", project_id);
            } else {
                url = project.config.baseURL + "/" + project.customRoutes.generatePdf.url.replace("{id}", project_id);
            }
            var token = accessToken;
            try {
                await dispatch(autoLogin(accessToken));
            } catch (e) {
                try {
                    var response = await dispatch(refreshToken(userAuth.expirationTime, userAuth.refresh));
                    token = response;
                } catch (error) {
                    await dispatch(logout());
                }
            }
            Linking.openURL(url + "?auth_token=" + token).catch((err) =>
                console.error("Ocurrio un error intente más tarde", err)
            );
            setIsLoading(false);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const fetchCreateProject = async (data) => {
        toggleIsLoading();
        try {
            data.additionals_project = [];
            await project.create(data);
            toggleCreateModal();
            toggleIsLoading();
            setAlert(translate("sentence", "SAVED"));
            if (viewSelected) {
                fetchDataActive();
            } else {
                fetchDataHistoric();
            }
        } catch (e) {
            console.log("error", e);
            toggleCreateModal();
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    useEffect(() => {
        toggleIsLoading();
        if (viewSelected) {
            fetchDataActive();
        } else {
            fetchDataHistoric();
        }
    }, [paginationState.page]);

    useEffect(() => {
        if (!!accessToken) {
            toggleIsLoading();
            if (viewSelected) {
                fetchDataActive({ page: 1 });
            } else {
                fetchDataHistoric({ page: 1 });
            }
        }
    }, [viewSelected]);

    useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            if (!!accessToken) {
                toggleIsLoading();
                if (viewSelected) {
                    fetchDataActive();
                } else {
                    fetchDataHistoric();
                }
            }
        });

        return unsubscribe;
    }, [navigation]);

    const generateTable = (datas) => {
        var results = [];
        if (datas && datas.results) {
            results = datas.results?.map((result) => {
                return [
                    result.name,
                    result.client_name,
                    result.client_street,
                    <StyledView flexDirection="row" justifyContent="space-between" alignItems="center">
                        {viewSelected ? (
                            <StyledButton
                                style={styles.tableButtonContainer}
                                buttonType="white"
                                textSize={16}
                                textFont="regular"
                                textColor="nummaBlue"
                                onPress={() => {
                                    navigation.navigate("ProjectNav", {
                                        screen: "Details",
                                        params: { project_id: result.id },
                                    });
                                }}
                            >
                                {translate("sentence", "EDIT")}{" "}
                                <StyledIcon name="edit" iconType="FontAwesome" color="nummaBlue" />
                            </StyledButton>
                        ) : (
                            <StyledButton
                                style={styles.tableButtonContainer}
                                buttonType="white"
                                textSize={16}
                                textFont="regular"
                                textColor="nummaBlue"
                                onPress={() => {
                                    navigation.navigate("ProjectNav", {
                                        screen: "Details",
                                        params: { project_id: result.id },
                                    });
                                }}
                            >
                                {translate("sentence", "SEE")}{" "}
                                <StyledIcon name="eye" iconType="FontAwesome" color="nummaBlue" />
                            </StyledButton>
                        )}
                        <StyledButton
                            style={styles.tableButtonContainer}
                            buttonType="white"
                            textSize={16}
                            textFont="regular"
                            textColor="nummaBlue"
                            onPress={() => {
                                downloadProject(result.id, false);
                            }}
                        >
                            {translate("sentence", "DOWNLOAD_PDF")}
                        </StyledButton>
                        <StyledButton
                            style={styles.tableButtonContainer}
                            buttonType="white"
                            textSize={16}
                            textFont="regular"
                            textColor="nummaBlue"
                            onPress={() => {
                                downloadProject(result.id, true);
                            }}
                        >
                            {translate("sentence", "DOWNLOAD_QUOTE_PDF")}
                        </StyledButton>
                    </StyledView>,
                ];
            });
            setDataTable(results);

            let resultsMobile = datas.results?.map((result) => {
                return (
                    <Card style={styles.cardProjectMobile} key={result.id}>
                        <StyledView style={styles.containerW100}>
                            <StyledView style={{ ...styles.containerW100, zIndex: 3 }} flexDirection="row">
                                <H5>{result.name}</H5>
                                <StyledView style={styles.buttonRightContainerTitle}>
                                    <Popable
                                        animationType="spring"
                                        content={
                                            <StyledView
                                                style={{
                                                    boxShadow: "0px 0px 5px gray",
                                                    borderRadius: 5,
                                                    margin: 5,
                                                }}
                                            >
                                                {viewSelected ? (
                                                    <StyledButton
                                                        style={styles.buttonRight}
                                                        buttonType="white"
                                                        textSize={12}
                                                        textFont="regular"
                                                        textColor="nummaBlue"
                                                        onPress={() => {
                                                            navigation.navigate("ProjectNav", {
                                                                screen: "Details",
                                                                params: { project_id: result.id },
                                                            });
                                                        }}
                                                    >
                                                        {translate("sentence", "EDIT")}{" "}
                                                        <StyledIcon
                                                            name="edit"
                                                            size={12}
                                                            iconType="FontAwesome"
                                                            color="nummaBlue"
                                                        />
                                                    </StyledButton>
                                                ) : (
                                                    <StyledButton
                                                        style={styles.buttonRight}
                                                        buttonType="white"
                                                        textSize={12}
                                                        textFont="regular"
                                                        textColor="nummaBlue"
                                                        onPress={() => {
                                                            navigation.navigate("ProjectNav", {
                                                                screen: "Details",
                                                                params: { project_id: result.id },
                                                            });
                                                        }}
                                                    >
                                                        {translate("sentence", "SEE")}{" "}
                                                        <StyledIcon
                                                            name="eye"
                                                            size={12}
                                                            iconType="FontAwesome"
                                                            color="nummaBlue"
                                                        />
                                                    </StyledButton>
                                                )}
                                            </StyledView>
                                        }
                                        backgroundColor={Colors.WHITE}
                                        style={{
                                            transform: "translateX(-80px)",
                                            width: 115,
                                        }}
                                        caret={false}
                                        position="bottom"
                                        strictPosition={true}
                                    >
                                        <StyledIcon
                                            size={25}
                                            name="dots-horizontal"
                                            iconType="MaterialCommunityIcons"
                                            color={Colors.NUMMA_BLUE}
                                        />
                                    </Popable>
                                </StyledView>
                            </StyledView>
                            <StyledText style={styles.textLocationMobile}>
                                {(result.client_street ||
                                    result.client_number ||
                                    result.client_state ||
                                    result.client_city) && (
                                    <>
                                        <StyledIcon
                                            name="ios-location-sharp"
                                            iconType="Ionicons"
                                            color={Colors.NUMMA_BLUE}
                                        />{" "}
                                        {result.client_street} {result.client_number && `#${result.client_number}`}{" "}
                                        {result.client_suburb && `Col. ${result.client_suburb}.`} {result.client_state}{" "}
                                        {result.client_city}
                                    </>
                                )}
                            </StyledText>
                            <StyledText style={styles.textMobileGray}>Cliente: {result.client_name}</StyledText>
                            <StyledView style={styles.containerW100} flexDirection="row">
                                <StyledButton
                                    style={styles.tableButtonContainer}
                                    buttonType="white"
                                    textSize={12}
                                    textFont="regular"
                                    textColor="nummaBlue"
                                    onPress={() => {
                                        downloadProject(result.id, false);
                                    }}
                                >
                                    <StyledIcon name="download" size={16} iconType="FontAwesome" color="nummaBlue" />{" "}
                                    {translate("sentence", "DOWNLOAD_PDF")}
                                </StyledButton>
                                <StyledButton
                                    style={styles.tableButtonContainer}
                                    buttonType="white"
                                    textSize={12}
                                    textFont="regular"
                                    textColor="nummaBlue"
                                    onPress={() => {
                                        downloadProject(result.id, true);
                                    }}
                                >
                                    <StyledIcon name="download" size={16} iconType="FontAwesome" color="nummaBlue" />{" "}
                                    {translate("sentence", "DOWNLOAD_QUOTE_PDF")}
                                </StyledButton>
                            </StyledView>
                        </StyledView>
                    </Card>
                );
            });
            setMobileData(resultsMobile);
        }
    };

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <Screen style={!isMobile ? styles.screen : styles.screenMobile}>
                <StyledModal
                    modalType="alert"
                    visible={!!error}
                    size="medium"
                    title={translate("word", "ERROR")}
                    titleColor="danger"
                    onAccept={removeError}
                    onPressOut={removeError}
                    onRequestClose={removeError}
                >
                    {error}
                </StyledModal>
                <StyledModal
                    modalType="alert"
                    visible={!!alert}
                    size="medium"
                    title={translate("word", "INFO")}
                    titleColor="info"
                    onAccept={removeAlert}
                    onPressOut={removeAlert}
                    onRequestClose={removeAlert}
                >
                    {alert}
                </StyledModal>
                <StyledModal
                    visible={!!create}
                    size={!isMobile ? "web" : "large"}
                    title={translate("sentence", "CREATE_PROJECT")}
                    cardStyle={
                        !isMobile
                            ? { maxWidth: "50%", height: "80%" }
                            : { height: "85%", marginTop: "auto", backgroundColor: Colors.LIGHT_GRAY }
                    }
                    onAccept={toggleCreateModal}
                    onRequestClose={toggleCreateModal}
                >
                    <StyledView style={styles.containerW100} alignItems="end">
                        <Pressable onPress={toggleCreateModal}>
                            <StyledIcon name="close" iconType="FontAwesome" color={Colors.DARK_GRAY} />
                        </Pressable>
                    </StyledView>
                    <H3>{translate("sentence", "CREATE_PROJECT")}</H3>
                    <ScrollView style={styles.container100}>
                        <ProjectForm isLoading={isLoading} onSubmit={fetchCreateProject} />
                    </ScrollView>
                </StyledModal>
                <Container>
                    <Card size="large" style={!isMobile ? styles.titleCard : styles.titleCardMobile}>
                        <StyledView style={styles.title}>
                            <H1 size={50} color={Colors.BLACK}>
                                {translate("sentence", "OWN_PROJECTS")}
                            </H1>
                        </StyledView>
                    </Card>
                    <Card size="large" style={!isMobile ? styles.bodyCard : styles.bodyCardMobile}>
                        <StyledView
                            style={styles.containerW100}
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            {!isMobile ? (
                                <>
                                    <StyledButton
                                        buttonType={viewSelected ? "primary" : "secondary"}
                                        textFont="bold"
                                        textSize={18}
                                        width="48%"
                                        onPress={showActive}
                                        style={styles.activeButtonContainer}
                                    >
                                        {translate("verb", "ACTIVES")}
                                    </StyledButton>
                                    <StyledButton
                                        buttonType={viewSelected ? "secondary" : "primary"}
                                        textFont="bold"
                                        textSize={18}
                                        width="48%"
                                        onPress={showHistory}
                                        style={styles.activeButtonContainer}
                                    >
                                        {translate("verb", "HISTORIC")}
                                    </StyledButton>
                                </>
                            ) : (
                                <>
                                    <StyledButton
                                        buttonType="secondary"
                                        textFont="bold"
                                        textSize={18}
                                        width="50%"
                                        onPress={showActive}
                                        textColor={viewSelected ? Colors.NUMMA_BLUE : Colors.LIGHT_GRAY}
                                        style={viewSelected ? styles.buttonMobileActive : styles.buttonMobileInactive}
                                    >
                                        {translate("verb", "ACTIVES")}
                                    </StyledButton>
                                    <StyledButton
                                        buttonType="secondary"
                                        textFont="bold"
                                        textSize={18}
                                        width="50%"
                                        onPress={showHistory}
                                        textColor={viewSelected ? Colors.LIGHT_GRAY : Colors.NUMMA_BLUE}
                                        style={viewSelected ? styles.buttonMobileInactive : styles.buttonMobileActive}
                                    >
                                        {translate("verb", "HISTORIC")}
                                    </StyledButton>
                                </>
                            )}
                        </StyledView>
                        {!isMobile ? (
                            <StyledView style={styles.containerW100}>
                                {!isLoading && (
                                    <Table
                                        style={StyleSheet.flatten(styles.table)}
                                        borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}
                                    >
                                        <Row
                                            data={tableHead}
                                            style={StyleSheet.flatten(styles.header)}
                                            textStyle={StyleSheet.flatten(styles.textHeader)}
                                        />
                                        <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                            <Rows
                                                data={dataTable}
                                                style={StyleSheet.flatten(styles.row)}
                                                textStyle={StyleSheet.flatten(styles.text)}
                                            />
                                        </TableWrapper>
                                    </Table>
                                )}
                            </StyledView>
                        ) : (
                            <ScrollView style={styles.scrollContainerMobile}>
                                <StyledView style={styles.containerMobileItems}>
                                    {!isLoading &&
                                        (mobileData.length > 0 ? (
                                            mobileData
                                        ) : (
                                            <StyledText style={styles.text}>
                                                {translate("word", "NO_PROJECTS")}
                                            </StyledText>
                                        ))}
                                </StyledView>
                            </ScrollView>
                        )}
                        <Pagination pageStatus={paginationState} nextPage={nextPage} previousPage={previousPage} />
                        {viewSelected && (
                            <StyledView style={!isMobile ? styles.containerAdd : styles.containerAddMobile}>
                                <StyledButton
                                    buttonType={!isMobile ? "nummaBlue" : "white"}
                                    textColor={!isMobile ? "white" : "nummaBlue"}
                                    textSize={16}
                                    style={styles.addButtonContainer}
                                    onPress={toggleCreateModal}
                                >
                                    <StyledIcon
                                        name="plus-circle"
                                        size={14}
                                        iconType="FontAwesome5"
                                        color={!isMobile ? "white" : "nummaBlue"}
                                    />{" "}
                                    {translate("sentence", "ADD_PROJECT")}
                                </StyledButton>
                            </StyledView>
                        )}
                    </Card>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    titleCard: {
        alignItems: "start",
        marginTop: 30,
    },
    title: {
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: 20,
    },
    bodyCard: {
        marginTop: 30,
        paddingHorizontal: 30,
        paddingVertical: 30,
    },
    activeButtonContainer: {
        paddingVertical: 5,
        width: "100%",
    },
    tableButtonContainer: {
        height: 35,
        paddingVertical: 3,
        paddingHorizontal: 3,
        color: Colors.WHITE,
    },
    addButtonContainer: {
        paddingVertical: 5,
    },
    containerW100: {
        width: "100%",
    },
    table: {
        marginTop: 30,
    },
    header: {
        height: 50,
        backgroundColor: Colors.WHITE,
    },
    textHeader: {
        textAlign: "center",
        fontFamily: Fonts.PRIMARY.BOLD,
    },
    text: {
        textAlign: "center",
    },
    dataWrapper: {
        marginTop: -1,
    },
    row: {
        height: 40,
        backgroundColor: Colors.WHITE,
    },
    containerAdd: {
        alignSelf: "start",
        marginTop: 10,
    },
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
        paddingRight: 6,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    titleCardMobile: {
        display: "none",
    },
    bodyCardMobile: {
        paddingHorizontal: "5%",
        height: "100%",
    },
    buttonMobileActive: {
        paddingVertical: 5,
        width: "100%",
        borderRadius: 0,
        backgroundColor: Colors.WHITE,
        borderBottomWidth: 2,
        borderBottomColor: Colors.NUMMA_BLUE,
    },
    buttonMobileInactive: {
        paddingVertical: 5,
        width: "100%",
        borderRadius: 0,
        backgroundColor: Colors.WHITE,
        borderBottomWidth: 0,
    },
    containerMobileItems: {
        paddingTop: 15,
        width: "100%",
    },
    cardProjectMobile: {
        marginVertical: 10,
        borderRadius: 15,
    },
    textLocationMobile: {
        color: Colors.NUMMA_BLUE,
        fontSize: 12,
    },
    textMobileGray: {
        color: Colors.GRAY,
        fontSize: 12,
    },
    buttonRightContainer: {
        marginLeft: "auto",
    },
    buttonRightContainerTitle: {
        marginLeft: "auto",
        height: 35,
        paddingVertical: 3,
        paddingHorizontal: 3,
        color: Colors.WHITE,
    },
    buttonRight: {
        height: 35,
        paddingVertical: 3,
        paddingHorizontal: 3,
        color: Colors.WHITE,
    },
    containerAddMobile: {
        alignSelf: "center",
        marginTop: 10,
    },
    scrollContainerMobile: {
        width: "100%",
        height: "50vh",
    },
});

export default ProjectScreen;
