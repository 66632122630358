import { useEffect } from 'react';
import { Platform, StyleSheet, Image } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { Colors } from '../constants';

import { Spacing } from '../styles';

import { appActions } from '../store/slices/app';
import { authActions } from '../store/slices/auth';
// import { companyActions } from '../store/slices/company';
import { autoLogin, refreshToken, logout } from '../store/actions/auth';

import { config } from '../models';

import { Screen } from '../components/UI';

const isIos = Platform.OS === 'ios';

const StartupScreen = () => {
    const appState = useSelector((state) => state.app);

    const dispatch = useDispatch();
    let loading = true;
    useEffect(() => {
        const getStoredData = async () => {
            const appData = await AsyncStorage.getItem('appData');
            const authData = await AsyncStorage.getItem('authData');

            return {
                appData: appData !== null ? JSON.parse(appData) : null,
                authData: authData !== null ? JSON.parse(authData) : null
            };
        };

        const initApp = async () => {
            const { appData, authData } = await getStoredData();

            if (appData) {
                dispatch(appActions.setData({ ...appData }));
            } else {
                await AsyncStorage.setItem(
                    'appData',
                    JSON.stringify({
                        country: appState.country,
                        language: appState.language,
                        timeZone: appState.timeZone,
                        currency: appState.currency
                    })
                );
            }

            if (authData?.token) {
                dispatch(authActions.authenticate({ ...authData }));

                try {
                    await dispatch(autoLogin(authData.token));
                } catch (e) {
                    try {
                        await dispatch(refreshToken(authData.expirationTime,authData.refresh));
                    } catch (error) {
                        await dispatch(logout());
                    }

                }
            }

            dispatch(appActions.isReady());
        };

        initApp();
    }, [dispatch]);

    return (
        <Screen style={styles.screen}>
            <Image
                style={styles.logo}
                source={
                    isIos
                        ? require('../assets/images/logo-white.png')
                        : require('../assets/images/logo-white.png')
                }
            />
        </Screen>
    );
};

const styles = StyleSheet.create({
    screen: {
        backgroundColor: isIos ? Colors.PRIMARY : Colors.PRIMARY
    },
    logo: {
        width: Spacing.logo.width,
        height: Spacing.logo.height
    }
});

export default StartupScreen;
