import { StyleSheet } from 'react-native';
import {
    Ionicons,
    FontAwesome,
    FontAwesome5,
    MaterialCommunityIcons,
    Entypo
} from '@expo/vector-icons';

import { Colors, Spacing } from '../../../styles';

const getIcon = (props) => {
    const styles = StyleSheet.create({
        icon: { ...StyleSheet.flatten(props.style) }
    });

    switch (props.iconType) {
        case 'FontAwesome':
            return (
                <FontAwesome
                    {...props}
                    size={props.size ?? Spacing.icon.size}
                    color={Colors.app[props.color] ?? props.color ?? Colors.icon.icon}
                    style={styles.icon}
                />
            );
        case 'FontAwesome5':
            return (
                <FontAwesome5
                    {...props}
                    size={props.size ?? Spacing.icon.size}
                    color={Colors.app[props.color] ?? props.color ?? Colors.icon.icon}
                    style={styles.icon}
                />
            );
        case 'MaterialCommunityIcons':
            return (
                <MaterialCommunityIcons
                    {...props}
                    size={props.size ?? Spacing.icon.size}
                    color={Colors.app[props.color] ?? props.color ?? Colors.icon.icon}
                    style={styles.icon}
                />
            );
        case 'Entypo':
            return (
                <Entypo
                    {...props}
                    size={props.size ?? Spacing.icon.size}
                    color={Colors.app[props.color] ?? props.color ?? Colors.icon.icon}
                    style={styles.icon}
                />
            );
        default:
            return (
                <Ionicons
                    {...props}
                    size={props.size ?? Spacing.icon.size}
                    color={Colors.app[props.color] ?? props.color ?? Colors.icon.icon}
                    style={styles.icon}
                />
            );
    }
};

const StyledIcon = (props) => {
    return getIcon(props);
};

export default StyledIcon;
