import { StyleSheet } from 'react-native';

import { Views } from '../../styles';

import { StyledView } from './Styled';

const Screen = (props) => {
    const styles = StyleSheet.create({
        screen: {
            paddingHorizontal: Views.screen.paddingHorizontal,
            paddingVertical: Views.screen.paddingVertical,
            backgroundColor: Views.screen.backgroundColor,
            ...StyleSheet.flatten(props.style)
        }
    });    

    return (
        <StyledView
            flex={Views.screen.flex}
            justifyContent={Views.screen.justifyContent}
            alignItems={Views.screen.alignItems}
            {...props}
            style={styles.screen}
        >
            {props.children}
        </StyledView>
    );
};

export default Screen;
