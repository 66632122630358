import { StyleSheet, useWindowDimensions } from 'react-native';

import { StyledTextInput } from '../UI/Styled';

const FormTextInput = (props) => {
  const layout = useWindowDimensions();

  return (
    <StyledTextInput
      textSize={layout.width > 768 ? 18 : 14}
      iconSize={layout.width > 768 ? 25 : 16}
      iconColor="darkGray"
      showPasswordIconColor="darkGray"
      hidePasswordIconColor="darkGray"
      textStyle={styles.inputMobile}
      {...props}
      style={{ ...(layout.width > 768 ? styles.container : styles.containerMobile), ...StyleSheet.flatten(props.style) }}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 0,
    paddingTop: 15,
    paddingBottom: 10,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRadius: 0
  },
  containerMobile: {
    paddingHorizontal: 0,
    paddingTop: 7,
    paddingBottom: 5,
    borderWidth: 0,
    borderBottomWidth: 1,
    borderRadius: 0
  },
  inputMobile: {
    width:"100%"
  }
});

export default FormTextInput;
