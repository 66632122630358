/* Common */

const YES = "Yes";

const NO = "No";

const OK = "Ok";

const NEW = "New";

const OLD = "Old";

const QUALITY = "Quality";

const TYPE = "Type";

const COST = "Cost";

const RENT = "Rent";

const BUY = "Buy";

const TOTAL = "Total";

const LOCATION = "Location";

const INFORMATION = "Information";

const ASSISTANCE = "Assistance";

const DATE = "Date";

const YEAR = "Year";

const MONTH = "Month";

const DAY = "Day";

const HOUR = "Hour";

const MINUTE = "Minute";

const SECOND = "Second";

const MORE = "More";

const DOCUMENT = "Document";

const DOCUMENTS = "Documents";

const OTHER = "Other";

const OTHERS = "Others";

const NAME = "Name";

const LASTNAME = "Last Name";

const NAMES = "Names";

const CODE = "Code";

const CODES = "Codes";

const NICKNAME = "Nickname";

/* Verb */

const KNOW = "Know";

/* Objects */

const CAMERA = "Camera";

const GALLERY = "Gallery";

const BEDROOM = "Bedroom";

const BATHROOM = "Bathroom";

const GARAGE = "Garage";

const PARKING_LOT = "Parking lot";

const POOL = "Pool";

/* Sections */

const MENU = "Menu";

const PROFILE = "Profile";

const HOME = "Home";

const TODAY = "Today";

const EXPLORE = "Explore";

const PROPERTIES = "Properties";

const SERVICES = "Services";

const MESSAGES = "Messages";

/* Profile */

const SECURITY = "Security";

const SETTINGS = "Settings";

const EXAMPLE_FILE = "Example file";

/* Account */

const CURP = "CURP";

const ADDRESS = "Address";

const DOMICILE = "Domicile";

const PHONE_NUMBER = "Phone number";

/* Settings */

const LANGUAGE = "Language";

const CURRENCY = "Currency";

const NOTIFICATIONS = "Notifications";

/* Explore */

const PROPERTY = "Property";

const FEATURES = "Features";

const PLACE = "Place";

const LABEL = "Label";

const LABELS = "Labels";

const ZONE = "Zone";

const ZONES = "Zones";

const AVENUE = "Avenue";

const AVENUES = "Avenues";

const ELEMENTS = "Elements";

const SUBURB = "Suburb";

const SUBURBS = "Suburbs";

const TERRAIN = "Terrain";

const BUILDING = "Building";

const SERVICE = "Service";

const SCORE = "Score";

const FILTER = "Filter";

const FILTERS = "Filters";

const CITY = "City";

const CITIES = "Cities";

const PRICE = "Price";

const PRICES = "Prices";

const ROOM = "Room";

const ROOMS = "Rooms";

const FLOOR = "Floor";

const FLOORS = "Floors";

/* Properties */

const OWN = "Own";

const MANAGED = "Managed";

const DEVELOPMENT = "Development";

const CONVERSATION = "Conversation";

const DATA_SHEET = "Data sheet";

const COUNTRY = "Country";

const COUNTRIES = "Countries";

const STATE = "State";

const STATES = "States";

const STREET = "Street";

const STREETS = "Streets";

const EXT_NUMBER = "Ext. num";

const INT_NUMBER = "Int. num";

const ZIP_CODE = "Zip code";

/* Services */

const EXTERNAL = "External";

const PLAN = "Plan";

const PLANS = "Plans";

const APPOINTMENT = "Appointment";

const APPOINTMENTS = "Appointments";

const CONTRACT = "Contract";

const CONTRACTS = "Contracts";

const EXPIRATION = "Vencimiento";

/* Form */

const SUCCESS = "Success";

const ERROR = "Error";

const DANGER = "Danger";

const WARNING = "Warning";

const INFO = "Info";

const MODAL = "Modal";

const ALERT = "Alert";

const DIALOG = "Dialog";

const SUBJECT = "Subject";

const MESSAGE = "Message";

const FRONT = "Front";

const NEXT = "Next";

const BACK = "Back";

const RETURN = "Return";

const LOADING = "Loading";

const MINIMUM = "Minimum";

const MAXIMUM = "Maximum";

const RESULT = "Result";

const RESULTS = "Results";

const REQUIREMENT = "Requirement";

const REQUIREMENTS = "Requirements";

const CLAUSE = "Clause";

const CLAUSES = "Clauses";

const ADVANTAGE = "Advantage";

const ADVANTAGES = "Advantages";

const DISADVANTAGE = "Disadvantage";

const DISADVANTAGES = "Disadvantages";

const OFFER = "Offer";

const OFFERS = "Offers";

/* Fields */

const USER = "User";

const EMAIL = "Email";

const CURRENT_PASSWORD = "Current password";

const PASSWORD = "Password";

const CONFIRM_PASSWORD = "Confirm password";

const RECOVERY_CODE = "Recovery code";

const VERIFICATION_CODE = "Verification code";

const RFC = "Rfc";

/* Project */

const PROJECT_NAME = "Project";

const CLIENT_NAME = "Client";

const CLIENT_PHONE = "Telefono";

const CLIENT_EMAIL = "Email";

const NUMBER = "Número";

const DIRECTION = "Direction";

const OPTIONS = "Options";

const QUANTITY = "Quantity";

const AREA = "Area";

const NOTES = "Notes";

const MODEL = "Model";

const COLOR = "Color";

const WIDTH = "Width";

const HEIGTH = "Heigth";

const PROJECTION = "Projection";

const CALCULATEBY = "Calculate by : ";

const TYPE_INSTALLATION = "Type of installation";

const TYPE_PERSIANA = "Type of persiana";

const INSTALLATION = "Instalación";

const WALL = "Wall";

const CEILING = "Ceiling";

const CONFECTION = "Confection";

const CONSUMPTION = "Consumption";

const ROLL_WIDTH = "Roll width";

const BASTILLAS = "Bastillas";

const TOTAL_WIDTH = "Total width";

const TOTAL_HEIGTH = "Total heigth";

const FOLDS = "Folds";

const UNIT_COST = "Unit cost";

const TOTAL_COST = "Total cost";

const ADDITIONAL_DATA = "Additional data";

const AUTOMATION = "Automation";

const CONTROL = "Control";

const CONTROL_SIDE = "Control side";

const CONTROL_HEIGTH = "Control Heigth";

const CRANK = "Manivela";

const NO_PROJECTS = "No projects";

const IVA = "IVA";

const DISCOUNT = "Descuento";

const ADVANCE = "Anticipo";

/* Calculator */

const CALCULATOR = "Calculator";

const PERSIANA = "Persiana";

const TOLDO_90 = "Toldo 90°";

const TOLDO_VERTICAL = "Toldo vertical";

const TOLDO_PROYECCION = "Toldo proyeccion";

const CORTINA = "Cortina";

const LEFT = "Izquierda";

const RIGHT = "Derecha";

const RIGHTLEFT = "Agrupación a los lados";

const STRUCTURE_COLOR = "Perfilería";

const TEJIDO = "Tejido";

const SELLING_POLICIES = "Políticas de venta";

/* Others */

const OF = "of";

export {
    YES,
    NO,
    OK,
    NEW,
    OLD,
    QUALITY,
    TYPE,
    COST,
    RENT,
    BUY,
    TOTAL,
    LOCATION,
    INFORMATION,
    ASSISTANCE,
    DATE,
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MORE,
    DOCUMENT,
    DOCUMENTS,
    OTHER,
    OTHERS,
    NAME,
    LASTNAME,
    NAMES,
    CODE,
    CODES,
    NICKNAME,
    KNOW,
    CAMERA,
    GALLERY,
    BEDROOM,
    BATHROOM,
    GARAGE,
    PARKING_LOT,
    POOL,
    MENU,
    PROFILE,
    HOME,
    TODAY,
    EXPLORE,
    PROPERTIES,
    SERVICES,
    MESSAGES,
    SECURITY,
    SETTINGS,
    EXAMPLE_FILE,
    CURP,
    ADDRESS,
    DOMICILE,
    PHONE_NUMBER,
    LANGUAGE,
    CURRENCY,
    NOTIFICATIONS,
    PROPERTY,
    FEATURES,
    PLACE,
    LABEL,
    LABELS,
    ZONE,
    ZONES,
    AVENUE,
    AVENUES,
    ELEMENTS,
    SUBURB,
    SUBURBS,
    TERRAIN,
    BUILDING,
    SERVICE,
    SCORE,
    FILTER,
    FILTERS,
    CITY,
    CITIES,
    PRICE,
    PRICES,
    ROOM,
    ROOMS,
    FLOOR,
    FLOORS,
    OWN,
    MANAGED,
    DEVELOPMENT,
    CONVERSATION,
    DATA_SHEET,
    COUNTRY,
    COUNTRIES,
    STATE,
    STATES,
    STREET,
    STREETS,
    EXT_NUMBER,
    INT_NUMBER,
    ZIP_CODE,
    EXTERNAL,
    PLAN,
    PLANS,
    APPOINTMENT,
    APPOINTMENTS,
    CONTRACT,
    CONTRACTS,
    EXPIRATION,
    SUCCESS,
    ERROR,
    DANGER,
    WARNING,
    INFO,
    MODAL,
    ALERT,
    DIALOG,
    SUBJECT,
    MESSAGE,
    FRONT,
    NEXT,
    BACK,
    RETURN,
    LOADING,
    MINIMUM,
    MAXIMUM,
    RESULT,
    RESULTS,
    REQUIREMENT,
    REQUIREMENTS,
    CLAUSE,
    CLAUSES,
    ADVANTAGE,
    ADVANTAGES,
    DISADVANTAGE,
    DISADVANTAGES,
    OFFER,
    OFFERS,
    USER,
    EMAIL,
    CURRENT_PASSWORD,
    PASSWORD,
    CONFIRM_PASSWORD,
    RECOVERY_CODE,
    VERIFICATION_CODE,
    RFC,
    PROJECT_NAME,
    CLIENT_NAME,
    CLIENT_PHONE,
    CLIENT_EMAIL,
    NUMBER,
    DIRECTION,
    OPTIONS,
    QUANTITY,
    AREA,
    NOTES,
    MODEL,
    COLOR,
    WIDTH,
    HEIGTH,
    PROJECTION,
    CALCULATEBY,
    TYPE_INSTALLATION,
    TYPE_PERSIANA,
    INSTALLATION,
    WALL,
    CEILING,
    CONFECTION,
    CONSUMPTION,
    ROLL_WIDTH,
    BASTILLAS,
    TOTAL_WIDTH,
    TOTAL_HEIGTH,
    FOLDS,
    UNIT_COST,
    TOTAL_COST,
    ADDITIONAL_DATA,
    AUTOMATION,
    CONTROL,
    CONTROL_SIDE,
    CONTROL_HEIGTH,
    CRANK,
    NO_PROJECTS,
    IVA,
    DISCOUNT,
    ADVANCE,
    CALCULATOR,
    PERSIANA,
    TOLDO_90,
    TOLDO_VERTICAL,
    TOLDO_PROYECCION,
    CORTINA,
    LEFT,
    RIGHT,
    RIGHTLEFT,
    STRUCTURE_COLOR,
    TEJIDO,
    SELLING_POLICIES,
    OF,
};

export default {
    YES,
    NO,
    OK,
    NEW,
    OLD,
    QUALITY,
    TYPE,
    COST,
    RENT,
    BUY,
    TOTAL,
    LOCATION,
    INFORMATION,
    ASSISTANCE,
    DATE,
    YEAR,
    MONTH,
    DAY,
    HOUR,
    MINUTE,
    SECOND,
    MORE,
    DOCUMENT,
    DOCUMENTS,
    OTHER,
    OTHERS,
    NAME,
    LASTNAME,
    NAMES,
    CODE,
    CODES,
    NICKNAME,
    KNOW,
    CAMERA,
    GALLERY,
    BEDROOM,
    BATHROOM,
    GARAGE,
    PARKING_LOT,
    POOL,
    MENU,
    PROFILE,
    HOME,
    TODAY,
    EXPLORE,
    PROPERTIES,
    SERVICES,
    MESSAGES,
    SECURITY,
    SETTINGS,
    EXAMPLE_FILE,
    CURP,
    ADDRESS,
    DOMICILE,
    PHONE_NUMBER,
    LANGUAGE,
    CURRENCY,
    NOTIFICATIONS,
    PROPERTY,
    FEATURES,
    PLACE,
    LABEL,
    LABELS,
    ZONE,
    ZONES,
    AVENUE,
    AVENUES,
    ELEMENTS,
    SUBURB,
    SUBURBS,
    TERRAIN,
    BUILDING,
    SERVICE,
    SCORE,
    FILTER,
    FILTERS,
    CITY,
    CITIES,
    PRICE,
    PRICES,
    ROOM,
    ROOMS,
    FLOOR,
    FLOORS,
    OWN,
    MANAGED,
    DEVELOPMENT,
    CONVERSATION,
    DATA_SHEET,
    COUNTRY,
    COUNTRIES,
    STATE,
    STATES,
    STREET,
    STREETS,
    EXT_NUMBER,
    INT_NUMBER,
    ZIP_CODE,
    EXTERNAL,
    PLAN,
    PLANS,
    APPOINTMENT,
    APPOINTMENTS,
    CONTRACT,
    CONTRACTS,
    EXPIRATION,
    SUCCESS,
    ERROR,
    DANGER,
    WARNING,
    INFO,
    MODAL,
    ALERT,
    DIALOG,
    SUBJECT,
    MESSAGE,
    FRONT,
    NEXT,
    BACK,
    RETURN,
    LOADING,
    MINIMUM,
    MAXIMUM,
    RESULT,
    RESULTS,
    REQUIREMENT,
    REQUIREMENTS,
    CLAUSE,
    CLAUSES,
    ADVANTAGE,
    ADVANTAGES,
    DISADVANTAGE,
    DISADVANTAGES,
    OFFER,
    OFFERS,
    USER,
    EMAIL,
    CURRENT_PASSWORD,
    PASSWORD,
    CONFIRM_PASSWORD,
    RECOVERY_CODE,
    VERIFICATION_CODE,
    RFC,
    PROJECT_NAME,
    CLIENT_NAME,
    CLIENT_PHONE,
    CLIENT_EMAIL,
    NUMBER,
    DIRECTION,
    OPTIONS,
    QUANTITY,
    AREA,
    NOTES,
    MODEL,
    COLOR,
    WIDTH,
    HEIGTH,
    PROJECTION,
    CALCULATEBY,
    TYPE_INSTALLATION,
    TYPE_PERSIANA,
    INSTALLATION,
    WALL,
    CEILING,
    CONFECTION,
    CONSUMPTION,
    ROLL_WIDTH,
    BASTILLAS,
    TOTAL_WIDTH,
    TOTAL_HEIGTH,
    FOLDS,
    UNIT_COST,
    TOTAL_COST,
    ADDITIONAL_DATA,
    AUTOMATION,
    CONTROL,
    CONTROL_SIDE,
    CONTROL_HEIGTH,
    CRANK,
    NO_PROJECTS,
    IVA,
    DISCOUNT,
    ADVANCE,
    CALCULATOR,
    PERSIANA,
    TOLDO_90,
    TOLDO_VERTICAL,
    TOLDO_PROYECCION,
    CORTINA,
    LEFT,
    RIGHT,
    RIGHTLEFT,
    STRUCTURE_COLOR,
    TEJIDO,
    SELLING_POLICIES,
    OF,
};
