import { StyleSheet, useWindowDimensions } from "react-native";
import { StyledView, StyledText } from "../UI/Styled";
import { BackButton } from "../Layout/Header";

const ViewBackButton = (props) => {
    const { navigation, textStatus, textPage } = props;
    const layout = useWindowDimensions();

    return (
        <StyledView style={layout.width > 768 ? styles.containerW100 : styles.containerW100PH} flexDirection="row">
            <BackButton style={styles.backButton} navigation={navigation} />
            {layout.width < 768 && (textStatus || textPage) && (
                <StyledView style={styles.textContainer}>
                    {textStatus && textPage ? (
                        <StyledText style={styles.centerVerticalText}>
                            {textStatus} | <StyledText color="gray">{textPage}</StyledText>
                        </StyledText>
                    ) : textStatus ? (
                        <StyledText style={styles.centerVerticalText}>{textStatus}</StyledText>
                    ) : (
                        <StyledText style={styles.centerVerticalText}>{textPage}</StyledText>
                    )}
                </StyledView>
            )}
        </StyledView>
    );
};

const styles = StyleSheet.create({
    containerW100: {
        width: "100%",
    },
    containerW100PH: {
        width: "100%",
        paddingHorizontal: "5%",
        paddingVertical: "5%",
        height: 52,
    },
    backButton: {
        alignSelf: "start",
    },
    textContainer: {
        marginHorizontal: "auto",
    },
    centerVerticalText: {
        marginVertical: "auto",
    },
});

export default ViewBackButton;
