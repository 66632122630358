import { isValidElement } from 'react';
import { StyleSheet, TouchableOpacity, View, Text, Pressable } from 'react-native';
import { Link } from '@react-navigation/native';

import { Colors, Fonts, Spacing } from '../../../styles';
import { capitalize } from 'lodash';

const getStylesFromProps = ({
    disabled,
    textFont,
    textSize,
    textColor
}) => {
    const styles = { container: {}, text: {} };

    if (!!disabled) {
        styles.text.opacity = Colors.buttonText.opacity;
    }

    if (typeof textFont !== 'undefined') {
        styles.text.fontFamily = Fonts.primary[textFont] ?? textFont;
    }

    if (typeof textSize !== 'undefined') {
        styles.text.fontSize = textSize;
    }

    if (typeof textColor !== 'undefined') {
        styles.text.color = Colors.app[textColor] ?? textColor;
    }

    return styles;
};

const StyledLink = (props) => {
    const { children } = props;

    const {
        text: textStyle,
    } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        text: {
            fontFamily: Fonts.primary.light,
            fontSize: Spacing.h3.fontSize,
            color: Fonts.primary.color,
            ...textStyle,
            ...props.textStyle
        }
    });

    return (
        <Pressable {...props}>
            <Link style={styles.text} to={props.to}>
                {children}
            </Link>
        </Pressable>
    );
};

export default StyledLink;
