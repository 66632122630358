import { Typography } from '../../styles';

import { StyledText } from './Styled';

const H3 = (props) => {
    return (
        <StyledText
            font={Typography.h3.fontFamily}
            size={Typography.h3.fontSize}
            color={Typography.h3.color}
            {...props}
        >
            {props.children}
        </StyledText>
    );
};

export default H3;
