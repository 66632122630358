import { StyleSheet, TouchableHighlight } from 'react-native';

import { Colors } from '../../styles';

const getStylesFromProps = ({ backgroundColor }) => {
    const styles = { touchable: {} };

    if (typeof backgroundColor !== 'undefined') {
        styles.touchable.backgroundColor =
            Colors.app[backgroundColor] ?? backgroundColor;
    }

    return styles;
};

const HighlightOnTouch = (props) => {
    const { underlayColor } = props;

    const { touchable: touchableStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        touchable: {
            ...touchableStyle,
            ...props.style
        }
    });

    return (
        <TouchableHighlight
            activeOpacity={1}
            {...props}
            underlayColor={
                underlayColor
                    ? Colors.app[underlayColor] ?? underlayColor
                    : Colors.touchableHighlight.underlayColor
            }
            style={styles.touchable}
        >
            {props.children}
        </TouchableHighlight>
    );
};

export default HighlightOnTouch;
