import { Fonts } from '../constants';

const primary = {
    regular: Fonts.PRIMARY.REGULAR,
    bold: Fonts.PRIMARY.BOLD,
    italic: Fonts.PRIMARY.ITALIC,
    black: Fonts.PRIMARY.BLACK,
    light: Fonts.PRIMARY.LIGHT,
    thin: Fonts.PRIMARY.THIN,
    boldItalic: Fonts.PRIMARY.BOLD_ITALIC,
    blackItalic: Fonts.PRIMARY.BLACK_ITALIC,
    lightItalic: Fonts.PRIMARY.LIGHT_ITALIC,
    thinItalic: Fonts.PRIMARY.THIN_ITALIC,
    title: Fonts.PRIMARY.TITLE,
    titleBold: Fonts.PRIMARY.TITLE_BOLD
};

export { primary };

export default { primary };
