import { StyleSheet, ActivityIndicator } from 'react-native';

import { Colors } from '../../constants';

const Loading = (props) => {
    const styles = StyleSheet.create({
        loading: { ...props.style }
    });

    return (
        <ActivityIndicator
            size={props.size ?? 'small'}
            color={props.color ?? Colors.PRIMARY}
            style={styles.loading}
        />
    );
};

export default Loading;
