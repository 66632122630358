import { StyleSheet, View } from "react-native";

import { Colors, Fonts, Spacing, Inputs } from "../../../styles";

import { Picker } from "@react-native-picker/picker";

import StyledIcon from "./StyledIcon";

const getStylesFromProps = ({ inputType, iconPosition, disabled, textFont, textSize, textColor }) => {
    const styles = { container: {}, text: {}, icon: {} };

    if (iconPosition === "left") {
        styles.container.flexDirection = "row-reverse";
        styles.icon.marginRight = Spacing.inputIcon.marginRight;
    } else {
        styles.container.flexDirection = "row";
        styles.icon.marginLeft = Spacing.inputIcon.marginLeft;
    }

    if (!!disabled) {
        styles.text.opacity = Colors.input.opacity;
        styles.icon.opacity = Colors.inputIcon.opacity;
    }

    if (typeof textFont !== "undefined") {
        styles.text.fontFamily = Fonts.primary[textFont] ?? textFont;
    }

    if (typeof textSize !== "undefined") {
        styles.text.fontSize = textSize;
    }

    if (typeof textColor !== "undefined") {
        styles.text.color = Colors.app[textColor] ?? textColor;
    }

    if (Colors.app[inputType]) {
        styles.container.borderColor = Colors.app[inputType];
    }

    return styles;
};

const StyledSelect = (props) => {
    const { inputType, iconName, iconType, backgroundColor, textStyle, textColorStyle, textSize, ...newProps } = props;

    const inputStyle = Inputs[props.size] ?? Inputs["regular"];

    const { container: containerStyle, text: textStyles, icon: iconStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        container: {
            ...inputStyle.container,
            ...containerStyle,
            ...StyleSheet.flatten(props.style),
            backgroundColor: backgroundColor
                ? backgroundColor
                : StyleSheet.flatten(props.style)?.backgroundColor
                ? StyleSheet.flatten(props.style).backgroundColor
                : inputStyle.container.backgroundColor,
            color: textColorStyle,
        },
        text: {
            ...inputStyle.text,
            ...textStyles,
            ...StyleSheet.flatten(props.textStyle),
            backgroundColor: backgroundColor
                ? backgroundColor
                : StyleSheet.flatten(props.style)?.backgroundColor
                ? StyleSheet.flatten(props.style).backgroundColor
                : inputStyle.container.backgroundColor,
            border: "none",
        },
        icon: {
            ...inputStyle.icon,
            ...iconStyle,
            ...props.iconStyle,
        },
    });

    const InputIcon = (
        <StyledIcon
            name={iconName}
            iconType={iconType}
            size={props.iconSize ?? Spacing.inputIcon.size}
            color={Colors.app[inputType] ?? props.iconColor ?? Colors.input.icon}
            style={styles.icon}
            onPress={props.onIcon}
        />
    );

    const options =
        props.data &&
        props.data.map((data) => {
            return <Picker.Item key={data.id} label={data.label} value={data.value} />;
        });

    return (
        <View style={styles.container}>
            <Picker enabled={!props.disabled} {...newProps} style={styles.text}>
                {options}
            </Picker>
            {iconName && InputIcon}
        </View>
    );
};

export default StyledSelect;
