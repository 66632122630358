import { useState, useEffect } from 'react';
import { Platform, StyleSheet } from 'react-native';
import { useForm, Controller } from 'react-hook-form';

import { Spacing } from '../../styles';

import Wrapper from './Wrapper';
import Screen from './Screen';
import Container from './Container';
import FluidView from './FluidView';
import LoadingModal from './LoadingModal';
import H3 from './H3';
import Field from './Field';
import SelectList from './SelectList';
import { StyledModal, StyledTextInput, StyledButton } from './Styled';

const isIos = Platform.OS === 'ios';

const FormView = (props) => {
    const {
        navigation,
        goTo,
        cancelButton,
        saveButton,
        onCancel,
        onSave,
        title
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const {
        control,
        handleSubmit,
        formState: { errors }
    } = useForm({
        mode: 'onBlur',
        ...props.form
    });

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeError = () => {
        setError(null);
    };

    const cancelHandler = async () => {
        if (typeof onCancel === 'function') {
            toggleIsLoading();

            const response = await onCancel();

            toggleIsLoading();

            if (!response.success) {
                setError(response.error);

                return;
            }
        }

        navigation.goBack();
    };

    const saveHandler = async (formData) => {
        if (typeof onSave === 'function') {
            toggleIsLoading();

            const response = await onSave(formData);

            toggleIsLoading();

            if (!response.success) {
                setError(response.error);

                return;
            }
        }

        if (!!goTo) {
            navigation.navigate(goTo.navigator, {
                ...goTo.data,
                params: { ...goTo.data?.params, ...formData }
            });
        } else {
            navigation.goBack();
        }
    };

    const getFields = (fields) => {
        return fields.map((field, index) => {
            const styles = StyleSheet.create({
                title: {
                    ...field.titleStyle
                },
                container: {
                    paddingHorizontal: Spacing.screen.paddingHorizontal,
                    ...field.style,
                    marginTop: index === 0 ? 0 : field.style?.marginTop ?? 40
                },
                label: {
                    ...field.labelStyle
                }
            });

            return (
                <Controller
                    key={field.name}
                    control={control}
                    name={field.name}
                    rules={field.rules}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            title={field.title}
                            label={field.errors[errors[field.name]?.type] ?? ''}
                            labelColor={errors[field.name] ? 'danger' : undefined}
                            style={styles.container}
                            titleStyle={styles.title}
                            labelStyle={styles.label}
                        >
                            {getField(field, value, onChange, onBlur)}
                        </Field>
                    )}
                />
            );
        });
    };

    const getField = (field, value, onChange, onBlur) => {
        switch (field.type) {
            case 'input':
                return getInput(field, value, onChange, onBlur);
            case 'selectList':
                return getSelectList(field, value, onChange, onBlur);
        }
    };

    const getInput = (field, value, onChange, onBlur) => {
        const styles = StyleSheet.create({
            container: {
                paddingHorizontal: 0,
                paddingTop: 15,
                paddingBottom: 10,
                borderWidth: 0,
                borderBottomWidth: 1,
                borderRadius: 0,
                ...field.props?.style
            },
            text: {
                ...field.props?.textStyle
            },
            iconStyle: {
                ...field.props?.iconStyle
            },
            showPasswordIcon: {
                ...field.props?.showPasswordIconStyle
            },
            hidePasswordIcon: {
                ...field.props?.hidePasswordIconStyle
            }
        });

        return (
            <StyledTextInput
                textSize={18}
                {...field.props}
                inputType={errors[field.name] ? 'danger' : ''}
                value={value}
                style={styles.container}
                textStyle={styles.text}
                iconStyle={styles.icon}
                showPasswordIconStyle={styles.showPasswordIcon}
                hidePasswordIconStyle={styles.hidePasswordIcon}
                onChangeText={onChange}
                onBlur={onBlur}
            />
        );
    };

    const getSelectList = (field, value, onChange, onBlur) => {
        const styles = StyleSheet.create({
            container: {
                ...field.props?.style
            },
            sectionContainer: {
                ...field.props?.sectionContainerStyle
            },
            sectionTitle: {
                ...field.props?.sectionTitleStyle
            },
            itemTouchable: {
                ...field.props?.itemTouchableStyle
            },
            itemContainer: {
                ...field.props?.itemContainerStyle
            },
            itemTitle: {
                ...field.props?.itemTitleStyle
            },
            itemTitleIcon: {
                ...field.props?.itemTitleIconStyle
            },
            itemCheckIcon: {
                ...field.props?.itemCheckIconStyle
            }
        });

        return (
            <SelectList
                {...field.props}
                selectedItems={value}
                style={styles.container}
                sectionContainerStyle={styles.sectionContainer}
                sectionTitleStyle={styles.sectionTitle}
                itemTouchableStyle={styles.itemTouchable}
                itemContainerStyle={styles.itemContainer}
                itemTitleStyle={styles.itemTitle}
                itemTitleIconStyle={styles.itemTitleIcon}
                itemCheckIconStyle={styles.itemCheckIcon}
                onSelectItem={(selectedItem) => onChange(selectedItem.id)}
            />
        );
    };

    const styles = StyleSheet.create({
        cancelButtonContainer: {
            marginLeft: 20,
            paddingHorizontal: 0,
            paddingVertical: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
            ...cancelButton?.props?.containerStyle
        },
        cancelButtonText: {
            ...cancelButton?.props?.textStyle
        },
        saveButtonContainer: {
            marginRight: 20,
            paddingHorizontal: 0,
            paddingVertical: 0,
            borderRadius: 0,
            backgroundColor: 'transparent',
            ...saveButton?.props?.containerStyle
        },
        saveButtonText: {
            ...saveButton?.props?.textStyle
        },
        screen: {},
        container: {
            minWidth: Spacing.screen.minWidth,
            maxWidth: Spacing.screen.maxWidth
        },
        title: {
            ...title?.props?.style
        },
        inputsContainer: {
            marginTop: 20
        }
    });

    const CancelButton = (
        <StyledButton
            textFont={'bold'}
            textSize={18}
            textColor={isIos ? 'white' : 'white'}
            onPress={cancelHandler}
            {...cancelButton?.props}
            style={styles.cancelButtonContainer}
            textStyle={styles.cancelButtonText}
        >
            {cancelButton?.content ?? 'CANCEL'}
        </StyledButton>
    );

    const SaveButton = (
        <StyledButton
            textFont={'bold'}
            textSize={18}
            textColor={isIos ? 'white' : 'white'}
            onPress={handleSubmit(saveHandler)}
            {...saveButton?.props}
            style={styles.saveButtonContainer}
            textStyle={styles.saveButtonText}
        >
            {saveButton?.content ?? 'SAVE'}
        </StyledButton>
    );

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => CancelButton,
            headerRight: () => SaveButton
        });
    }, []);

    return (
        <Wrapper navbar={false}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={'ERROR'}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={styles.screen}>
                <Container style={styles.container}>
                    {!!title && (
                        <H3 {...title?.props} style={styles.title}>
                            {title}
                        </H3>
                    )}
                    <FluidView style={styles.inputsContainer}>
                        {getFields(props.fields ?? [])}
                    </FluidView>
                </Container>
            </Screen>
        </Wrapper>
    );
};

export default FormView;
