/* Common */

const SEE_ALL = 'See all';

const SEE_MORE = 'See more';

const UNDER_CONSTRUCTION = 'Under construction';

const RESULTS_FOUND = 'Results found';

const OUR_SUGGESTIONS = 'Our suggestions';

const MORE_RESULTS = 'More results';

const LOAD_MORE_RESULTS = 'Load more results';

const USE_AS_DEFAULT = 'Use as default';

/* Authentication */

const LOGIN = 'Login';

const WELCOME_BACK = 'Welcome back!';

const SIGN_IN = 'Sign in';

const LOG_IN = 'Log in';

const SIGN_UP = 'Sign up';

const SIGN_UP_TITLE = 'Sign up';

const SIGN_OUT = 'Sign out';

const LOG_OUT = 'Log out';

const REMEMBER_ME = 'Remember me';

const FORGOT_PASSWORD = 'Forgot password?';

const FORGOT_MY_PASSWORD = 'I forgot my password';

const DONT_HAVE_AN_ACCOUNT = "New member?";

const CREATE_AN_ACCOUNT = 'Create an account to be able to enter';

const CREATE_ACCOUNT = 'Create account';

const ALREADY_REGISTERED = 'Already registered?';

const I_HAVE_READ_AND_AGREE_TO_THE = 'I have read and agree to the';

const TERMS_OF_SERVICE = 'Terms and Conditions';

const VERIFY_YOUR_ACCOUNT = 'Verify your account';

const WE_HAVE_SENT_AN_EMAIL_TO = 'We have sent an email to';

const YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN =
    'You need to verify your account to sign in.';

const IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL =
    'If you have not received the verification email, please check your "Spam" or "Bulk Email" folder. You can also click the resend button below to have another email sent to you.';

const RESET_YOUR_PASSWORD = 'Reset your password';

const RECOVERY_CODE = 'Recovery code';

const REMAINING_ATTEMPTS = 'Remaining attempts';

const RESET_PASSWORD = 'Reset password';

const AUTHENTICATION_NEEDED_TO_ACCESS_SECTION =
    'Authentication needed to access section.';

/* Profile */

const MY_PROFILE = 'Mi perfil';

const MY_ACCOUNT = 'Mi cuenta';

const PERSONAL_INFORMATION = 'Información personal';

const PAYMENT_METHODS = 'Métodos de pago';

const CHANGE_PROFILE_PICTURE = 'Cambiar foto de perfil';

const ARE_YOU_SURE_YOU_WANT_TO_LOGOUT =
    '¿Estás seguro que quieres cerrar sesión?';

const SAVE = 'save';

/* Projects*/

const ADD_PROJECT = "Add project";

const OWN_PROJECTS = "OWN PROJECTS";

const EDIT = "Edit";

const SEE = "See";

const DOWNLOAD_PDF = "Download PDF";

const DOWNLOAD_QUOTE_PDF = "Descargar Quote";

const ESTATUS = "Estatus";

const PRODUCT = "Product";

const QUANTITY = "Quantity";

const AREA = "Area";

const COST = "Cost";

const CREATE_PROJECT = "Create project";

const UPDATE_PROJECT = "Update project";

const PLEASE_ENTER_PROJECT_NAME = "Please enter the project's name"

const PLEASE_ENTER_CLIENT_NAME = "Please enter the client's name"

const PLEASE_ENTER_CLIENT_PHONE = "Please enter the client's phone"

const PLEASE_ENTER_CLIENT_EMAIL = "Please enter the client's email"

const PLEASE_ENTER_CLIENT_STREET = "Please enter the client's street"

const PLEASE_ENTER_CLIENT_NUMBER = "Please enter the client's number"

const PLEASE_ENTER_CLIENT_CITY = "Please enter the client's city"

const PLEASE_ENTER_CLIENT_SUBURB = "Please enter the client's suburb"

const PLEASE_ENTER_CLIENT_STATE = "Please enter the client's state"

const PLEASE_ENTER_CLIENT_ZIP_CODE = "Please enter the client's zip code"

const PLEASE_ENTER_CLIENT_DIRECTION = "Please enter the client's direction"

const ADD_PRODUCT = "Add product";

const UPDATE_PRODUCT = "Update product";

const PLEASE_ENTER_A_VALID_NUMBER = 'Please enter a valid number';

const ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT = '¿Estás seguro que quieres eliminar este producto?';

const PLEASE_ENTER_VALUE_BETWEEN = 'Please enter a valid number between ';

const MAX_LENGTH = 'Por favor, ingrese un texto mas corto. Max 500. ';

const MAX_LENGTH_240 = 'Por favor, ingrese un texto mas corto. Max 500. ';

/* Company */

const TERMS_AND_CONDITIONS = 'Términos y condiciones';

/* Account */

const FULL_NAME = 'Nombre completo';

const DATE_OF_BIRTH = 'Fecha de nacimiento';

/* Settings */

const TIME_ZONE = 'Zona horaria';

/* Validations */

const PLEASE_ENTER_THIS_FIELD = 'Por favor, ingrese este campo.';

const PLEASE_ENTER_YOUR_EMAIL = 'Por favor, ingrese su correo electrónico.';

const PLEASE_ENTER_YOUR_PASSWORD = 'Por favor, ingrese su contraseña.';

const PLEASE_ENTER_YOUR_RECOVERY_CODE =
    'Por favor, ingrese el código de recuperación de 6 dígitos.';

const PLEASE_ENTER_YOUR_VERIFICATION_CODE =
    'Por favor, ingrese el código de verificación de 6 dígitos.';

const PLEASE_ENTER_YOUR_PHONE_NUMBER =
    'Por favor, ingrese su número telefónico.';

const PLEASE_ENTER_YOUR_FULL_NAME = 'Por favor, ingrese su nombre completo.';

const PLEASE_ENTER_YOUR_FIRST_NAME = 'Por favor, ingrese su nombre.';

const PLEASE_ENTER_YOUR_LAST_NAME = 'Por favor, ingrese su apellido.';

const PLEASE_ENTER_YOUR_SUBJECT = 'Por favor, ingrese su asunto.';

const PLEASE_ENTER_YOUR_MESSAGE = 'Por favor, ingrese su mensaje.';

const PLEASE_SELECT_YOUR_LANGUAGE = 'Por favor, seleccione su lenguaje.';

const PLEASE_SELECT_YOUR_TIME_ZONE = 'Por favor, seleccione su zona horaria.';

const PLEASE_SELECT_YOUR_CURRENCY = 'Por favor, seleccione su moneda.';

const PLEASE_SELECT_YOUR_COUNTRY = 'Por favor, seleccione su país.';

const PLEASE_SELECT_YOUR_STATE = 'Por favor, seleccione su estado.';

const PLEASE_SELECT_YOUR_CITY = 'Por favor, seleccione su ciudad.';

const PLEASE_ENTER_A_VALID_EMAIL =
    'Por favor, ingrese un correo electrónico válido.';

const PLEASE_ENTER_A_VALID_PHONE_NUMBER =
    'Por favor, ingrese un número telefónico válido.';

const PLEASE_ENTER_A_VALID_CARD_NUMBER =
    'Por favor, ingrese un número de tarjeta válido.';

const PLEASE_ENTER_A_VALID_RFC = 'Por favor, ingrese un RFC válido. Ej. ABCD123456XX0';

const PLEASE_ENTER_A_MODERATE_PASSWORD =
    'Por favor, ingrese una contraseña de al menos 8 caracteres con 1 letra minúscula, 1 letra mayúscula y 1 número.';

const PLEASE_ENTER_A_COMPLEX_PASSWORD =
    'Por favor, ingrese una contraseña de al menos 8 caracteres con 1 letra minúscula, 1 letra mayúscula, 1 número y un caracter especial.';

const PASSWORDS_DO_NOT_MATCH = 'Las contraseñas no coinciden.';

const WRONG_CURRENT_PASSWORD = 'Contraseña actual incorrecta.';

/* Alerts */

const SAVED = 'Saved data.';

const SOMETHING_WENT_WRONG = 'Algo salió mal.';

const INVALID_USERNAME_OR_PASSWORD = 'Usuario o contraseña incorrectos.';

const INVALID_EMAIL_OR_PASSWORD = 'Correo o contraseña incorrectos.';

const EMAIL_IS_ALREADY_REGISTERED = 'El correo electrónico ya está registrado.';

const UNREGISTERED_EMAIL = 'El correo electrónico no está registrado.';

const VERIFICATION_EMAIL_HAS_BEEN_RESENT =
    'Se ha reenvíado el correo de verificación.';

const RECOVERY_CODE_HAS_BEEN_RESENT =
    'Se ha reenvíado el código de recuperación.';

const YOUR_RECOVERY_CODE_IS_WRONG = 'Tu código de recuperación es incorrecto.';

const YOUR_VERIFICATION_CODE_IS_WRONG =
    'Tu código de verificación es incorrecto.';

const QUALITY_RECOMMENDED =
    'Se recomienda seleccionar una calidad para poder buscar los inmuebles que mejor se adapten a tus necesidades.';

const DO_YOU_WANT_TO_CONTINUE_ANYWAY = '¿Desea continuar de todas formas?';

const NO_PROPERTIES = 'No properties.';

const NO_PROPERTIES_FOUND =
    "We can't seem to find any properties that match your search.";

const PLEASE_LOGIN_TO_CONTINUE = 'Please, login to continue.';

const OFFER_SENT_TO_PROPERTY_OWNER =
    'Your offer has been sent to the property owner.';

const PROPERTY_ADDED = 'Property successfully added.';

const PROPERTY_CODE_COPIED = 'Property code copied.';

const ACCEPT_PROPERTY_APPOINTMENT = 'Accept property appointment.';

const REJECT_PROPERTY_APPOINTMENT = 'Reject property appointment.';

const ACCEPT_PROPERTY_OFFER = 'Accept property offer.';

const REJECT_PROPERTY_OFFER = 'Reject property offer.';

const PROPERTY_APPOINTMENT_ACCEPTED = 'Property appointment accepted.';

const PROPERTY_APPOINTMENT_REJECTED = 'Property appointment rejected.';

const PROPERTY_OFFER_ACCEPTED = 'Property offer accepted.';

const PROPERTY_OFFER_REJECTED = 'Property offer rejected.';

const THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL =
    'The files will be sent to your email.';

const THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL =
    'The files have been sent to your email.';

const SERVICE_NOT_FOUND = 'Service not found.';

const SERVICE_PLAN_PROPERTY_AD_WARNING =
    'Recuerda que el bloque seleccionado debe ser lo más cercano a las características de tu propiedad.\n\nSi el tiempo contratado no es suficiente para la recoleccion de informacion puede representar atrasos en el servicio.';

const NO_CARD_FOUND = 'No card found.';

const CVC_DESCRIPTION =
    'The CVC code is a group of 3 or 4 numbers located on the back of the bank card.';

const CVC_REQUIRED =
    'This information is required to carry out the online transaction and is not stored at any time.';

const INVOICE_INFO_NOT_FOUND = 'Invoice info not found.';

const FILE_REQUIRED = 'File is required.';

const PRICES_UPLOAD = 'Upload prices list';

export {
    SEE_ALL,
    SEE_MORE,
    UNDER_CONSTRUCTION,
    RESULTS_FOUND,
    OUR_SUGGESTIONS,
    MORE_RESULTS,
    LOAD_MORE_RESULTS,
    USE_AS_DEFAULT,
    LOGIN,
    WELCOME_BACK,
    SIGN_IN,
    LOG_IN,
    SIGN_UP,
    SIGN_UP_TITLE,
    SIGN_OUT,
    LOG_OUT,
    REMEMBER_ME,
    FORGOT_PASSWORD,
    FORGOT_MY_PASSWORD,
    DONT_HAVE_AN_ACCOUNT,
    CREATE_AN_ACCOUNT,
    CREATE_ACCOUNT,
    ALREADY_REGISTERED,
    I_HAVE_READ_AND_AGREE_TO_THE,
    TERMS_OF_SERVICE,
    VERIFY_YOUR_ACCOUNT,
    WE_HAVE_SENT_AN_EMAIL_TO,
    YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN,
    IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL,
    RESET_YOUR_PASSWORD,
    RECOVERY_CODE,
    REMAINING_ATTEMPTS,
    RESET_PASSWORD,
    AUTHENTICATION_NEEDED_TO_ACCESS_SECTION,
    MY_PROFILE,
    MY_ACCOUNT,
    PERSONAL_INFORMATION,
    PAYMENT_METHODS,
    CHANGE_PROFILE_PICTURE,
    ARE_YOU_SURE_YOU_WANT_TO_LOGOUT,
    SAVE,
    ADD_PROJECT,
    OWN_PROJECTS,
    EDIT,
    SEE,
    DOWNLOAD_PDF,
    DOWNLOAD_QUOTE_PDF,
    ESTATUS,
    PRODUCT,
    QUANTITY,
    AREA,
    COST,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    PLEASE_ENTER_PROJECT_NAME,
    PLEASE_ENTER_CLIENT_NAME,
    PLEASE_ENTER_CLIENT_PHONE,
    PLEASE_ENTER_CLIENT_EMAIL,
    PLEASE_ENTER_CLIENT_STREET,
    PLEASE_ENTER_CLIENT_NUMBER,
    PLEASE_ENTER_CLIENT_CITY,
    PLEASE_ENTER_CLIENT_SUBURB,
    PLEASE_ENTER_CLIENT_STATE,
    PLEASE_ENTER_CLIENT_ZIP_CODE,
    PLEASE_ENTER_CLIENT_DIRECTION,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    PLEASE_ENTER_A_VALID_NUMBER,
    ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT,
    PLEASE_ENTER_VALUE_BETWEEN,
    MAX_LENGTH,
    MAX_LENGTH_240,
    TERMS_AND_CONDITIONS,
    FULL_NAME,
    DATE_OF_BIRTH,
    TIME_ZONE,
    PLEASE_ENTER_THIS_FIELD,
    PLEASE_ENTER_YOUR_EMAIL,
    PLEASE_ENTER_YOUR_PASSWORD,
    PLEASE_ENTER_YOUR_RECOVERY_CODE,
    PLEASE_ENTER_YOUR_VERIFICATION_CODE,
    PLEASE_ENTER_YOUR_PHONE_NUMBER,
    PLEASE_ENTER_YOUR_FULL_NAME,
    PLEASE_ENTER_YOUR_FIRST_NAME,
    PLEASE_ENTER_YOUR_LAST_NAME,
    PLEASE_ENTER_YOUR_SUBJECT,
    PLEASE_ENTER_YOUR_MESSAGE,
    PLEASE_SELECT_YOUR_LANGUAGE,
    PLEASE_SELECT_YOUR_TIME_ZONE,
    PLEASE_SELECT_YOUR_CURRENCY,
    PLEASE_SELECT_YOUR_COUNTRY,
    PLEASE_SELECT_YOUR_STATE,
    PLEASE_SELECT_YOUR_CITY,
    PLEASE_ENTER_A_VALID_EMAIL,
    PLEASE_ENTER_A_VALID_PHONE_NUMBER,
    PLEASE_ENTER_A_VALID_CARD_NUMBER,
    PLEASE_ENTER_A_VALID_RFC,
    PLEASE_ENTER_A_MODERATE_PASSWORD,
    PLEASE_ENTER_A_COMPLEX_PASSWORD,
    PASSWORDS_DO_NOT_MATCH,
    WRONG_CURRENT_PASSWORD,
    SAVED,
    SOMETHING_WENT_WRONG,
    INVALID_USERNAME_OR_PASSWORD,
    INVALID_EMAIL_OR_PASSWORD,
    EMAIL_IS_ALREADY_REGISTERED,
    UNREGISTERED_EMAIL,
    VERIFICATION_EMAIL_HAS_BEEN_RESENT,
    RECOVERY_CODE_HAS_BEEN_RESENT,
    YOUR_RECOVERY_CODE_IS_WRONG,
    YOUR_VERIFICATION_CODE_IS_WRONG,
    QUALITY_RECOMMENDED,
    DO_YOU_WANT_TO_CONTINUE_ANYWAY,
    NO_PROPERTIES,
    NO_PROPERTIES_FOUND,
    PLEASE_LOGIN_TO_CONTINUE,
    OFFER_SENT_TO_PROPERTY_OWNER,
    PROPERTY_ADDED,
    PROPERTY_CODE_COPIED,
    ACCEPT_PROPERTY_APPOINTMENT,
    REJECT_PROPERTY_APPOINTMENT,
    ACCEPT_PROPERTY_OFFER,
    REJECT_PROPERTY_OFFER,
    PROPERTY_APPOINTMENT_ACCEPTED,
    PROPERTY_APPOINTMENT_REJECTED,
    PROPERTY_OFFER_ACCEPTED,
    PROPERTY_OFFER_REJECTED,
    THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL,
    THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL,
    SERVICE_NOT_FOUND,
    SERVICE_PLAN_PROPERTY_AD_WARNING,
    NO_CARD_FOUND,
    CVC_DESCRIPTION,
    CVC_REQUIRED,
    INVOICE_INFO_NOT_FOUND,
    FILE_REQUIRED,
    PRICES_UPLOAD
};

export default {
    
    SEE_ALL,
    SEE_MORE,
    UNDER_CONSTRUCTION,
    RESULTS_FOUND,
    OUR_SUGGESTIONS,
    MORE_RESULTS,
    LOAD_MORE_RESULTS,
    USE_AS_DEFAULT,
    LOGIN,
    WELCOME_BACK,
    SIGN_IN,
    LOG_IN,
    SIGN_UP,
    SIGN_UP_TITLE,
    SIGN_OUT,
    LOG_OUT,
    REMEMBER_ME,
    FORGOT_PASSWORD,
    FORGOT_MY_PASSWORD,
    DONT_HAVE_AN_ACCOUNT,
    CREATE_AN_ACCOUNT,
    CREATE_ACCOUNT,
    ALREADY_REGISTERED,
    I_HAVE_READ_AND_AGREE_TO_THE,
    TERMS_OF_SERVICE,
    VERIFY_YOUR_ACCOUNT,
    WE_HAVE_SENT_AN_EMAIL_TO,
    YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN,
    IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL,
    RESET_YOUR_PASSWORD,
    RECOVERY_CODE,
    REMAINING_ATTEMPTS,
    RESET_PASSWORD,
    AUTHENTICATION_NEEDED_TO_ACCESS_SECTION,
    MY_PROFILE,
    MY_ACCOUNT,
    PERSONAL_INFORMATION,
    PAYMENT_METHODS,
    CHANGE_PROFILE_PICTURE,
    ARE_YOU_SURE_YOU_WANT_TO_LOGOUT,
    SAVE,
    ADD_PROJECT,
    OWN_PROJECTS,
    EDIT,
    SEE,
    DOWNLOAD_PDF,
    DOWNLOAD_QUOTE_PDF,
    ESTATUS,
    PRODUCT,
    QUANTITY,
    AREA,
    COST,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    PLEASE_ENTER_PROJECT_NAME,
    PLEASE_ENTER_CLIENT_NAME,
    PLEASE_ENTER_CLIENT_PHONE,
    PLEASE_ENTER_CLIENT_EMAIL,
    PLEASE_ENTER_CLIENT_STREET,
    PLEASE_ENTER_CLIENT_NUMBER,
    PLEASE_ENTER_CLIENT_CITY,
    PLEASE_ENTER_CLIENT_SUBURB,
    PLEASE_ENTER_CLIENT_STATE,
    PLEASE_ENTER_CLIENT_ZIP_CODE,
    PLEASE_ENTER_CLIENT_DIRECTION,
    ADD_PRODUCT,
    UPDATE_PRODUCT,
    PLEASE_ENTER_A_VALID_NUMBER,
    ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT,
    PLEASE_ENTER_VALUE_BETWEEN,
    MAX_LENGTH,
    MAX_LENGTH_240,
    TERMS_AND_CONDITIONS,
    FULL_NAME,
    DATE_OF_BIRTH,
    TIME_ZONE,
    PLEASE_ENTER_THIS_FIELD,
    PLEASE_ENTER_YOUR_EMAIL,
    PLEASE_ENTER_YOUR_PASSWORD,
    PLEASE_ENTER_YOUR_RECOVERY_CODE,
    PLEASE_ENTER_YOUR_VERIFICATION_CODE,
    PLEASE_ENTER_YOUR_PHONE_NUMBER,
    PLEASE_ENTER_YOUR_FULL_NAME,
    PLEASE_ENTER_YOUR_FIRST_NAME,
    PLEASE_ENTER_YOUR_LAST_NAME,
    PLEASE_ENTER_YOUR_SUBJECT,
    PLEASE_ENTER_YOUR_MESSAGE,
    PLEASE_SELECT_YOUR_LANGUAGE,
    PLEASE_SELECT_YOUR_TIME_ZONE,
    PLEASE_SELECT_YOUR_CURRENCY,
    PLEASE_SELECT_YOUR_COUNTRY,
    PLEASE_SELECT_YOUR_STATE,
    PLEASE_SELECT_YOUR_CITY,
    PLEASE_ENTER_A_VALID_EMAIL,
    PLEASE_ENTER_A_VALID_PHONE_NUMBER,
    PLEASE_ENTER_A_VALID_CARD_NUMBER,
    PLEASE_ENTER_A_VALID_RFC,
    PLEASE_ENTER_A_MODERATE_PASSWORD,
    PLEASE_ENTER_A_COMPLEX_PASSWORD,
    PASSWORDS_DO_NOT_MATCH,
    WRONG_CURRENT_PASSWORD,
    SAVED,
    SOMETHING_WENT_WRONG,
    INVALID_USERNAME_OR_PASSWORD,
    INVALID_EMAIL_OR_PASSWORD,
    EMAIL_IS_ALREADY_REGISTERED,
    UNREGISTERED_EMAIL,
    VERIFICATION_EMAIL_HAS_BEEN_RESENT,
    RECOVERY_CODE_HAS_BEEN_RESENT,
    YOUR_RECOVERY_CODE_IS_WRONG,
    YOUR_VERIFICATION_CODE_IS_WRONG,
    QUALITY_RECOMMENDED,
    DO_YOU_WANT_TO_CONTINUE_ANYWAY,
    NO_PROPERTIES,
    NO_PROPERTIES_FOUND,
    PLEASE_LOGIN_TO_CONTINUE,
    OFFER_SENT_TO_PROPERTY_OWNER,
    PROPERTY_ADDED,
    PROPERTY_CODE_COPIED,
    ACCEPT_PROPERTY_APPOINTMENT,
    REJECT_PROPERTY_APPOINTMENT,
    ACCEPT_PROPERTY_OFFER,
    REJECT_PROPERTY_OFFER,
    PROPERTY_APPOINTMENT_ACCEPTED,
    PROPERTY_APPOINTMENT_REJECTED,
    PROPERTY_OFFER_ACCEPTED,
    PROPERTY_OFFER_REJECTED,
    THE_FILES_WILL_BE_SENT_TO_YOUR_EMAIL,
    THE_FILES_HAVE_BEEN_SENT_TO_YOUR_EMAIL,
    SERVICE_NOT_FOUND,
    SERVICE_PLAN_PROPERTY_AD_WARNING,
    NO_CARD_FOUND,
    CVC_DESCRIPTION,
    CVC_REQUIRED,
    INVOICE_INFO_NOT_FOUND,
    FILE_REQUIRED,
    PRICES_UPLOAD
};
