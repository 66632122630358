import AsyncStorage from '@react-native-async-storage/async-storage';

import { appActions } from '../slices/app';
import { authActions } from '../slices/auth';
import { userActions } from '../slices/user';

import { auth, user } from '../../models';

const login = (email, password) => {
    return async (dispatch) => {
        const { data: loginResponseData } = await auth.login({ email, password });

        if (loginResponseData.is_verified){
            const authData = {
                token: loginResponseData.access,
                expirationTime: loginResponseData.tokenlifetime,
                refresh: loginResponseData.refresh
            };
    
            dispatch(authActions.authenticate({ ...authData }));
    
            await AsyncStorage.setItem('authData', JSON.stringify(authData));
    
            await dispatch(autoLogin(authData.token));
        }else{
            throw {isnt_verified:!loginResponseData.is_verified}
        }
    };
};

const refreshToken = (tokenlifetime,refresh) => {
    return async (dispatch) => {
        const { data: loginResponseData } = await auth.refreshToken({ refresh });

        const authData = {
            token: loginResponseData.access,
            expirationTime: tokenlifetime,
            refresh: refresh
        };
        
        dispatch(authActions.authenticate({ ...authData }));

        await AsyncStorage.setItem('authData', JSON.stringify(authData));

        await dispatch(autoLogin(authData.token));
        return authData.token;
    };
};

const autoLogin = (token) => {
    return async (dispatch) => {
        const { data: meResponseData } = await user
            .withOption('headers', { Authorization: `Bearer ${token}` })
            .me();

        const userData = {
            id: meResponseData.id,
            firstName: meResponseData.first_name,
            lastName: meResponseData.last_name,
            fullName: meResponseData.full_name,
            dateOfBirth: meResponseData.birthday,
            email: meResponseData.email,
            phoneNumber: meResponseData.phone_number,
            profilePicture: meResponseData.image,
            address: meResponseData.address,
            rfc: meResponseData.rfc
        };

        let appData = await AsyncStorage.getItem('appData');

        appData = appData !== null ? JSON.parse(appData) : {};

        await AsyncStorage.setItem('appData', JSON.stringify(appData));

        dispatch(appActions.setData({ ...appData }));
        dispatch(userActions.setData({ ...userData }));
    };
};

const logout = () => {
    return async (dispatch) => {
        await AsyncStorage.removeItem('authData');

        dispatch(authActions.logout());
        dispatch(userActions.resetData());
    };
};

export { login, autoLogin, logout, refreshToken };

export default { login, autoLogin, logout, refreshToken };
