import { Fonts } from '../constants';

import Colors from './colors';
import Spacing from './spacing';

const headerTitle = {
    fontFamily: Fonts.PRIMARY.BOLD
};

const headerBackTitle = {
    fontFamily: Fonts.PRIMARY.REGULAR
};

const bottomTabsLabel = {
    fontFamily: Fonts.PRIMARY.REGULAR
};

const h1 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h1.fontSize,
    color: Colors.h1.font
};

const h2 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h2.fontSize,
    color: Colors.h2.font
};

const h3 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h3.fontSize,
    color: Colors.h3.font
};

const h4 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h4.fontSize,
    color: Colors.h4.font
};

const h5 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h5.fontSize,
    color: Colors.h5.font
};

const h6 = {
    fontFamily: Fonts.PRIMARY.TITLE_BOLD,
    fontSize: Spacing.h6.fontSize,
    color: Colors.h6.font
};

const paragraph = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.paragraph.fontSize,
    color: Colors.paragraph.font
};

const label = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.label.fontSize,
    color: Colors.label.font
};

const text = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.text.fontSize,
    color: Colors.text.font
};

const input = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.input.fontSize,
    color: Colors.input.font
};

const smallInput = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.smallInput.fontSize,
    color: Colors.input.font
};

const mediumInput = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.mediumInput.fontSize,
    color: Colors.input.font
};

const largeInput = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.largeInput.fontSize,
    color: Colors.input.font
};

const checkboxLabel = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.checkboxLabel.fontSize,
    color: Colors.checkboxLabel.font
};

const toggleLabel = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.toggleLabel.fontSize,
    color: Colors.toggleLabel.font
};

const buttonText = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.buttonText.fontSize,
    color: Colors.buttonText.font
};

const smallButtonText = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.smallButtonText.fontSize,
    color: Colors.buttonText.font
};

const mediumButtonText = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.mediumButtonText.fontSize,
    color: Colors.buttonText.font
};

const largeButtonText = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.largeButtonText.fontSize,
    color: Colors.buttonText.font
};

const fieldTitle = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.fieldTitle.fontSize,
    color: Colors.fieldTitle.font
};

const fieldLabel = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.fieldLabel.fontSize,
    color: Colors.fieldLabel.font
};

const listItemText = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.listItemText.fontSize,
    color: Colors.listItemText.font
};

const selectListSectionTitle = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.selectListSectionTitle.fontSize,
    color: Colors.selectListSectionTitle.font
};

const selectListItemTitle = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.selectListItemTitle.fontSize,
    color: Colors.selectListItemTitle.font
};

const settingsListSectionTitle = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.settingsListSectionTitle.fontSize,
    color: Colors.settingsListSectionTitle.font
};

const settingsListItemTitle = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.settingsListItemTitle.fontSize,
    color: Colors.settingsListItemTitle.font
};

const settingsListItemInfo = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.settingsListItemInfo.fontSize,
    color: Colors.settingsListItemInfo.font
};

const accordionTitle = {
    fontFamily: Fonts.PRIMARY.BOLD,
    fontSize: Spacing.accordionTitle.fontSize,
    color: Colors.accordionTitle.font
};

const accordionDescription = {
    fontFamily: Fonts.PRIMARY.REGULAR,
    fontSize: Spacing.accordionDescription.fontSize,
    color: Colors.accordionDescription.font
};

export {
    headerTitle,
    headerBackTitle,
    bottomTabsLabel,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    input,
    smallInput,
    mediumInput,
    largeInput,
    checkboxLabel,
    toggleLabel,
    buttonText,
    smallButtonText,
    mediumButtonText,
    largeButtonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectListSectionTitle,
    selectListItemTitle,
    settingsListSectionTitle,
    settingsListItemTitle,
    settingsListItemInfo,
    accordionTitle,
    accordionDescription
};

export default {
    headerTitle,
    headerBackTitle,
    bottomTabsLabel,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    input,
    smallInput,
    mediumInput,
    largeInput,
    checkboxLabel,
    toggleLabel,
    buttonText,
    smallButtonText,
    mediumButtonText,
    largeButtonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectListSectionTitle,
    selectListItemTitle,
    settingsListSectionTitle,
    settingsListItemTitle,
    settingsListItemInfo,
    accordionTitle,
    accordionDescription
};
