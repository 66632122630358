import { StyleSheet } from 'react-native';

import { Typography } from '../../styles';

import { StyledText } from './Styled';

const Paragraph = (props) => {
    return (
        <StyledText
            font={Typography.paragraph.fontFamily}
            size={Typography.paragraph.fontSize}
            color={Typography.paragraph.color}
            align="justify"
            {...props}
            style={{ ...styles.paragraph, ...props.style }}
        >
            {props.children}
        </StyledText>
    );
};

const styles = StyleSheet.create({
    paragraph: {
        width: '100%'
    }
});

export default Paragraph;
