import { StyleSheet } from "react-native";

import { Cards } from "../../styles";

import StyledView from "./Styled/StyledView";

const Card = (props) => {
    const cardStyle = Cards[props.size] ?? Cards["regular"];

    const styles = StyleSheet.create({
        card: {
            paddingHorizontal: cardStyle.paddingHorizontal,
            paddingVertical: cardStyle.paddingVertical,
            borderRadius: cardStyle.borderRadius,
            backgroundColor: cardStyle.backgroundColor,
            shadowRadius: cardStyle.shadowRadius,
            shadowOffset: cardStyle.shadowOffset,
            shadowColor: cardStyle.shadowColor,
            shadowOpacity: cardStyle.shadowOpacity,
            elevation: cardStyle.elevation,
            maxWidth: cardStyle.maxWidth,
            ...StyleSheet.flatten(props.style),
        },
    });

    return (
        <StyledView
            justifyContent={cardStyle.justifyContent}
            alignItems={cardStyle.alignItems}
            width={cardStyle.width}
            {...props}
            style={styles.card}
        >
            {props.children}
        </StyledView>
    );
};

export default Card;
