import { Platform, Image } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { Colors, Spacing, Typography } from '../../styles';

import { BackButton } from '../../components/Layout/Header';

import SignInScreen from '../../screens/Auth/SignInScreen';
import VerificationEmailScreen from '../../screens/Auth/VerificationEmailScreen';
import ForgotPasswordScreen from '../../screens/Auth/ForgotPasswordScreen';
import RecoveryCodeScreen from '../../screens/Auth/RecoveryCodeScreen';
import ResetPasswordScreen from '../../screens/Auth/ResetPasswordScreen';

const Stack = createStackNavigator();

const defaultScreenOptions = (navData) => {
    return {
        headerStyle: {
            backgroundColor: Colors.header.background
        },
        headerTitle: () => (
            <Image
                style={{
                    width: Spacing.headerLogo.width,
                    height: Spacing.headerLogo.height,
                    marginBottom: Spacing.headerLogo.marginBottom
                }}
                source={
                    Platform.OS === 'ios'
                        ? require('../../assets/images/logo-white.png')
                        : require('../../assets/images/logo-white.png')
                }
            />
        ),
        headerTitleStyle: { ...Typography.headerTitle },
        headerTitleAlign: 'center',
        headerBackTitleStyle: { ...Typography.headerBackTitle },
        headerTintColor: Colors.header.tint,
        headerLeft: () => <BackButton {...navData} />
    };
};

const AuthNavigator = () => {
    return (
        <Stack.Navigator screenOptions={{headerShown: false }}>
            <Stack.Screen name="SignIn" component={SignInScreen} />
            <Stack.Screen name="VerificationEmail" component={VerificationEmailScreen} />
            <Stack.Screen name="ForgotPassword" component={ForgotPasswordScreen} />
            <Stack.Screen name="RecoveryCode" component={RecoveryCodeScreen} />
            <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} />
        </Stack.Navigator>
    );
};

export default AuthNavigator;
