import { useState } from 'react';
import { Platform, TouchableWithoutFeedback } from 'react-native';

import { useLanguage } from '../../../hooks';

import {
  StyledView,
  StyledModal,
  StyledText,
  StyledIcon
} from '../../UI/Styled';

const ProfileLogout = (props) => {
  const { onLogout } = props;

  const [confirmLogout, setConfirmLogout] = useState(false);

  const { translate } = useLanguage();

  const toggleConfirmLogout = () => {
    setConfirmLogout((prevConfirmLogout) => !prevConfirmLogout);
  };

  const logoutHandler = () => {
    toggleConfirmLogout();

    if (typeof onLogout === 'function') {
      onLogout();
    }
  };

  return (
    <StyledView style={props.style}>
      <StyledModal
        modalType="dialog"
        visible={!!confirmLogout}
        size="medium"
        title={translate('sentence', 'LOG_OUT')}
        titleColor="info"
        onConfirm={logoutHandler}
        onCancel={toggleConfirmLogout}
        onPressOut={toggleConfirmLogout}
        onRequestClose={toggleConfirmLogout}
      >
        {translate('sentence', 'ARE_YOU_SURE_YOU_WANT_TO_LOGOUT')}
      </StyledModal>
      <TouchableWithoutFeedback
        accessible={false}
        onPress={toggleConfirmLogout}
      >
        <StyledView alignItems="center">
          <StyledIcon
            name={
              Platform.OS === 'ios'
                ? 'ios-log-out-outline'
                : 'md-log-out-outline'
            }
            size={30}
            color="gray"
          />
          <StyledText font="bold" size={16} color="gray">
            {translate('sentence', 'LOG_OUT')}
          </StyledText>
        </StyledView>
      </TouchableWithoutFeedback>
    </StyledView>
  );
};

export default ProfileLogout;
