import { Typography } from '../../styles';

import { StyledText } from './Styled';

const H5 = (props) => {
    return (
        <StyledText
            font={Typography.h5.fontFamily}
            size={Typography.h5.fontSize}
            color={Typography.h5.color}
            {...props}
        >
            {props.children}
        </StyledText>
    );
};

export default H5;
