import { Button } from 'react-native';

import { Icons } from '../../../styles';

import CustomButton from './CustomButton';

const CloseButton = ({ navigation }) => {

    return (
        <Button HeaderButtonComponent={CustomButton}
            title={'CLOSE'}
            iconName={Icons.header.close}
            onPress={() => {
                navigation.goBack();
            }}>
        </Button>
    );
};

export default CloseButton;
