import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';

const loadAssets = async () => {
    // await new Promise(resolve => setTimeout(resolve, 5000));
    await loadFonts();
};

const loadFonts = () => {
    return Font.loadAsync({
        'montserrat': require('../assets/fonts/MontserratAlternates-Regular.ttf'),
        'montserrat-bold': require('../assets/fonts/MontserratAlternates-Bold.ttf'),
        'roboto-black': require('../assets/fonts/Roboto-Black.ttf'),
        'roboto-black-italic': require('../assets/fonts/Roboto-BlackItalic.ttf'),
        'roboto-bold': require('../assets/fonts/Roboto-Bold.ttf'),
        'roboto-bold-italic': require('../assets/fonts/Roboto-BoldItalic.ttf'),
        'roboto-italic': require('../assets/fonts/Roboto-Italic.ttf'),
        'roboto-light': require('../assets/fonts/Roboto-Light.ttf'),
        'roboto-light-italic': require('../assets/fonts/Roboto-LightItalic.ttf'),
        'roboto-regular': require('../assets/fonts/Roboto-Regular.ttf'),
        'roboto-thin': require('../assets/fonts/Roboto-Thin.ttf'),
        'roboto-thin-italic': require('../assets/fonts/Roboto-ThinItalic.ttf')
    });
};

const LoadingScreen = (props) => {
    return (
        <AppLoading
            startAsync={loadAssets}
            onFinish={props.onFinish}
            onError={props.onError}
        />
    );
};

export default LoadingScreen;
