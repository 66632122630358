import { StyleSheet } from "react-native";

import { Colors } from "../../constants";
import { StyledButton, StyledView, StyledIcon } from "../UI/Styled";

const Pagination = (props) => {
    return (
        <StyledView style={styles.container}>
            {props.pageStatus?.previousLink && (
                <StyledButton
                    buttonType="secondary"
                    textSize={10}
                    styleContainer={styles.buttonInilineContainer}
                    style={styles.buttonLft}
                    onPress={props.previousPage}
                >
                    <StyledIcon iconType="FontAwesome5" name="chevron-left" size={10} color={Colors.BLACK} />
                </StyledButton>
            )}
            <StyledButton
                buttonType="secondary"
                textSize={10}
                disabled={true}
                styleContainer={styles.buttonInilineContainer}
                style={styles.buttonCen}
            >
                {props.pageStatus.page}
            </StyledButton>
            {props.pageStatus?.nextLink && (
                <StyledButton
                    buttonType="secondary"
                    textSize={10}
                    styleContainer={styles.buttonInilineContainer}
                    style={styles.buttonRgh}
                    onPress={props.nextPage}
                >
                    <StyledIcon iconType="FontAwesome5" name="chevron-right" size={10} color={Colors.BLACK} />
                </StyledButton>
            )}
        </StyledView>
    );
};

const styles = StyleSheet.create({
    container: {
        marginVertical: 5,
        display: "inline",
    },
    buttonInilineContainer: {
        display: "inline-block",
    },
    buttonLft: {
        display: "inline-block",
        borderBottomLeftRadius: 7,
        borderTopLeftRadius: 7,
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
        paddingHorizontal: 10,
        borderRightWidth: 1,
        paddingVertical: 5,
    },
    buttonCen: {
        display: "inline-block",
        borderRadius: 0,
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    buttonRgh: {
        display: "inline-block",
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 7,
        borderTopRightRadius: 7,
        paddingHorizontal: 10,
        borderLeftWidth: 1,
        paddingVertical: 5,
    },
});

export default Pagination;
