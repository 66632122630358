import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { capitalize, forIn } from 'lodash';

import languages from '../languages';

const useLanguage = () => {
    const appLanguage = useSelector((state) => state.app.language);

    const language = languages[appLanguage];

    const translate = useCallback(
        (constantType, constant, template = {}) => {
            let translation =
                language[`${capitalize(constantType)}s`][constant] ?? '';

            forIn(template, (value, key) => {
                translation = translation.replace(new RegExp(`{{${key}}}`, 'g'), value);
            });

            return translation;
        },
        [language]
    );

    return { language, translate };
};

export default useLanguage;
