import { Colors } from '../../constants';

import Screen from './Screen';
import Loading from './Loading';

const LoadingView = (props) => {
    return (
        <Screen {...props}>
            <Loading
                size={props.loadingIconSize ?? 'large'}
                color={props.loadingIconColor ?? Colors.PRIMARY}
                style={props.loadingIconStyle}
            />
        </Screen>
    );
};

export default LoadingView;
