import Colors from './colors';
import Spacing from './spacing';

const regular = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: 'auto',
    paddingHorizontal: Spacing.card.paddingHorizontal,
    paddingVertical: Spacing.card.paddingVertical,
    borderRadius: Spacing.card.borderRadius,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.card.shadowRadius,
    shadowOffset: Spacing.card.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5
};

const small = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.smallCard.width,
    paddingHorizontal: Spacing.smallCard.paddingHorizontal,
    paddingVertical: Spacing.smallCard.paddingVertical,
    borderRadius: Spacing.smallCard.borderRadius,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.smallCard.shadowRadius,
    shadowOffset: Spacing.smallCard.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5
};

const medium = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.mediumCard.width,
    paddingHorizontal: Spacing.mediumCard.paddingHorizontal,
    paddingVertical: Spacing.mediumCard.paddingVertical,
    borderRadius: Spacing.mediumCard.borderRadius,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.mediumCard.shadowRadius,
    shadowOffset: Spacing.mediumCard.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5
};

const large = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.largeCard.width,
    paddingHorizontal: Spacing.largeCard.paddingHorizontal,
    paddingVertical: Spacing.largeCard.paddingVertical,
    borderRadius: Spacing.largeCard.borderRadius,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.largeCard.shadowRadius,
    shadowOffset: Spacing.largeCard.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5
};

const web = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.webCard.width,
    paddingHorizontal: Spacing.webCard.paddingHorizontal,
    paddingVertical: Spacing.webCard.paddingVertical,
    borderRadius: Spacing.webCard.borderRadius,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.webCard.shadowRadius,
    shadowOffset: Spacing.webCard.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5,
    maxWidth: 550
};

const modal = {
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 10,
    backgroundColor: Colors.card.background,
    shadowRadius: Spacing.card.shadowRadius,
    shadowOffset: Spacing.card.shadowOffset,
    shadowColor: Colors.card.shadow,
    shadowOpacity: Colors.card.shadowOpacity,
    elevation: 5
};

export { regular, small, medium, large, web, modal };

export default { regular, small, medium, large, web, modal };
