import { isValidElement, useState, useEffect } from 'react';
import {
    Platform,
    UIManager,
    LayoutAnimation,
    StyleSheet,
    TouchableWithoutFeedback
} from 'react-native';

import { Colors, Spacing, Typography, Icons } from '../../styles';

import FluidView from './FluidView';
import Paragraph from './Paragraph';
import { StyledText, StyledIcon } from './Styled';

if (
    Platform.OS === 'android' &&
    UIManager.setLayoutAnimationEnabledExperimental
) {
    UIManager.setLayoutAnimationEnabledExperimental(true);
}

const Accordion = (props) => {
    const { isVisible: initialIsVisible = false, title, children } = props;

    const [isVisible, setIsVisible] = useState(false);

    const styles = StyleSheet.create({
        container: {
            ...props.style
        },
        header: {
            paddingHorizontal: Spacing.accordionHeader.paddingHorizontal,
            paddingVertical: Spacing.accordionHeader.paddingVertical,
            borderWidth: Spacing.accordionHeader.borderWidth,
            borderColor: Colors.accordionHeader.borderColor,
            backgroundColor: Colors.accordionHeader.backgroundColor,
            ...props.headerStyle
        },
        title: {
            ...props.titleStyle
        },
        openIcon: {
            ...props.openIconStyle
        },
        closeIcon: {
            ...props.closeIconStyle
        },
        body: {
            paddingHorizontal: Spacing.accordionBody.paddingHorizontal,
            paddingVertical: Spacing.accordionBody.paddingVertical,
            borderRightWidth: Spacing.accordionBody.borderRightWidth,
            borderBottomWidth: Spacing.accordionBody.borderBottomWidth,
            borderLeftWidth: Spacing.accordionBody.borderLeftWidth,
            borderColor: Colors.accordionBody.borderColor,
            backgroundColor: Colors.accordionBody.backgroundColor,
            ...props.bodyStyle
        },
        description: {
            ...props.descriptionStyle
        }
    });

    const toggleIsVisible = () => {
        LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
        setIsVisible((prevIsVisible) => !prevIsVisible);
    };

    useEffect(() => {
        setIsVisible(initialIsVisible);
    }, []);

    const content = isValidElement(children) ? (
        children
    ) : (
        <Paragraph
            font={props.descriptionFont ?? Typography.accordionDescription.fontFamily}
            size={props.descriptionSize ?? Typography.accordionDescription.fontSize}
            color={props.descriptionColor ?? Typography.accordionDescription.color}
            style={styles.description}
        >
            {children}
        </Paragraph>
    );

    return (
        <FluidView {...props} style={styles.container}>
            <TouchableWithoutFeedback onPress={toggleIsVisible}>
                <FluidView
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    style={styles.header}
                >
                    {isValidElement(title) ? (
                        title
                    ) : (
                        <StyledText
                            font={props.titleFont ?? Typography.accordionTitle.fontFamily}
                            size={props.titleSize ?? Typography.accordionTitle.fontSize}
                            color={props.titleColor ?? Typography.accordionTitle.color}
                            style={styles.title}
                        >
                            {title ?? ''}
                        </StyledText>
                    )}
                    {isVisible ? (
                        <StyledIcon
                            name={Icons.accordion.close}
                            size={props.closeIconSize ?? Spacing.accordionCloseIcon.size}
                            color={props.closeIconColor ?? Colors.accordionCloseIcon.icon}
                            style={styles.closeIcon}
                        />
                    ) : (
                        <StyledIcon
                            name={Icons.accordion.open}
                            size={props.openIconSize ?? Spacing.accordionOpenIcon.size}
                            color={props.openIconColor ?? Colors.accordionOpenIcon.icon}
                            style={styles.openIcon}
                        />
                    )}
                </FluidView>
            </TouchableWithoutFeedback>
            {!!isVisible && <FluidView style={styles.body}>{content}</FluidView>}
        </FluidView>
    );
};

export default Accordion;
