/* Common */

const ACCEPT = 'Aceptar';

const CONFIRM = 'Confirmar';

const CANCEL = 'Cancelar';

const REJECT = 'Rechazar';

const CLOSE = 'Cerrar';

const EXPLORE = 'Explorar';

const RENT = 'Rentar';

const BUY = 'Comprar';

const PAY = 'Pagar';

const CONTRACT = 'Contratar';

/* CRUD */

const CREATE = 'Crear';

const UPDATE = 'Actualizar';

const DELETE = 'Eliminar';

const SAVE = 'Guardar';

const STORE = 'Almacenar';

const ADD = 'Agregar';

const EDIT = 'Editar';

const CHANGE = 'Cambiar';

const REMOVE = 'Remover';

const RESET = 'Restablecer';

const REGISTER = 'Registrar';

const SEND = 'Enviar';

const RESEND = 'Reenviar';

const SEARCH = 'Buscar';

const SELECT = 'Seleccionar';

const PICK = 'Seleccionar';

/* PROJECTS */

const ACTIVES = 'Activos';

const HISTORIC = 'Históricos';

const ACTIVE = 'Activo';

const FINISHED = 'Terminado';

export {
    ACCEPT,
    CONFIRM,
    CANCEL,
    REJECT,
    CLOSE,
    EXPLORE,
    RENT,
    BUY,
    PAY,
    CONTRACT,
    CREATE,
    UPDATE,
    DELETE,
    SAVE,
    STORE,
    ADD,
    EDIT,
    CHANGE,
    REMOVE,
    RESET,
    REGISTER,
    SEND,
    RESEND,
    SEARCH,
    SELECT,
    PICK,
    ACTIVES,
    HISTORIC,
    ACTIVE,
    FINISHED
};

export default {
    ACCEPT,
    CONFIRM,
    CANCEL,
    REJECT,
    CLOSE,
    EXPLORE,
    RENT,
    BUY,
    PAY,
    CONTRACT,
    CREATE,
    UPDATE,
    DELETE,
    SAVE,
    STORE,
    ADD,
    EDIT,
    CHANGE,
    REMOVE,
    RESET,
    REGISTER,
    SEND,
    RESEND,
    SEARCH,
    SELECT,
    PICK,
    ACTIVES,
    HISTORIC,
    ACTIVE,
    FINISHED
};
