import { Text, View, StyleSheet } from "react-native";

// import {
//     Row,
//     Col,
//     Container,
// } from 'react-bootstrap';
import { Spacing } from '../styles';

const NotFound = ({ navigation, route }) => {
    return (
        <View style={styles.app}>
            <Text >Pagina no encontrada</Text>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth,
        marginTop: 30,
        paddingHorizontal: Spacing.screen.paddingHorizontal
    },
    paragraph: {
        marginTop: 30
    }
});


export default NotFound;
