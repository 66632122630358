import { useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Colors } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import User from "../../models/User";
import { userActions } from "../../store/slices/user";
import { BackButton } from "../../components/Layout/Header";
import { StyledView } from "../../components/UI/Styled";

import { Wrapper, Screen, Container, H4, SettingsList, LoadingModal } from "../../components/UI";
import { StyledModal } from "../../components/UI/Styled";
import ProfileForm from "../../components/Profile/ProfileForm";

const AccountScreen = ({ navigation }) => {
    const accessToken = useSelector((state) => state.auth.token);
    const userState = useSelector((state) => state.user);

    const layout = useWindowDimensions();

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [error, setError] = useState(null);

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const dispatch = useDispatch();

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const { translate } = useLanguage();

    const accountSettingsData = {
        email: {
            title: translate("word", "EMAIL"),
            data: [
                {
                    id: "email",
                    title: userState.email ?? "---",
                    info: translate("verb", "EDIT"),
                    onPress: () => {
                        navigation.navigate("UpdateEmail", {
                            initialEmail: userState.email,
                        });
                    },
                },
            ],
        },
    };

    const updateUser = async (data) => {
        try {
            toggleIsLoading();
            const { data: meResponseData } = await user.updateMe(data);

            dispatch(
                userActions.setData({
                    firstName: meResponseData.first_name,
                    lastName: meResponseData.last_name,
                    birthday: meResponseData.birthday,
                    phoneNumber: meResponseData.phone_number,
                    address: meResponseData.address,
                    rfc: meResponseData.rfc,
                })
            );

            toggleIsLoading();

            setAlert(translate("sentence", "SAVED"));
        } catch (e) {
            toggleIsLoading();
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    return (
        <Wrapper whiteNavbar={true}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!alert}
                size={layout.width > 768 ? "medium" : "regular"}
                title={translate("word", "INFO")}
                titleColor="info"
                onAccept={removeAlert}
                onPressOut={removeAlert}
                onRequestClose={removeAlert}
            >
                {alert}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!error}
                size={layout.width > 768 ? "medium" : "regular"}
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={styles.screen}>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} navigation={navigation} />
                    </StyledView>
                    <H4 style={layout > 768 ? styles.h4 : styles.h4Mobile}>
                        {translate("sentence", "PERSONAL_INFORMATION")}
                    </H4>
                    <ProfileForm onSubmit={updateUser} />
                    <SettingsList
                        data={accountSettingsData}
                        style={styles.accountSettings}
                        sectionContainerStyle={styles.accountSettingsSectionContainer}
                        itemContainerStyle={styles.accountSettingsItemContainer}
                    />
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    h4: {
        width: "100%",
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    accountSettings: {
        marginTop: 30,
        paddingHorizontal: Spacing.screen.paddingHorizontal,
    },
    accountSettingsSectionContainer: {
        paddingHorizontal: 0,
    },
    accountSettingsItemContainer: {
        paddingHorizontal: 0,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    backButton: {
        alignSelf: "start",
    },
    h4Mobile: {
        width: "100%",
        paddingHorizontal: Spacing.screen.paddingHorizontal,
        marginTop: 48,
        textAlign: "center",
    },
});

export default AccountScreen;
