import Rapid from 'rapid.js';

import { API_BASE_URL, API_VERSION } from '../config/api';

export default class Model extends Rapid {
    boot() {
        this.baseURL = `${API_BASE_URL}/${API_VERSION}`;

        this.config.trailingSlash = true;

        this.config.suffixes = {
            create: '/',
            update: '/',
            destroy: '/',
            restore: 'restore'
        };
    }
}
