import { useEffect, useState } from "react";
import { StyleSheet, useWindowDimensions } from "react-native";
import CurrencyInput from "react-native-currency-input";

import { useLanguage } from "../../hooks";

import { Colors, RegularExpressions } from "../../constants";

import { useForm, Controller } from "react-hook-form";
import { FluidView, Field, RequiredIcon } from "../UI";
import { StyledSelect, StyledSwitch } from "../UI/Styled";
import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import FormAdditionals from "./FormAdditionals";

const FormPersiana = (props) => {
    const { isLoading, additionals, setAdditionals } = props;
    const isActive = props.isActive != undefined ? props.isActive == 0 : true;
    const inCalculator = props.inCalculator ?? false;

    const { translate } = useLanguage();
    const [totalAdditional, setTotalAdditional] = useState(0);

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const INSTALLATIONS_CHOICES = [
        { id: 0, label: translate("word", "WALL"), value: 0 },
        { id: 1, label: translate("word", "CEILING"), value: 1 },
    ];
    const CONTROL_SIDE_CHOICES = [
        { id: 0, label: "--", value: 0 },
        { id: 1, label: translate("word", "LEFT"), value: 1 },
        { id: 2, label: translate("word", "RIGHT"), value: 2 },
        { id: 3, label: translate("word", "RIGHTLEFT"), value: 3 },
    ];
    const TEJIDO_CHOICES = [
        { id: 0, label: "Blackout", value: 0 },
        { id: 1, label: "Transparentes", value: 1 },
        { id: 2, label: "Traslucido", value: 2 },
    ];

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setValue,
        setError: setErrorForm,
        clearErrors,
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            quantity: props.data?.quantity ?? 1,
            persiana_type: props.data?.persiana_type ?? "",
            model: props.data?.model ?? "",
            color: props.data?.color ?? "",
            width: props.data?.width ?? "",
            heigth: props.data?.heigth ?? "",
            cost: props.data?.cost ?? "",
            area: props.data?.area ?? "",
            notes: props.data?.notes ?? "",
            unit_cost: props.data?.unit_cost ?? "",
            total_cost: props.data?.total_cost ?? "",

            type_installation: props.data?.type_installation ?? 0,
            control_heigth: props.data?.control_heigth ?? "",
            automation: props.data?.automation ?? false,
            control: props.data?.control ?? false,
            control_side: props.data?.control_side ?? 0,
            location: props.data?.location ?? "",
            tejido: props.data?.tejido ?? 0,
        },
    });

    useEffect(() => {
        var heigth = parseFloat(watch("heigth"));
        var width = parseFloat(watch("width"));
        heigth = isNaN(heigth) ? 0 : heigth;
        width = isNaN(width) ? 0 : width;
        setValue("area", (heigth * width).toFixed(2));
    }, [watch("heigth"), watch("width")]);

    useEffect(() => {
        var area = parseFloat(watch("area"));
        var cost = parseFloat(watch("cost"));
        area = isNaN(area) ? 0 : area;
        cost = isNaN(cost) ? 0 : cost;
        setValue("unit_cost", (area * cost).toFixed(2));
    }, [watch("area"), watch("cost")]);

    useEffect(() => {
        var unit_cost = parseFloat(watch("unit_cost"));
        var quantity = parseFloat(watch("quantity"));
        unit_cost = isNaN(unit_cost) ? 0 : unit_cost;
        quantity = isNaN(quantity) ? 0 : quantity;
        setValue("total_cost", (unit_cost * quantity + totalAdditional * quantity).toFixed(2));
    }, [watch("unit_cost"), watch("quantity"), totalAdditional]);

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            <FluidView>
                <Controller
                    control={control}
                    name="quantity"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.quantity?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.quantity?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "QUANTITY")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.quantity ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.quantity ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                keyboardType="numeric"
                                style={styles.fromContainer}
                                placeholder={translate("word", "QUANTITY")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="persiana_type"
                    rules={{ required: true }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.persiana_type?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "TYPE_PERSIANA")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.persiana_type ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.persiana_type ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate("word", "TYPE_PERSIANA")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="model"
                    rules={{ required: true }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.model?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "MODEL")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.model ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.model ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate("word", "MODEL")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="color"
                    rules={{ required: true }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.color?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "COLOR")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.color ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.color ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate("word", "COLOR")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="width"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.width?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "WIDTH")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.width ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.width ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                keyboardType="decimal-pad"
                                style={styles.fromContainer}
                                placeholder={translate("word", "WIDTH")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="heigth"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.heigth?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.heigth?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "HEIGTH")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.heigth ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.heigth ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                keyboardType="decimal-pad"
                                style={styles.fromContainer}
                                placeholder={translate("word", "HEIGTH")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />

                <Controller
                    control={control}
                    name="automation"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.automation?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={translate("word", "AUTOMATION")}
                            labelColor={errors.automation ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <StyledSwitch
                                style={styles.checkBox}
                                disabled={!isActive}
                                value={value}
                                onValueChange={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="control"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.control?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={translate("word", "CONTROL")}
                            labelColor={errors.control ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <StyledSwitch
                                style={styles.checkBox}
                                disabled={!isActive}
                                value={value}
                                onValueChange={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="control_side"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.control_side?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={translate("word", "CONTROL_SIDE")}
                            labelColor={errors.control_side ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <StyledSelect
                                disabled={!isActive}
                                selectedValue={value}
                                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                data={CONTROL_SIDE_CHOICES}
                                style={styles.fromContainer}
                                textStyle={styles.fromContainerSelect}
                                textSize={isMobile ? 14 : 16}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="location"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.location?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={translate("word", "LOCATION")}
                            labelColor={errors.location ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.location ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate("word", "LOCATION")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="tejido"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.tejido?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : ""
                            }
                            title={translate("word", "TEJIDO")}
                            labelColor={errors.tejido ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <StyledSelect
                                disabled={!isActive}
                                selectedValue={value}
                                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                data={TEJIDO_CHOICES}
                                style={styles.fromContainer}
                                textStyle={styles.fromContainerSelect}
                                textSize={isMobile ? 14 : 16}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="type_installation"
                    rules={{ required: false }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.type_installation?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.type_installation?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "TYPE_INSTALLATION")}
                            labelColor={errors.type_installation ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <StyledSelect
                                disabled={!isActive}
                                selectedValue={value}
                                onValueChange={(itemValue, itemIndex) => onChange(itemValue)}
                                data={INSTALLATIONS_CHOICES}
                                style={styles.fromContainer}
                                textStyle={styles.fromContainerSelect}
                                textSize={isMobile ? 14 : 16}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="control_heigth"
                    rules={{ required: false, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.control_heigth?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.control_heigth?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "CONTROL_HEIGTH")}
                            labelColor={errors.control_heigth ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.control_heigth ? "danger" : ""}
                                iconName={isActive?"edit":null}
                                iconType="FontAwesome"
                                disabled={!isActive}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                keyboardType="decimal-pad"
                                style={styles.fromContainer}
                                placeholder={translate("word", "CONTROL_HEIGTH")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />

                <Controller
                    control={control}
                    name="area"
                    rules={{ required: true }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.area?.type === "required" ? translate("sentence", "PLEASE_ENTER_THIS_FIELD") : ""
                            }
                            title={translate("word", "AREA")}
                            labelColor={errors.area ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.area ? "danger" : ""}
                                disabled={true}
                                style={styles.fromContainer}
                                placeholder={translate("word", "AREA")}
                                value={value}
                                onChangeText={onChange}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="cost"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.cost?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "COST")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.cost ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <CurrencyInput
                                renderTextInput={(textInputProps) => (
                                    <FormTextInput
                                        {...textInputProps}
                                        iconName={isActive?"edit":null}
                                        iconType="FontAwesome"
                                        keyboardType="decimal-pad"
                                        style={styles.currencyInput}
                                    />
                                )}
                                placeholder={translate("word", "COST")}
                                disabled={!isActive}
                                value={value}
                                onBlur={onBlur}
                                onChangeValue={onChange}
                                prefix="$"
                                delimiter=","
                                separator="."
                                precision={2}
                                minValue={0}
                                style={isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="unit_cost"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.unit_cost?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "UNIT_COST")}
                            labelColor={errors.unit_cost ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <CurrencyInput
                                renderTextInput={(textInputProps) => (
                                    <FormTextInput
                                        {...textInputProps}
                                        iconName={isActive?"edit":null}
                                        iconType="FontAwesome"
                                        keyboardType="decimal-pad"
                                        style={styles.currencyInput}
                                    />
                                )}
                                placeholder={translate("word", "UNIT_COST")}
                                disabled={true}
                                value={value}
                                onBlur={onBlur}
                                onChangeValue={onChange}
                                prefix="$"
                                delimiter=","
                                separator="."
                                precision={2}
                                minValue={0}
                                style={isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="total_cost"
                    rules={{ required: true, pattern: RegularExpressions.DIGITS_FLOAT }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.total_cost?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "TOTAL_COST")}
                            labelColor={errors.total_cost ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <CurrencyInput
                                renderTextInput={(textInputProps) => (
                                    <FormTextInput
                                        {...textInputProps}
                                        iconName={isActive?"edit":null}
                                        iconType="FontAwesome"
                                        keyboardType="decimal-pad"
                                        style={styles.currencyInput}
                                    />
                                )}
                                placeholder={translate("word", "TOTAL_COST")}
                                disabled={true}
                                value={value}
                                onBlur={onBlur}
                                onChangeValue={onChange}
                                prefix="$"
                                delimiter=","
                                separator="."
                                precision={2}
                                minValue={0}
                                style={isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency}
                            />
                        </Field>
                    )}
                />
                {!inCalculator && (
                    <Controller
                        control={control}
                        name="notes"
                        rules={{ required: false, maxLength: 240 }}
                        style={styles.extrasContainer}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <Field
                                label={
                                    errors.notes?.type === "required"
                                        ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                        : errors.notes?.type === "maxLength"
                                        ? translate("sentence", "MAX_LENGTH_240")
                                        : ""
                                }
                                title={translate("word", "NOTES")}
                                labelColor={errors.notes ? "danger" : undefined}
                                style={styles.formField}
                            >
                                <FormTextInput
                                    inputType={errors.notes ? "danger" : ""}
                                    iconName={isActive?"edit":null}
                                    iconType="FontAwesome"
                                    disabled={!isActive}
                                    multiline={true}
                                    returnKeyType="next"
                                    autoCorrect={false}
                                    autoCapitalize="none"
                                    style={styles.fromContainer}
                                    placeholder={translate("word", "NOTES")}
                                    value={value}
                                    onChangeText={onChange}
                                    onBlur={onBlur}
                                />
                            </Field>
                        )}
                    />
                )}
                {!inCalculator && (
                    <FormAdditionals
                        {...{ additionals, setAdditionals, setErrorForm, clearErrors, setTotalAdditional, isActive }}
                    />
                )}
            </FluidView>
            {isActive && !inCalculator && (
                <FormButton
                    buttonType="nummaBlue"
                    disabled={isLoading}
                    isLoading={isLoading}
                    style={styles.formButton}
                    onPress={handleSubmit(props.onSubmit)}
                >
                    {translate("sentence", "SAVE")}
                </FormButton>
            )}
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    formField: {
        marginTop: 10,
    },
    formButton: {
        marginTop: 10,
    },
    extrasContainer: {},
    fromContainer: {
        backgroundColor: Colors.NUMMA_YELLOW,
        width: "100%",
    },
    fromContainerSelect: {
        backgroundColor: Colors.NUMMA_YELLOW,
        width: "100%",
    },
    fromContainerCurrency: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingHorizontal: 0,
        paddingTop: 15,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 18,
        paddingLeft: 15,
    },
    checkBox: {
        justifyContent: "center",
    },
    fromContainerCurrencyMobile: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingHorizontal: 0,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 14,
        paddingLeft: 15,
    },
    formFieldMobile: {
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    fromContainerMobile: {
        width: "100%",
    },
    labelMobile: {
        marginVertical: "auto",
        width: "35%",
    },
    containerInput: {
        width: "65%",
    },
    currencyInput: {
        backgroundColor: Colors.NUMMA_YELLOW,
        paddingRight: 10,
    },
});

export default FormPersiana;
