import { StyleSheet, useWindowDimensions } from "react-native";
import { StyledButton, StyledIcon } from "../../UI/Styled";
import { Colors } from "../../../constants";
import { Icons } from "../../../styles";

const BackButton = ({ navigation }) => {
    const layout = useWindowDimensions();

    if (navigation.canGoBack()) {
        return (
            <StyledButton
                buttonType={layout.width > 768 ? "nummaBlue" : "lightGray"}
                textSize={12}
                iconName={Icons.header.back}
                onPress={() => {
                    navigation.goBack();
                }}
                style={layout.width > 768 ? styles.backButton : styles.backButtonMobile}
            >
                {layout.width > 768 ? (
                    "ATRAS"
                ) : (
                    <StyledIcon iconType="FontAwesome5" name="arrow-left" color={Colors.BLACK} />
                )}
            </StyledButton>
        );
    } else {
        return <></>;
    }
};

const styles = StyleSheet.create({
    backButton: {},
    backButtonMobile: {
        marginLeft: 5,
        borderRadius: "100%",
        position: "absolute",
    },
});

export default BackButton;
