import { useState, useEffect } from "react";
import { StyleSheet} from "react-native";
import { Popable } from "react-native-popable";

import { StyledView, StyledText, StyledIcon } from "../../components/UI/Styled";
import { Card, H5 } from "../../components/UI";
import { useLanguage } from "../../hooks";
import { Colors } from "../../constants";

const generateColums = (optionsArray, dataArray) => {
    if (optionsArray.length == dataArray.length) {
        return optionsArray.map((element, index) => {
            if (dataArray.length - 1 != index) {
                return (
                    <StyledView
                        key={index}
                        style={styles.containerProductLineMobile}
                        flexDirection="row"
                        justifyContent="space-around"
                        alignItems="center"
                    >
                        <StyledView
                            style={styles.containerProductInnerMobile}
                            flexDirection="row"
                            justifyContent="space-around"
                            alignItems="center"
                        >
                            <StyledText style={styles.container100}>{element}</StyledText>
                            <StyledText color={Colors.GRAY} style={styles.container100}>
                                {dataArray[index]}
                            </StyledText>
                        </StyledView>
                    </StyledView>
                );
            }
        });
    } else {
        return [];
    }
};

const CardProduct = (props) => {
    const [title, setTitle] = useState("");
    const [optionsArray, setOptionsArray] = useState("");

    const { translate } = useLanguage();

    const optionsPersiana = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "MODEL"),
        translate("word", "COLOR"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const optionsT90 = [
        translate("word", "WIDTH"),
        translate("word", "PROJECTION"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const optionsTvertical = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "MODEL"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const optionsTproyeccion = [
        translate("word", "WIDTH"),
        translate("word", "PROJECTION"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const optionsCortina = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "CONFECTION"),
        translate("word", "MODEL"),
        translate("word", "COLOR"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "ROLL_WIDTH"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];

    useEffect(() => {
        switch (props.typeProduct) {
            case 1:
                setTitle("Persiana");
                setOptionsArray(optionsPersiana);
                break;
            case 2:
                setTitle("Toldo 90");
                setOptionsArray(optionsT90);
                break;
            case 3:
                setTitle("Toldo vertical");
                setOptionsArray(optionsTvertical);
                break;
            case 4:
                setTitle("Toldo proyeccion");
                setOptionsArray(optionsTproyeccion);
                break;
            case 5:
                setTitle("Cortina");
                setOptionsArray(optionsCortina);
                break;

            default:
                break;
        }
    }, [props.typeProduct, props.dataArray]);

    return (
        <Card size="large" style={styles.containerCardProduct}>
            <StyledView style={styles.containerTitle} flexDirection="row">
                <H5 style={styles.titleCard}>{title}</H5>

                <StyledView style={styles.buttonRightContainer}>
                    <Popable
                        animationType="spring"
                        content={
                            <StyledView
                                style={{
                                    boxShadow: "0px 0px 5px gray",
                                    borderRadius: 5,
                                    margin: 5,
                                }}
                            >
                                {props.dataArray[props.dataArray.length - 1]}
                            </StyledView>
                        }
                        numberOfLines={10}
                        backgroundColor={Colors.WHITE}
                        onLayout={{ style: { zIndex: 3 } }}
                        style={{
                            transform: "translateX(-80px)",
                            width: 115,
                        }}
                        caret={false}
                        position="bottom"
                        strictPosition={true}
                    >
                        <StyledIcon
                            size={25}
                            name="dots-horizontal"
                            iconType="MaterialCommunityIcons"
                            color={Colors.NUMMA_BLUE}
                        />
                    </Popable>
                </StyledView>
            </StyledView>
            {generateColums(optionsArray, props.dataArray)}
        </Card>
    );
};

const styles = StyleSheet.create({
    containerCardProduct: {
        width: "100%",
        marginVertical: 15,
    },
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    containerTitle: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
        zIndex: 99,
    },
    containerProductInnerMobile: {
        width: "80%",
    },
    containerProductLineMobile: {
        width: "90%",
        borderBottomColor: Colors.LIGHT_GRAY,
        borderBottomWidth: 1,
        paddingVertical: 5,
        zIndex: 1,
    },
    buttonRightContainer: {
        marginLeft: "auto",
        height: 35,
        paddingVertical: 3,
        paddingHorizontal: 3,
        color: Colors.WHITE,
    },
    buttonRight: {
        height: 35,
        paddingVertical: 3,
        paddingHorizontal: 3,
        color: Colors.WHITE,
    },
    titleCard: {
        marginVertical: "auto",
    },
});

export default CardProduct;
