import { isValidElement } from 'react';
import {
    StyleSheet,
    TouchableWithoutFeedback,
    View,
    Modal
} from 'react-native';

import { Colors } from '../../../constants';

import { Icons, Views, Cards } from '../../../styles';

import Card from '../Card';
import StyledText from './StyledText';
import StyledButton from './StyledButton';
import StyledIcon from './StyledIcon';

const modalTypes = {
    modal: {
        animationType: 'fade',
        styles: {
            container: { backgroundColor: 'rgba(177, 177, 177, 0.7)' },
            card: {}
        },
        titlePosition: 'center',
        closeButton: true,
        closeButtonPosition: 'right',
        acceptButton: false,
        acceptButtonText: 'Ok',
        acceptButtonPosition: 'center',
        confirmButton: false,
        confirmButtonText: 'Confirm',
        confirmButtonPosition: 'right',
        cancelButton: false,
        cancelButtonText: 'Cancel',
        cancelButtonPosition: 'left'
    },
    alert: {
        animationType: 'fade',
        styles: {
            container: { backgroundColor: 'rgba(177, 177, 177, 0.7)' },
            card: {}
        },
        titlePosition: 'center',
        closeButton: false,
        closeButtonPosition: 'right',
        acceptButton: true,
        acceptButtonText: 'Ok',
        acceptButtonPosition: 'center',
        confirmButton: false,
        confirmButtonText: 'Confirm',
        confirmButtonPosition: 'right',
        cancelButton: false,
        cancelButtonText: 'Cancel',
        cancelButtonPosition: 'left'
    },
    dialog: {
        animationType: 'fade',
        styles: {
            container: { backgroundColor: 'rgba(177, 177, 177, 0.7)' },
            card: {}
        },
        titlePosition: 'center',
        closeButton: false,
        closeButtonPosition: 'right',
        acceptButton: false,
        acceptButtonText: 'Ok',
        acceptButtonPosition: 'center',
        confirmButton: true,
        confirmButtonText: 'Confirm',
        confirmButtonPosition: 'right',
        cancelButton: true,
        cancelButtonText: 'Cancel',
        cancelButtonPosition: 'left'
    }
};

const getStylesFromProps = ({ modalType }) => {
    const styles = { container: {}, card: {} };

    if (modalTypes[modalType]) {
        styles.container.backgroundColor =
            modalTypes[modalType].styles.container.backgroundColor;
    }

    return styles;
};

const getContent = (content, modalType, options) => {
    modalType = modalTypes[modalType];

    const header = {
        style: {
            marginBottom: 10,
            width: '100%',
            ...options.headerStyle
        }
    };

    const title = {
        text: options.title ?? '',
        position:
            typeof options.titlePosition !== 'undefined'
                ? options.titlePosition
                : modalType.titlePosition,
        font: options.titleFont,
        size: options.titleSize,
        color: options.titleColor,
        align: options.titleAlign,
        style: {
            ...options.titleStyle
        }
    };

    const closeButton = {
        visible:
            typeof options.closeButton !== 'undefined'
                ? !!options.closeButton
                : modalType.closeButton,
        position:
            typeof options.closeButtonPosition !== 'undefined'
                ? options.closeButtonPosition
                : modalType.closeButtonPosition,
        style: {
            ...options.closeButtonStyle
        },
        onPress: options.onClose
    };

    const body = {
        style: {
            width: '100%',
            ...options.bodyStyle
        }
    };

    const description = {
        content: content ?? '',
        font: options.descriptionFont,
        size: options.descriptionSize,
        color: options.descriptionColor,
        align: options.descriptionAlign,
        style: {
            width: '100%',
            ...options.descriptionStyle
        }
    };

    const footer = {
        style: {
            width: '100%',
            marginTop: 20,
            ...options.footerStyle
        }
    };

    const buttons = {
        accept: {
            visible:
                typeof options.acceptButton !== 'undefined'
                    ? !!options.acceptButton
                    : modalType.acceptButton,
            text:
                typeof options.acceptButtonText !== 'undefined'
                    ? options.acceptButtonText
                    : modalType.acceptButtonText,
            position:
                typeof options.acceptButtonPosition !== 'undefined'
                    ? options.acceptButtonPosition
                    : modalType.acceptButtonPosition,
            buttonType: options.acceptButtonType,
            size: options.acceptButtonSize,
            disabled: options.acceptButtonDisabled,
            isLoading: options.acceptButtonIsLoading,
            textFont: options.acceptButtonTextFont,
            textSize: options.acceptButtonTextSize,
            textColor: options.acceptButtonTextColor,
            textAlign: options.acceptButtonTextAlign,
            textStyle: options.acceptButtonTextStyle,
            loadingIconSize: options.acceptButtonLoadingIconSize,
            loadingIconColor: options.acceptButtonLoadingIconColor,
            loadingIconStyle: options.acceptButtonLoadingIconStyle,
            style: {
                ...options.acceptButtonStyle
            },
            textStyle: {
                ...options.acceptButtonStyle
            },
            loadingIconStyle: {
                ...options.acceptButtonStyle
            },
            onPress: options.onAccept
        },
        confirm: {
            visible:
                typeof options.confirmButton !== 'undefined'
                    ? !!options.confirmButton
                    : modalType.confirmButton,
            text:
                typeof options.confirmButtonText !== 'undefined'
                    ? options.confirmButtonText
                    : modalType.confirmButtonText,
            position:
                typeof options.confirmButtonPosition !== 'undefined'
                    ? options.confirmButtonPosition
                    : modalType.confirmButtonPosition,
            buttonType: options.confirmButtonType,
            size: options.confirmButtonSize,
            disabled: options.confirmButtonDisabled,
            isLoading: options.confirmButtonIsLoading,
            textFont: options.confirmButtonTextFont,
            textSize: options.confirmButtonTextSize,
            textColor: options.confirmButtonTextColor,
            textAlign: options.confirmButtonTextAlign,
            textStyle: options.confirmButtonTextStyle,
            loadingIconSize: options.confirmButtonLoadingIconSize,
            loadingIconColor: options.confirmButtonLoadingIconColor,
            loadingIconStyle: options.confirmButtonLoadingIconStyle,
            style: {
                ...options.confirmButtonStyle
            },
            textStyle: {
                ...options.confirmButtonStyle
            },
            loadingIconStyle: {
                ...options.confirmButtonStyle
            },
            onPress: options.onConfirm
        },
        cancel: {
            visible:
                typeof options.cancelButton !== 'undefined'
                    ? !!options.cancelButton
                    : modalType.cancelButton,
            text:
                typeof options.cancelButtonText !== 'undefined'
                    ? options.cancelButtonText
                    : modalType.cancelButtonText,
            position:
                typeof options.cancelButtonPosition !== 'undefined'
                    ? options.cancelButtonPosition
                    : modalType.cancelButtonPosition,
            buttonType: options.cancelButtonType,
            size: options.cancelButtonSize,
            disabled: options.cancelButtonDisabled,
            isLoading: options.cancelButtonIsLoading,
            textFont: options.cancelButtonTextFont,
            textSize: options.cancelButtonTextSize,
            textColor: options.cancelButtonTextColor,
            textAlign: options.cancelButtonTextAlign,
            textStyle: options.cancelButtonTextStyle,
            loadingIconSize: options.cancelButtonLoadingIconSize,
            loadingIconColor: options.cancelButtonLoadingIconColor,
            loadingIconStyle: options.cancelButtonLoadingIconStyle,
            style: {
                ...options.cancelButtonStyle
            },
            textStyle: {
                ...options.cancelButtonStyle
            },
            loadingIconStyle: {
                ...options.cancelButtonStyle
            },
            onPress: options.onCancel
        }
    };

    const styles = StyleSheet.create({
        content: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '100%'
            // width: options.size ? '100%' : 200
        }
    });

    return (
        <View style={styles.content}>
            {(title.text || closeButton.visible) &&
                getHeader(header, title, closeButton)}
            {getBody(body, description)}
            {(buttons.accept.visible ||
                buttons.confirm.visible ||
                buttons.cancel.visible) &&
                getFooter(footer, buttons)}
        </View>
    );
};

const getHeader = (header, title, closeButton) => {
    const styles = StyleSheet.create({
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 35,
            ...header.style
        },
        title: {
            ...title.style
        },
        closeButton: {
            marginLeft: closeButton.position === 'left' ? 0 : 10,
            marginRight: closeButton.position === 'left' ? 10 : 0,
            ...closeButton.style
        }
    });

    const Title = (
        <StyledText
            font={title.font ?? 'bold'}
            size={title.size ?? 17}
            color={title.color ?? 'darkGray'}
            align={title.align ?? 'left'}
            style={styles.title}
        >
            {title.text}
        </StyledText>
    );

    const CloseButton = (
        <StyledIcon
            name={Icons.modal.close}
            size={25}
            color={Colors.GRAY}
            style={{ ...styles.closeButton }}
            onPress={closeButton.onPress}
        />
    );

    const EmptyComponent = <StyledText></StyledText>;

    return (
        <View style={styles.header}>
            {title.position === 'left'
                ? Title
                : closeButton.visible && closeButton.position === 'left'
                    ? CloseButton
                    : EmptyComponent}
            {title.position === 'center' && Title}
            {title.position === 'right'
                ? Title
                : closeButton.visible && closeButton.position === 'right'
                    ? CloseButton
                    : EmptyComponent}
        </View>
    );
};

const getBody = (body, description) => {
    const styles = StyleSheet.create({
        body: {
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            ...body.style
        },
        description: {
            ...description.style
        }
    });

    const Description = isValidElement(description.content) ? (
        description.content
    ) : (
        <StyledText
            font={description.font ?? 'regular'}
            size={description.size ?? 16}
            color={description.color ?? 'darkGray'}
            align={description.align ?? 'left'}
            style={styles.description}
        >
            {description.content}
        </StyledText>
    );

    return <View style={styles.body}>{Description}</View>;
};

const getFooter = (footer, buttons) => {
    const styles = StyleSheet.create({
        footer: {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            ...footer.style
        },
        acceptButton: {
            minWidth: 50,
            marginHorizontal: 10,
            paddingHorizontal: 10,
            paddingVertical: 10,
            borderRadius: 5,
            ...buttons.accept.style
        },
        acceptButtonText: {
            fontSize: 15,
            ...buttons.accept.textStyle
        },
        acceptButtonLoadingIcon: {
            ...buttons.accept.loadingIconStyle
        },
        confirmButton: {
            minWidth: 50,
            marginHorizontal: 10,
            paddingHorizontal: 10,
            paddingVertical: 10,
            borderRadius: 5,
            ...buttons.confirm.style
        },
        confirmButtonText: {
            fontSize: 15,
            ...buttons.confirm.textStyle
        },
        confirmButtonLoadingIcon: {
            ...buttons.confirm.loadingIconStyle
        },
        cancelButton: {
            minWidth: 50,
            marginHorizontal: 10,
            paddingHorizontal: 10,
            paddingVertical: 10,
            borderRadius: 5,
            ...buttons.cancel.style
        },
        cancelButtonText: {
            fontSize: 15,
            ...buttons.cancel.textStyle
        },
        cancelButtonLoadingIcon: {
            ...buttons.cancel.loadingIconStyle
        }
    });

    const AcceptButton = (
        <StyledButton
            buttonType={buttons.accept.buttonType ?? 'primary'}
            size={buttons.accept.size}
            disabled={buttons.accept.disabled}
            isLoading={buttons.accept.isLoading}
            style={styles.acceptButton}
            textFont={buttons.accept.textFont}
            textSize={buttons.accept.textSize}
            textColor={buttons.accept.textColor}
            textAlign={buttons.accept.textAlign}
            textStyle={styles.acceptButtonText}
            loadingIconSize={buttons.accept.loadingIconSize}
            loadingIconColor={buttons.accept.loadingIconColor}
            loadingIconStyle={styles.acceptButtonLoadingIcon}
            onPress={buttons.accept.onPress}
        >
            {buttons.accept.text}
        </StyledButton>
    );

    const ConfirmButton = (
        <StyledButton
            buttonType={buttons.confirm.buttonType ?? 'primary'}
            size={buttons.confirm.size}
            disabled={buttons.confirm.disabled}
            isLoading={buttons.confirm.isLoading}
            style={styles.confirmButton}
            textFont={buttons.confirm.textFont}
            textSize={buttons.confirm.textSize}
            textColor={buttons.confirm.textColor}
            textAlign={buttons.confirm.textAlign}
            textStyle={styles.confirmButtonText}
            loadingIconSize={buttons.confirm.loadingIconSize}
            loadingIconColor={buttons.confirm.loadingIconColor}
            loadingIconStyle={styles.confirmButtonLoadingIcon}
            onPress={buttons.confirm.onPress}
        >
            {buttons.confirm.text}
        </StyledButton>
    );

    const CancelButton = (
        <StyledButton
            buttonType={buttons.cancel.buttonType ?? 'gray'}
            size={buttons.cancel.size}
            disabled={buttons.cancel.disabled}
            isLoading={buttons.cancel.isLoading}
            style={styles.cancelButton}
            textFont={buttons.cancel.textFont}
            textSize={buttons.cancel.textSize}
            textColor={buttons.cancel.textColor}
            textAlign={buttons.cancel.textAlign}
            textStyle={styles.cancelButtonText}
            loadingIconSize={buttons.cancel.loadingIconSize}
            loadingIconColor={buttons.cancel.loadingIconColor}
            loadingIconStyle={styles.cancelButtonLoadingIcon}
            onPress={buttons.cancel.onPress}
        >
            {buttons.cancel.text}
        </StyledButton>
    );

    return (
        <View style={styles.footer}>
            {buttons.accept.visible && buttons.accept.position === 'left'
                ? AcceptButton
                : buttons.confirm.visible && buttons.confirm.position === 'left'
                    ? ConfirmButton
                    : buttons.cancel.visible && buttons.cancel.position === 'left'
                        ? CancelButton
                        : undefined}
            {buttons.accept.visible && buttons.accept.position === 'center'
                ? AcceptButton
                : buttons.confirm.visible && buttons.confirm.position === 'center'
                    ? ConfirmButton
                    : buttons.cancel.visible && buttons.cancel.position === 'center'
                        ? CancelButton
                        : undefined}
            {buttons.accept.visible && buttons.accept.position === 'right'
                ? AcceptButton
                : buttons.confirm.visible && buttons.confirm.position === 'right'
                    ? ConfirmButton
                    : buttons.cancel.visible && buttons.cancel.position === 'right'
                        ? CancelButton
                        : undefined}
        </View>
    );
};

const StyledModal = (props) => {
    const { modalType, onPressOut, children } = props;

    const { container: containerStyle, card: cardStyle } =
        getStylesFromProps(props);

    const styles = StyleSheet.create({
        container: {
            ...Views.modal,
            ...containerStyle,
            ...props.containerStyle
        },
        card: {
            ...Cards.modal,
            ...cardStyle,
            ...props.cardStyle
        }
    });

    modalTypes.modal.acceptButtonText = 'OK';
    modalTypes.modal.confirmButtonText = 'CONFIRMAR';
    modalTypes.modal.cancelButtonText = 'CANCELAR';

    modalTypes.alert.acceptButtonText = 'OK';
    modalTypes.alert.confirmButtonText = 'CONFIRMAR';
    modalTypes.alert.cancelButtonText = 'CANCELAR';

    modalTypes.dialog.acceptButtonText = 'OK';
    modalTypes.dialog.confirmButtonText = 'CONFIRMAR';
    modalTypes.dialog.cancelButtonText = 'CANCELAR';

    const content = modalTypes[modalType]
        ? getContent(children, modalType, props)
        : children;

    return (
        <Modal
            animationType={modalTypes[modalType]?.animationType ?? 'slide'}
            transparent
            {...props}
        >
            {!!onPressOut ? (
                <TouchableWithoutFeedback accessible={false} onPress={onPressOut}>
                    <View style={styles.container}>
                        <Card size={props.size} style={styles.card}>
                            {content}
                        </Card>
                    </View>
                </TouchableWithoutFeedback>
            ) : (
                <View style={styles.container}>
                    <Card size={props.size} style={styles.card}>
                        {content}
                    </Card>
                </View>
            )}
        </Modal>
    );
};

export default StyledModal;
