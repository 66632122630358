import { Platform, StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import * as DocumentPicker from "expo-document-picker";

import { Colors, RegularExpressions } from "../../constants";

import { useLanguage } from "../../hooks";

import { FluidView, Field, RequiredIcon } from "../UI";
import { StyledButton, StyledIcon } from "../UI/Styled";
import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

const PriceForm = (props) => {
    const { isLoading } = props;

    const { translate } = useLanguage();

    const pickDocumentHandler = async () => {
        let document = await DocumentPicker.getDocumentAsync({
            type: ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"],
        });
        // console.log("document", document);

        if (document.type === "cancel") {
            return;
        }

        props.onSubmit(document);
    };

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            <FluidView>
                <StyledButton onPress={pickDocumentHandler} style={styles.exampleFile} buttonType="white" textSize={14}>
                    <StyledIcon name="upload" iconType="FontAwesome" color="nummaBlue" size={14} />{" "}
                    {translate("sentence", "PRICES_UPLOAD")}
                </StyledButton>
            </FluidView>
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    formField: {
        marginBottom: 10,
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    extrasContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
    },
});

export default PriceForm;
