import { StyleSheet, Text } from 'react-native';

import { Spacing } from '../../styles';

import {
    Wrapper,
    Screen,
    FluidView,
} from '../../components/UI';

const HomeScreen = ({navigation, route}) => {

    return (
        <Wrapper>
            <Screen style={styles.screen}>
                <FluidView>
                    <Text></Text>
                </FluidView>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    container: {
        minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth,
        marginTop: 30,
        paddingHorizontal: Spacing.screen.paddingHorizontal
    },
    paragraph: {
        marginTop: 30
    }
});

export default HomeScreen;
