import { StyleSheet, useWindowDimensions } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import CurrencyInput from "react-native-currency-input";

import { Colors, RegularExpressions } from "../../constants";

import { useLanguage } from "../../hooks";

import { FluidView, Field, RequiredIcon } from "../UI";
import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";
import StyledSwitch from "../UI/Styled/StyledSwitch";
import FormAdditionals from "../../components/Projects/FormAdditionals";

const ProjectForm = (props) => {
    const isActive = props.isActive ?? true;
    const { isLoading } = props;
    const additionals = props.update && props.additionalsProject;
    const setAdditionals = props.update && props.setAdditionalsProject;
    const setTotalAdditional = props.update && props.setTotalAdditional;

    const userState = useSelector((state) => state.user);

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const {
        control,
        handleSubmit,
        formState: { errors },
        setError: setErrorForm,
        clearErrors,
    } = useForm({
        mode: "onBlur",
        defaultValues: props.project
            ? {
                  name: props.project?.name ?? "",
                  client_name: props.project?.client_name ?? "",
                  client_phone: props.project?.client_phone ?? "",
                  client_email: props.project?.client_email ?? "",
                  client_street: props.project?.client_street ?? "",
                  client_number: props.project?.client_number ?? "",
                  client_city: props.project?.client_city ?? "",
                  client_state: props.project?.client_state ?? "",
                  client_suburb: props.project?.client_suburb ?? "",
                  zip_code: props.project?.zip_code ?? "",
                  selling_policies: props.project?.selling_policies ?? "",
                  status: !props.project?.status ?? true,
                  custom_user: userState.id,
                  discount: props.project?.discount ?? "",
                  iva: props.project?.iva ?? false,
                  advance: props.project?.advance ?? "",
              }
            : {
                  name: "",
                  client_name: "",
                  client_phone: "",
                  client_email: "",
                  client_street: "",
                  client_number: "",
                  client_city: "",
                  client_state: "",
                  client_suburb: "",
                  zip_code: "",
                  selling_policies: "",
                  custom_user: userState.id,
                  discount: "",
                  iva: false,
                  advance: "",
              },
    });
    const { translate } = useLanguage();

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            <FluidView>
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.name?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_PROJECT_NAME")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "PROJECT_NAME")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.name ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.name ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "PROJECT_NAME")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_name"
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_name?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_NAME")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "CLIENT_NAME")}
                                    <RequiredIcon />
                                </>
                            }
                            labelColor={errors.client_name ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_name ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "CLIENT_NAME")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_phone"
                    rules={{ required: false, pattern: RegularExpressions.PHONE_NUMBER }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_phone?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_PHONE")
                                    : errors.client_phone?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_PHONE_NUMBER")
                                    : ""
                            }
                            title={translate("word", "CLIENT_PHONE")}
                            labelColor={errors.client_phone ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_phone ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "CLIENT_PHONE")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_email"
                    rules={{ required: false, pattern: RegularExpressions.EMAIL }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_email?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_EMAIL")
                                    : errors.client_email?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_EMAIL")
                                    : ""
                            }
                            title={translate("word", "CLIENT_EMAIL")}
                            labelColor={errors.client_email ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_email ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "CLIENT_EMAIL")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_street"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_street?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_STREET")
                                    : ""
                            }
                            title={translate("word", "STREET")}
                            labelColor={errors.client_street ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_street ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "STREET")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_number"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_number?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_NUMBER")
                                    : ""
                            }
                            title={translate("word", "NUMBER")}
                            labelColor={errors.client_number ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_number ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "NUMBER")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_suburb"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_suburb?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_SUBURB")
                                    : ""
                            }
                            title={translate("word", "SUBURB")}
                            labelColor={errors.client_suburb ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_suburb ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "SUBURB")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_city"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_city?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_CITY")
                                    : ""
                            }
                            title={translate("word", "CITY")}
                            labelColor={errors.client_city ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_city ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "CITY")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="client_state"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.client_state?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_STATE")
                                    : ""
                            }
                            title={translate("word", "STATE")}
                            labelColor={errors.client_state ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.client_state ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "STATE")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="zip_code"
                    rules={{ required: false }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.zip_code?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_CLIENT_ZIP_CODE")
                                    : ""
                            }
                            title={translate("word", "ZIP_CODE")}
                            labelColor={errors.zip_code ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <FormTextInput
                                inputType={errors.zip_code ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={isMobile && styles.fromContainerMobile}
                                placeholder={translate("word", "ZIP_CODE")}
                                value={value}
                                keyboardType="decimal-pad"
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="selling_policies"
                    rules={{ required: false, maxLength: 1500 }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.selling_policies?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.selling_policies?.type === "maxLength"
                                    ? translate("sentence", "MAX_LENGTH")
                                    : ""
                            }
                            title={translate("word", "SELLING_POLICIES")}
                            labelColor={errors.selling_policies ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.selling_policies ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                multiline={true}
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                style={styles.fromContainer}
                                placeholder={translate("word", "SELLING_POLICIES")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="iva"
                    style={styles.formField}
                    render={({ field: { onChange, value } }) => (
                        <StyledSwitch value={value} label={translate("word", "IVA")} onValueChange={onChange} />
                    )}
                />
                <Controller
                    control={control}
                    name="discount"
                    rules={{ required: false, pattern: RegularExpressions.DIGITS_FLOAT }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.discount?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "DISCOUNT")}
                            labelColor={errors.discount ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <CurrencyInput
                                renderTextInput={(textInputProps) => (
                                    <FormTextInput
                                        {...textInputProps}
                                        iconName="edit"
                                        iconType="FontAwesome"
                                        keyboardType="decimal-pad"
                                        style={styles.currencyInput}
                                    />
                                )}
                                placeholder={translate("word", "DISCOUNT")}
                                value={value}
                                onBlur={onBlur}
                                onChangeValue={onChange}
                                prefix="%"
                                delimiter=","
                                separator="."
                                precision={2}
                                maxValue={100}
                                minValue={0}
                                style={isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="advance"
                    rules={{ required: false, pattern: RegularExpressions.DIGITS_FLOAT }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.advance?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.width?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_NUMBER")
                                    : ""
                            }
                            title={translate("word", "ADVANCE")}
                            labelColor={errors.advance ? "danger" : undefined}
                            style={isMobile ? styles.formFieldMobile : styles.formField}
                            titleStyle={isMobile && styles.labelMobile}
                            styleInput={isMobile && styles.containerInput}
                        >
                            <CurrencyInput
                                renderTextInput={(textInputProps) => (
                                    <FormTextInput
                                        {...textInputProps}
                                        iconName="edit"
                                        iconType="FontAwesome"
                                        keyboardType="decimal-pad"
                                        style={styles.currencyInput}
                                    />
                                )}
                                placeholder={translate("word", "ADVANCE")}
                                value={value}
                                onBlur={onBlur}
                                onChangeValue={onChange}
                                prefix="$"
                                delimiter=","
                                separator="."
                                precision={2}
                                minValue={0}
                                style={isMobile ? styles.fromContainerCurrencyMobile : styles.fromContainerCurrency}
                            />
                        </Field>
                    )}
                />
                {props.project && (
                    <Controller
                        control={control}
                        name="status"
                        render={({ field: { onChange, value } }) => (
                            <StyledSwitch
                                value={value}
                                label={translate("sentence", "ESTATUS")}
                                onValueChange={onChange}
                            />
                        )}
                    />
                )}
                {props.update && (
                    <FormAdditionals
                        {...{ additionals, setAdditionals, setErrorForm, clearErrors, setTotalAdditional, isActive }}
                    />
                )}
            </FluidView>
            <FormButton
                disabled={isLoading}
                isLoading={isLoading}
                style={styles.formButton}
                onPress={handleSubmit(props.onSubmit)}
            >
                {translate("sentence", "SAVE")}
            </FormButton>
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container100: {
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    formField: {
        marginBottom: 10,
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    formFieldMobile: {
        marginVertical: 10,
        flexDirection: "row",
        justifyContent: "space-between",
    },
    fromContainerMobile: {
        width: "100%",
    },
    labelMobile: {
        marginVertical: "auto",
    },
    containerInput: {
        width: "65%",
    },
    fromContainerCurrency: {
        paddingHorizontal: 0,
        paddingTop: 15,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 18,
        paddingLeft: 15,
    },
    fromContainerCurrencyMobile: {
        paddingHorizontal: 0,
        paddingTop: 10,
        paddingBottom: 10,
        borderWidth: 1,
        borderRadius: 5,
        fontSize: 14,
        paddingLeft: 15,
    },
    currencyInput: {
        paddingRight: 10,
    },
});

export default ProjectForm;
