import { useState, useEffect } from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { RegularExpressions } from "../../constants";

import { Spacing } from "../../styles";

import { userActions } from "../../store/slices/user";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import User from "../../models/User";

import { Wrapper, Screen, Container, FluidView, LoadingModal, Field } from "../../components/UI";
import { StyledModal, StyledButton } from "../../components/UI/Styled";
import FormTextInput from "../../components/Profile/FormTextInput";
import { BackButton } from "../../components/Layout/Header";
import { StyledView } from "../../components/UI/Styled";
import FormButton from "../../components/Profile/FormButton";

const isIos = Platform.OS === "ios";

const VerifyEmailScreen = ({ navigation, route }) => {
    const email = route.params?.email;

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const accessToken = useSelector((state) => state.auth.token);

    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: { verificationCode: "" },
    });

    const { translate } = useLanguage();

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeError = () => {
        setError(null);
    };

    const cancelHandler = () => {
        navigation.goBack();
    };

    const saveHandler = async (formData) => {
        try {
            toggleIsLoading();

            await user.updateMe({
                email,
                email_code: formData.verificationCode,
            });

            dispatch(userActions.setData({ email }));

            toggleIsLoading();

            navigation.navigate("Account");
        } catch (e) {
            toggleIsLoading();

            switch (e?.response?.status) {
                case 400:
                    setError(translate("sentence", "YOUR_VERIFICATION_CODE_IS_WRONG"));
                    break;
                case 401:
                    dispatch(appActions.notReady());
                    break;
                default:
                    setError(translate("sentence", "SOMETHING_WENT_WRONG"));
                    break;
            }
        }
    };

    const CancelButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={cancelHandler}
            style={styles.cancelButtonContainer}
        >
            {translate("verb", "CANCEL")}
        </StyledButton>
    );

    const SaveButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={handleSubmit(saveHandler)}
            style={styles.saveButtonContainer}
        >
            {translate("verb", "SAVE")}
        </StyledButton>
    );

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => CancelButton,
            headerRight: () => SaveButton,
        });
    }, []);

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="large"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} {...{ navigation }} />
                    </StyledView>
                    <FluidView style={styles.inputsContainer}>
                        <Controller
                            control={control}
                            name="verificationCode"
                            rules={{
                                required: true,
                                pattern: RegularExpressions.VERIFICATION_CODE,
                            }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    title={translate("word", "VERIFICATION_CODE")}
                                    label={translate("sentence", "PLEASE_ENTER_YOUR_VERIFICATION_CODE")}
                                    labelColor={errors.verificationCode ? "danger" : undefined}
                                >
                                    <FormTextInput
                                        inputType={errors.verificationCode ? "danger" : ""}
                                        keyboardType="numeric"
                                        returnKeyType="done"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        placeholder={translate("word", "VERIFICATION_CODE")}
                                        value={value}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                    </FluidView>
                    <FormButton
                        disabled={isLoading}
                        isLoading={isLoading}
                        style={styles.formButton}
                        onPress={handleSubmit(saveHandler)}
                    >
                        {translate("sentence", "SAVE")}
                    </FormButton>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    cancelButtonContainer: {
        marginLeft: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    saveButtonContainer: {
        marginRight: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    container: {
        // minWidth: Spacing.screen.minWidth,
        maxWidth: Spacing.screen.maxWidth,
    },
    inputsContainer: {
        marginTop: 20,
    },
    backButton: {
        alignSelf: "start",
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
});

export default VerifyEmailScreen;
