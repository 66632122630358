import { Views } from '../../styles';

import { StyledView } from './Styled';

const FluidView = (props) => {
    return (
        <StyledView
            justifyContent={Views.fluidView.justifyContent}
            alignItems={Views.fluidView.alignItems}
            width={Views.fluidView.width}
            {...props}
        >
            {props.children}
        </StyledView>
    );
};

export default FluidView;
