import { StyleSheet, View } from 'react-native';

const getStylesFromProps = ({
    flex,
    flexDirection,
    justifyContent,
    alignItems,
    width,
    height
}) => {
    const styles = { view: {} };

    if (typeof flex !== 'undefined') {
        styles.view.flex = flex;
    }

    if (typeof flexDirection !== 'undefined') {
        styles.view.flexDirection = flexDirection;
    }

    if (typeof justifyContent !== 'undefined') {
        styles.view.justifyContent = justifyContent;
    }

    if (typeof alignItems !== 'undefined') {
        styles.view.alignItems = alignItems;
    }

    if (typeof width !== 'undefined') {
        styles.view.width = width;
    }

    if (typeof height !== 'undefined') {
        styles.view.height = height;
    }

    return styles;
};

const StyledView = (props) => {
    const { view: viewStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        view: {
            ...viewStyle,
            ...StyleSheet.flatten(props.style)
        }
    });

    return (
        <View {...props} style={styles.view}>
            {props.children}
        </View>
    );
};

export default StyledView;
