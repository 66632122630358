import { StyleSheet, View } from 'react-native';
import Checkbox from 'expo-checkbox';

import { Colors, Spacing, Typography } from '../../../styles';

import StyledText from './StyledText';

const getStylesFromProps = ({ labelPosition, disabled }) => {
    const styles = { container: {}, checkbox: {}, label: {} };

    if (labelPosition === 'left') {
        styles.container.flexDirection = 'row-reverse';
        styles.label.marginRight = Spacing.checkboxLabel.marginRight;
    } else {
        styles.container.flexDirection = 'row';
        styles.label.marginLeft = Spacing.checkboxLabel.marginLeft;
    }

    if (!!disabled) {
        styles.checkbox.opacity = Colors.checkbox.opacity;
        styles.label.opacity = Colors.checkboxLabel.opacity;
    }

    return styles;
};

const StyledCheckbox = (props) => {
    const { color, label } = props;

    const {
        container: containerStyle,
        checkbox: checkboxStyle,
        label: labelStyle
    } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            ...containerStyle,
            ...StyleSheet.flatten(props.style)
        },
        checkbox: {
            ...checkboxStyle,
            ...props.checkboxStyle
        },
        label: {
            ...labelStyle,
            ...props.labelStyle
        }
    });

    return (
        <View style={styles.container}>
            <Checkbox
                {...props}
                color={Colors.app[color] ?? color}
                style={styles.checkbox}
            />
            {label && (
                <StyledText
                    font={props.labelFont ?? Typography.checkboxLabel.fontFamily}
                    size={props.labelSize ?? Typography.checkboxLabel.fontSize}
                    color={props.labelColor ?? Typography.checkboxLabel.color}
                    style={styles.label}
                >
                    {label}
                </StyledText>
            )}
        </View>
    );
};

export default StyledCheckbox;
