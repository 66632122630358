import { Platform, StyleSheet } from "react-native";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";

import { Colors, RegularExpressions } from "../../constants";

import { useLanguage } from "../../hooks";

import { FluidView, Field, RequiredIcon } from "../UI";
import FormTextInput from "./FormTextInput";
import FormButton from "./FormButton";

const ProfileForm = (props) => {
    const { isLoading } = props;
    const userState = useSelector((state) => state.user);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: {
            first_name: userState.firstName ?? "",
            last_name: userState.lastName ?? "",
            phone_number: userState.phoneNumber ?? "",
            address: userState.address ?? "",
            rfc: userState.rfc ?? "",
        },
    });
    const { translate } = useLanguage();

    return (
        <FluidView
            justifyContent="flex-start"
            alignItems="center"
            style={{ ...styles.container, ...StyleSheet.flatten(props.style) }}
        >
            <FluidView>
                <Controller
                    control={control}
                    name="first_name"
                    rules={{ required: true }}
                    style={styles.extrasContainer}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.first_name?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_YOUR_FIRST_NAME")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "NAME")} <RequiredIcon />
                                </>
                            }
                            labelColor={errors.first_name ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.first_name ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder={translate("word", "NAME")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="last_name"
                    rules={{ required: true }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.last_name?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_YOUR_LAST_NAME")
                                    : ""
                            }
                            title={
                                <>
                                    {translate("word", "LASTNAME")} <RequiredIcon />
                                </>
                            }
                            labelColor={errors.last_name ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.last_name ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder={translate("word", "LASTNAME")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="phone_number"
                    rules={{ required: false, pattern: RegularExpressions.PHONE_NUMBER }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.phone_number?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_PHONE_NUMBER")
                                    : ""
                            }
                            title={translate("word", "PHONE_NUMBER")}
                            labelColor={errors.phone_number ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.phone_number ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                keyboardType="number-pad"
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder={translate("word", "PHONE_NUMBER")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="address"
                    rules={{ required: false, maxLength: 240 }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.address?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.address?.type === "maxLength"
                                    ? translate("sentence", "MAX_LENGTH_240")
                                    : ""
                            }
                            title={translate("word", "ADDRESS")}
                            labelColor={errors.address ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.address ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder={translate("word", "ADDRESS")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
                <Controller
                    control={control}
                    name="rfc"
                    rules={{ required: false, pattern: RegularExpressions.RFC }}
                    render={({ field: { value, onChange, onBlur } }) => (
                        <Field
                            label={
                                errors.rfc?.type === "required"
                                    ? translate("sentence", "PLEASE_ENTER_THIS_FIELD")
                                    : errors.rfc?.type === "pattern"
                                    ? translate("sentence", "PLEASE_ENTER_A_VALID_RFC")
                                    : ""
                            }
                            title={translate("word", "RFC")}
                            labelColor={errors.rfc ? "danger" : undefined}
                            style={styles.formField}
                        >
                            <FormTextInput
                                inputType={errors.rfc ? "danger" : ""}
                                iconName="edit"
                                iconType="FontAwesome"
                                returnKeyType="next"
                                autoCorrect={false}
                                autoCapitalize="none"
                                placeholder={translate("word", "RFC")}
                                value={value}
                                onChangeText={onChange}
                                onBlur={onBlur}
                            />
                        </Field>
                    )}
                />
            </FluidView>
            <FormButton
                disabled={isLoading}
                isLoading={isLoading}
                style={styles.formButton}
                onPress={handleSubmit(props.onSubmit)}
            >
                {translate("sentence", "SAVE")}
            </FormButton>
        </FluidView>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingBottom: 30,
        borderBottomWidth: 1,
        borderColor: Colors.SHADOW_GRAY,
    },
    formField: {
        marginBottom: 10,
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    extrasContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
    },
});

export default ProfileForm;
