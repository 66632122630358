const DIGITS = /^[0-9]+$/;

const DIGITS_FLOAT = /^([0-9]*[.])?[0-9]+$/;

const ALPHABETIC_CHARACTERS = /^[a-zA-Z]+$/;

const ALPHA_NUMERIC_CHARACTERS = /^[a-zA-Z0-9]+$/;

const DATE =
    /^(0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2}$/;

const MONTH = /^(0[1-9]|1[012])$/;

const EMAIL = /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;

const PHONE_NUMBER = /^[0-9]{10}$/;

const PASSWORD =
    /^.*(?=.{8,})(?=.*d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*? ]).*$/;

const COMPLEX_PASSWORD =
    /^(?=(.*[0-9]))(?=.*[\!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}$/;

const MODERATE_PASSWORD =
    /^(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/;

const SLUG = /^[a-z0-9-]+$/;

const URL =
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;

const IP_ADDRESS =
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

const STRING_TRIMMING = /^s*|s*$/g;

const SPECIAL_CHARACTERS_SCAPED = /[\-\[\]\/\\\{\}\(\)\*\+\?\.\^\$\|]/;

const MASTER_CARD =
    /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/;

const VISA_CARD = /^4[0-9]{12}(?:[0-9]{3})?$/;

const VISA_MASTER_CARD = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;

const RFC =/^[ña-z]{3,4}[0-9]{6}[0-9a-z]{3}$/i;

const RECOVERY_CODE = /^\d{6}$/;

const VERIFICATION_CODE = /^\d{6}$/;

export {
    DIGITS,
    DIGITS_FLOAT,
    ALPHABETIC_CHARACTERS,
    ALPHA_NUMERIC_CHARACTERS,
    DATE,
    MONTH,
    EMAIL,
    PHONE_NUMBER,
    PASSWORD,
    COMPLEX_PASSWORD,
    MODERATE_PASSWORD,
    SLUG,
    URL,
    IP_ADDRESS,
    STRING_TRIMMING,
    SPECIAL_CHARACTERS_SCAPED,
    MASTER_CARD,
    VISA_CARD,
    VISA_MASTER_CARD,
    RFC,
    RECOVERY_CODE,
    VERIFICATION_CODE
};

export default {
    DIGITS,
    DIGITS_FLOAT,
    ALPHABETIC_CHARACTERS,
    ALPHA_NUMERIC_CHARACTERS,
    DATE,
    MONTH,
    EMAIL,
    PHONE_NUMBER,
    PASSWORD,
    COMPLEX_PASSWORD,
    MODERATE_PASSWORD,
    SLUG,
    URL,
    IP_ADDRESS,
    STRING_TRIMMING,
    SPECIAL_CHARACTERS_SCAPED,
    MASTER_CARD,
    VISA_CARD,
    VISA_MASTER_CARD,
    RFC,
    RECOVERY_CODE,
    VERIFICATION_CODE
};
