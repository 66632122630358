import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Platform, Image, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { Colors, Spacing, Typography } from '../../styles';

import ProjectScreen from '../../screens/projects/ProjectsScreen';
import DetailsScreen from '../../screens/projects/DetailsScreen';

const Stack = createStackNavigator();

const ProjectNavigator = ({navigation}) => {

    const accessToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (!accessToken){
                navigation.navigate('AuthNav');
            }
        });
    
        return unsubscribe;
    }, [navigation]);

    return (
        <Stack.Navigator screenOptions={{ headerShown: false }} >
            <Stack.Screen name="Projects" component={ProjectScreen} />
            <Stack.Screen name="Details" component={DetailsScreen} />
        </Stack.Navigator>
    );
};

export default ProjectNavigator;
