import { API_BASE_URL_ENV, API_VERSION_ENV, API_TIMEOUT_ENV, API_ACCEPT_ENV } from "@env"

console.log(API_BASE_URL_ENV);
const API_BASE_URL = API_BASE_URL_ENV ?? 'https://api.numma.emkode.org';
const API_VERSION = API_VERSION_ENV ?? 'v1';
const API_TIMEOUT = API_TIMEOUT_ENV ?? '10000';
const API_ACCEPT = API_ACCEPT_ENV ?? 'application/json';

export { API_BASE_URL, API_VERSION, API_TIMEOUT, API_ACCEPT };

export default { API_BASE_URL, API_VERSION, API_TIMEOUT, API_ACCEPT };