import Model from './Model';

export default class User extends Model {
    boot() {
        super.boot();

        this.modelName = 'Users';

        this.config.customRoutes = [
            {
                name: 'me',
                type: 'get',
                url: 'users/me/'
            },
            {
                name: 'updateMe',
                type: 'patch',
                url: 'users/me/'
            },
            {
                name: 'updateMyPassword',
                type: 'patch',
                url: 'users/me/password/'
            },
            {
                name: 'downloadExampleFile',
                type: 'get',
                url: 'update-prices/'
            },
            {
                name: 'sendPricesFile',
                type: 'patch',
                url: 'update-prices/'
            }
        ];
    }

    me = () => {
        return this.route('me');
    };

    updateMe = (params) => {
        return this.withParams(params).route('updateMe');
    };

    updateMyPassword = (params) => {
        return this.withParams(params).route('updateMyPassword');
    };

    downloadExampleFile = () => {
        return this.route('downloadExampleFile');
    };

    sendPricesFile = (params) => {
        return this.withParams(params).route('sendPricesFile');
    };
}
