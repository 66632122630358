import { StyleSheet, View, Switch } from "react-native";

import { Colors, Spacing, Typography } from "../../../styles";

import StyledText from "./StyledText";

const getStylesFromProps = ({ labelPosition, disabled }) => {
    const styles = { container: {}, toggle: {}, label: {} };

    if (labelPosition === "left") {
        styles.container.flexDirection = "row-reverse";
        styles.label.marginRight = Spacing.toggleLabel.marginRight;
    } else {
        styles.container.flexDirection = "row";
        styles.label.marginLeft = Spacing.toggleLabel.marginLeft;
    }

    if (!!disabled) {
        styles.toggle.opacity = Colors.toggle.opacity;
        styles.label.opacity = Colors.toggleLabel.opacity;
    }

    return styles;
};

const StyledSwitch = (props) => {
    const { label } = props;

    const { container: containerStyle, toggle: toggleStyle, label: labelStyle } = getStylesFromProps(props);

    const styles = StyleSheet.create({
        container: {
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            ...containerStyle,
            ...StyleSheet.flatten(props.style),
        },
        toggle: {
            ...toggleStyle,
            ...props.toggleStyle,
        },
        label: {
            ...labelStyle,
            ...props.labelStyle,
        },
    });

    return (
        <View style={styles.container}>
            <Switch
                trackColor={{
                    false: Colors.toggle.trackColor.false,
                    true: Colors.toggle.trackColor.true,
                }}
                thumbColor={Colors.toggle.thumbColor}
                {...props}
                style={styles.toggle}
            />
            {label && (
                <StyledText
                    font={props.labelFont ?? Typography.toggleLabel.fontFamily}
                    size={props.labelSize ?? Typography.toggleLabel.fontSize}
                    color={props.labelColor ?? Typography.toggleLabel.color}
                    style={styles.label}
                >
                    {label}
                </StyledText>
            )}
        </View>
    );
};

export default StyledSwitch;
