import { StyleSheet } from "react-native";

import { StyledIcon } from "./Styled";

const RequiredIcon = (props) => {
    return <StyledIcon name="asterisk" iconType="FontAwesome" size={8} color="red" style={styles.requiredIcon} />;
};

const styles = StyleSheet.create({
    requiredIcon: {
        textAlignVertical: "top",
    },
});

export default RequiredIcon;
