import { useState } from "react";
import { StyleSheet, useWindowDimensions, Pressable } from "react-native";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { auth } from "../../models";

import { Wrapper, Screen, Container, FluidView, LoadingModal, Paragraph, H3, Card } from "../../components/UI";
import { StyledModal, StyledText } from "../../components/UI/Styled";
import FormButton from "../../components/Auth/FormButton";

const VerificationEmailScreen = ({ navigation, route }) => {
    const email = route.params?.email ?? "";

    const [isLoading, setIsLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [error, setError] = useState(null);

    const layout = useWindowDimensions();
    const isWeb = layout.width > 768;

    const { translate } = useLanguage();

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const resendHandler = async () => {
        try {
            toggleIsLoading();

            await auth.sendVerificationEmail({ email });

            toggleIsLoading();

            setAlert(translate("sentence", "VERIFICATION_EMAIL_HAS_BEEN_RESENT"));
        } catch (e) {
            toggleIsLoading();

            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
        }
    };

    const loginHandler = () => {
        navigation.navigate("SignIn");
    };

    return (
        <Wrapper navbar={false}>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!alert}
                size="medium"
                title={translate("word", "INFO")}
                titleColor="info"
                onAccept={removeAlert}
                onPressOut={removeAlert}
                onRequestClose={removeAlert}
            >
                {alert}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen style={!isWeb ? styles.screenMobile : undefined}>
                <Container style={styles.container}>
                    <Card style={!isWeb ? styles.cardMobile : undefined}>
                        <Container>
                            <H3>{translate("sentence", "VERIFY_YOUR_ACCOUNT")}</H3>
                        </Container>
                        <Container flex={!isWeb ? 12 : 6} style={styles.verificationEmailContainer}>
                            <FluidView>
                                <Paragraph>
                                    {`${translate("sentence", "WE_HAVE_SENT_AN_EMAIL_TO")} `}
                                    <StyledText font="bold" color="primary">
                                        {email}
                                    </StyledText>
                                    {`\n\n${translate("sentence", "YOU_NEED_TO_VERIFY_YOUR_ACCOUNT_TO_SIGN_IN")}`}
                                    {`\n\n${translate("sentence", "IF_YOU_HAVE_NOT_RECEIVED_THE_VERIFICATION_EMAIL")}`}
                                </Paragraph>
                            </FluidView>
                            <FormButton style={styles.resendButtonContainer} onPress={resendHandler}>
                                {translate("verb", "RESEND")}
                            </FormButton>
                        </Container>
                        <Container flex={!isWeb ? 2 : 1}>
                            <FluidView justifyContent="flex-start" alignItems="center" style={styles.extrasContainer}>
                                <StyledText font="bold" size={16} align="center">
                                    {translate("sentence", "ALREADY_REGISTERED")}
                                </StyledText>
                                <Pressable onPress={loginHandler}>
                                    <StyledText size={15} color="primary">
                                        &nbsp;{translate("sentence", "SIGN_IN")}
                                    </StyledText>
                                </Pressable>
                            </FluidView>
                        </Container>
                    </Card>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    verificationEmailContainer: {
        width: "90%",
        maxWidth: Spacing.screen.maxWidth - 40,
        marginTop: 20,
    },
    resendButtonContainer: {
        marginTop: 20,
    },
    alreadyRegisteredContainer: {
        marginTop: 10,
    },
    signInButtonContainer: {
        marginTop: 12,
    },
    extrasContainer: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 20,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    cardMobile: {
        marginTop: 30,
    },
});

export default VerificationEmailScreen;
