import { useState, useEffect } from "react";
import { StyleSheet, useWindowDimensions, Pressable, Linking, ScrollView } from "react-native";
import { useSelector, useDispatch } from "react-redux";

import { Table, TableWrapper, Row, Rows, Col } from "react-native-table-component";

import { Colors, Fonts } from "../../constants";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import { Wrapper, Screen, Container, LoadingModal, Card, H1, H4, H3, H5, ViewBackButton } from "../../components/UI";
import { StyledView, StyledText, StyledButton, StyledIcon, StyledModal } from "../../components/UI/Styled";
import ProjectForm from "../../components/Projects/ProjectForm";
import FormProduct from "../../components/Projects/FormProduct";
import CardProductMobile from "../../components/Projects/CardProductMobile";
import Project from "../../models/Project";
import Persiana from "../../models/Persiana";
import Toldo90 from "../../models/Toldo90";
import ToldoVertical from "../../models/ToldoVertical";
import ToldoProyeccion from "../../models/ToldoProyeccion";
import Cortina from "../../models/Cortina";
import { autoLogin, refreshToken, logout } from "../../store/actions/auth";

const DetailsScreen = ({ navigation, route }) => {
    const userAuth = useSelector((state) => state.auth);
    const accessToken = useSelector((state) => state.auth.token);
    const [isLoading, setIsLoading] = useState(false);
    const [dataProject, setDataProject] = useState({});
    const [dataTablePersiana, setDataTablePersiana] = useState([]);
    const [dataTableT90, setDataTableT90] = useState([]);
    const [dataTableTvertical, setDataTableTvertical] = useState([]);
    const [dataTableTproyeccion, setDataTableTproyeccion] = useState([]);
    const [dataTableCortina, setDataTableCortina] = useState([]);
    const [error, setError] = useState(null);
    const [alert, setAlert] = useState(null);
    const [updateModal, setUpdateModal] = useState(null);
    const [createModal, setCreateModal] = useState(null);
    const [updateProductModal, setUpdateProductModal] = useState(null);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [additionals, setAdditionals] = useState([]);
    const [additionalsProject, setAdditionalsProject] = useState([]);
    const [dataTableAdditionals, setDataTableAdditionals] = useState([]);
    const [totalAdditional, setTotalAdditional] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);
    var modelsTVertical = [];
    const [deleteProductModal, setDeleteProductModal] = useState(null);
    const dispatch = useDispatch();

    var project = new Project({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var persiana = new Persiana({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldo90 = new Toldo90({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldoVertical = new ToldoVertical({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var toldoProyeccion = new ToldoProyeccion({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    var cortina = new Cortina({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const layout = useWindowDimensions();
    const isMobile = layout.width < 768;

    const { translate } = useLanguage();

    const INSTALLATIONS_CHOICES = [translate("word", "WALL"), translate("word", "CEILING")];
    const CONTROL_SIDE_CHOICES = [
        "N/A",
        translate("word", "LEFT"),
        translate("word", "RIGHT"),
        translate("word", "RIGHTLEFT"),
    ];
    const PROJECTION_CHOICES_T90 = ["1.00", "1.40"];
    const PROJECTION_CHOICES_TPROJECTION = ["1.50", "2.00", "2.50", "3.00", "3.50", "4.00"];

    const removeAlert = () => {
        setAlert(null);
    };

    const removeError = () => {
        setError(null);
    };

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const toggleUpdateModal = () => {
        setUpdateModal((prevIsLoading) => !prevIsLoading);
    };

    const toggleCreateModal = () => {
        setCreateModal((prevIsLoading) => !prevIsLoading);
        setAdditionals([]);
    };

    const toggleUpdateProductModal = () => {
        setUpdateProductModal((prevIsLoading) => !prevIsLoading);
    };

    const toggleDeleteProductModal = () => {
        setDeleteProductModal((prevIsLoading) => !prevIsLoading);
    };

    const formatter = new Intl.NumberFormat("es-MX", {
        style: "currency",
        currency: "MXN",
    });

    const showUpdateModal = (type, data) => {
        switch (type) {
            case "1":
                setAdditionals(data.additionals_persiana);
                break;
            case "2":
                setAdditionals(data.additionals_t90);
                break;
            case "3":
                setAdditionals(data.additionals_tvertical);
                break;
            case "4":
                setAdditionals(data.additionals_tproyeccion);
                break;
            case "5":
                setAdditionals(data.additionals_cortina);
                break;

            default:
                break;
        }
        setSelectedProduct({ type, ...data });
        toggleUpdateProductModal();
    };

    const showDeleteModal = (type, data) => {
        setSelectedProduct({ type, id: data.id });
        toggleDeleteProductModal();
    };

    const fetchDataDetail = async () => {
        setIsLoading(true);
        try {
            var result = await toldoVertical.data();
            var options = result.data.map((e) => {
                return e.model;
            });
            modelsTVertical = options;
            if (route.params?.project_id) {
                result = await project.fetch(route.params?.project_id);
                setDataProject(result.data);

                generateTable(result.data);
                calcualteTotalProductsCost(result.data);
                setAdditionalsProject(result.data.additionals_project);
                tableDataAdditionals(result.data.additionals_project);
            }
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const downloadProject = async (client) => {
        setIsLoading(true);
        try {
            var project_id = route.params?.project_id;
            var url;
            if (client) {
                url =
                    project.config.baseURL +
                    "/" +
                    project.customRoutes.generateQuotePdf.url.replace("{id}", project_id);
            } else {
                url = project.config.baseURL + "/" + project.customRoutes.generatePdf.url.replace("{id}", project_id);
            }
            var token = accessToken;
            try {
                await dispatch(autoLogin(accessToken));
            } catch (e) {
                try {
                    var response = await dispatch(refreshToken(userAuth.expirationTime, userAuth.refresh));
                    token = response;
                } catch (error) {
                    await dispatch(logout());
                }
            }
            Linking.openURL(url + "?auth_token=" + token).catch((err) =>
                console.error("Ocurrio un error intente más tarde", err)
            );
            setIsLoading(false);
        } catch (e) {
            console.log("error", e);
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const tableHeadPersiana = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "MODEL"),
        translate("word", "COLOR"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const tableHeadT90 = [
        translate("word", "WIDTH"),
        translate("word", "PROJECTION"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const tableHeadTvertical = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "MODEL"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const tableHeadTproyeccion = [
        translate("word", "WIDTH"),
        translate("word", "PROJECTION"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];
    const tableHeadCortina = [
        translate("word", "WIDTH"),
        translate("word", "HEIGTH"),
        translate("word", "CONFECTION"),
        translate("word", "MODEL"),
        translate("word", "COLOR"),
        translate("word", "CONTROL_SIDE"),
        translate("word", "ROLL_WIDTH"),
        translate("word", "INSTALLATION"),
        translate("word", "TOTAL_COST"),
        translate("word", "OPTIONS"),
    ];

    const tableHeadAdditionals = [translate("word", "NAME"), translate("word", "COST")];

    const tableDataAdditionals = (additionals) => {
        var additionalsCost = additionals.reduce(
            (sum, e) => sum + (isNaN(parseFloat(e.cost)) ? 0 : parseFloat(e.cost)),
            0
        );
        setTotalAdditional(additionalsCost);
        setDataTableAdditionals(additionals.map((e) => [e.name, formatter.format(e.cost)]));
    };

    const calcualteTotalProductsCost = (datas) => {
        var additionalsCost = 0;
        if (datas && datas.persiana_project) {
            additionalsCost += datas.persiana_project.reduce(
                (sum, e) => sum + (isNaN(parseFloat(e.total_cost)) ? 0 : parseFloat(e.total_cost)),
                0
            );
        }
        if (datas && datas.t90_project) {
            additionalsCost += datas.t90_project.reduce(
                (sum, e) => sum + (isNaN(parseFloat(e.total_cost)) ? 0 : parseFloat(e.total_cost)),
                0
            );
        }
        if (datas && datas.tvertical_project) {
            additionalsCost += datas.tvertical_project.reduce(
                (sum, e) => sum + (isNaN(parseFloat(e.total_cost)) ? 0 : parseFloat(e.total_cost)),
                0
            );
        }
        if (datas && datas.tproyeccion_project) {
            additionalsCost += datas.tproyeccion_project.reduce(
                (sum, e) => sum + (isNaN(parseFloat(e.total_cost)) ? 0 : parseFloat(e.total_cost)),
                0
            );
        }
        if (datas && datas.cortina_project) {
            additionalsCost += datas.cortina_project.reduce(
                (sum, e) => sum + (isNaN(parseFloat(e.total_cost)) ? 0 : parseFloat(e.total_cost)),
                0
            );
        }
        setTotalProducts(additionalsCost);
    };

    const buttonsTable = (name, data, type, status) => {
        var buttons =
            status == 0 ? (
                <StyledView
                    style={isMobile && styles.containerTextMobile}
                    flexDirection={!isMobile ? "row" : "colum"}
                    justifyContent="space-between"
                    alignItems={!isMobile ? "center" : "start"}
                >
                    <StyledButton
                        style={!isMobile ? styles.tableButtonContainer : styles.tableButtonContainerMobile}
                        buttonType="white"
                        textSize={!isMobile ? 16 : 14}
                        textFont="regular"
                        textColor={"nummaBlue"}
                        onPress={() => {
                            showUpdateModal(type, data);
                        }}
                    >
                        {type != "5" && translate("sentence", "EDIT")}{" "}
                        {type == "5" && isMobile && translate("sentence", "EDIT")}{" "}
                        <StyledIcon name="edit" iconType="FontAwesome" size={isMobile && 14} color="nummaBlue" />
                    </StyledButton>
                    <StyledButton
                        style={!isMobile ? styles.tableButtonContainer : styles.tableButtonContainerMobile}
                        buttonType="white"
                        textSize={!isMobile ? 16 : 14}
                        textFont="regular"
                        textColor="nummaBlue"
                        onPress={() => {
                            showDeleteModal(type, data);
                        }}
                    >
                        {isMobile && translate("verb", "DELETE")}{" "}
                        <StyledIcon name="trash" iconType="FontAwesome" size={isMobile && 14} color="nummaBlue" />
                    </StyledButton>
                </StyledView>
            ) : (
                <StyledView alignItems="center">
                    <StyledButton
                        style={!isMobile ? styles.tableButtonContainer : styles.tableButtonContainerMobile}
                        buttonType="white"
                        textSize={!isMobile ? 16 : 14}
                        textFont="regular"
                        textColor={"nummaBlue"}
                        onPress={() => {
                            showUpdateModal(type, data);
                        }}
                    >
                        {translate("sentence", "SEE")}{" "}
                        <StyledIcon name="eye" iconType="FontAwesome" color="nummaBlue" />
                    </StyledButton>
                </StyledView>
            );
        switch (type) {
            case "1":
                return [
                    data.width,
                    data.heigth,
                    data.model,
                    data.color,
                    CONTROL_SIDE_CHOICES[data.control_side],
                    INSTALLATIONS_CHOICES[data.type_installation],
                    formatter.format(data.total_cost),
                    buttons,
                ];
                break;
            case "2":
                return [
                    data.width,
                    PROJECTION_CHOICES_T90[data.projection],
                    CONTROL_SIDE_CHOICES[data.control_side],
                    INSTALLATIONS_CHOICES[data.type_installation],
                    formatter.format(data.total_cost),
                    buttons,
                ];
                break;
            case "3":
                return [
                    data.width,
                    data.heigth,
                    modelsTVertical[data.tvertical_data],
                    CONTROL_SIDE_CHOICES[data.control_side],
                    INSTALLATIONS_CHOICES[data.type_installation],
                    formatter.format(data.total_cost),
                    buttons,
                ];
                break;
            case "4":
                return [
                    data.width,
                    PROJECTION_CHOICES_TPROJECTION[data.projection],
                    CONTROL_SIDE_CHOICES[data.control_side],
                    INSTALLATIONS_CHOICES[data.type_installation],
                    formatter.format(data.total_cost),
                    buttons,
                ];
                break;
            case "5":
                return [
                    data.width,
                    data.heigth,
                    data.confection,
                    data.model,
                    data.color,
                    CONTROL_SIDE_CHOICES[data.control_side],
                    data.roll_width,
                    INSTALLATIONS_CHOICES[data.type_installation],
                    formatter.format(data.total_cost),
                    buttons,
                ];
                break;

            default:
                break;
        }
    };

    const generateTable = (datas) => {
        var results = [];
        var resultsTem = [];
        if (datas && datas.persiana_project && datas.persiana_project.length !== 0) {
            resultsTem = datas.persiana_project.map((result) => {
                return buttonsTable("Persiana", result, "1", datas.status);
            });
            setDataTablePersiana(resultsTem);
            if (resultsTem.length) {
                results.push(...resultsTem);
            }
        }
        if (datas && datas.t90_project && datas.t90_project.length !== 0) {
            resultsTem = datas.t90_project.map((result) => {
                return buttonsTable("Toldo 90", result, "2", datas.status);
            });
            setDataTableT90(resultsTem);
            if (resultsTem.length) {
                results.push(...resultsTem);
            }
        }
        if (datas && datas.tvertical_project && datas.tvertical_project.length !== 0) {
            resultsTem = datas.tvertical_project.map((result) => {
                return buttonsTable("Toldo vertical", result, "3", datas.status);
            });
            setDataTableTvertical(resultsTem);
            if (resultsTem.length) {
                results.push(...resultsTem);
            }
        }
        if (datas && datas.tproyeccion_project && datas.tproyeccion_project.length !== 0) {
            resultsTem = datas.tproyeccion_project.map((result) => {
                return buttonsTable("Toldo de proyeccion", result, "4", datas.status);
            });
            setDataTableTproyeccion(resultsTem);
            if (resultsTem.length) {
                results.push(...resultsTem);
            }
        }
        if (datas && datas.cortina_project && datas.cortina_project.length !== 0) {
            resultsTem = datas.cortina_project.map((result) => {
                return buttonsTable("Cortina", result, "5", datas.status);
            });
            setDataTableCortina(resultsTem);
            if (resultsTem.length) {
                results.push(...resultsTem);
            }
        }
    };

    const fetchUpdateProject = async (data) => {
        toggleIsLoading();
        try {
            if (data.status != undefined) {
                data.status = data.status ? 0 : 1;
            }
            data.additionals_project = additionalsProject;
            const result = await project.update(route.params?.project_id, data);
            toggleUpdateModal();
            toggleIsLoading();
            setAlert(translate("sentence", "SAVED"));
            fetchDataDetail();
        } catch (e) {
            toggleUpdateModal();
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const confirmDelete = async () => {
        try {
            setIsLoading(true);
            toggleDeleteProductModal();
            switch (selectedProduct.type) {
                case "1":
                    await persiana.delete(selectedProduct.id);
                    break;
                case "2":
                    await toldo90.delete(selectedProduct.id);
                    break;
                case "3":
                    await toldoVertical.delete(selectedProduct.id);
                    break;
                case "3":
                    await toldoVertical.delete(selectedProduct.id);
                    break;
                case "4":
                    await toldoProyeccion.delete(selectedProduct.id);
                    break;
                case "5":
                    await cortina.delete(selectedProduct.id);
                    break;

                default:
                    break;
            }
            fetchDataDetail();
        } catch (e) {
            setIsLoading(false);
            setError(translate("sentence", "SOMETHING_WENT_WRONG"));
            dispatch(appActions.notReady());
        }
    };

    const totalDisplay = () => {
        var subtotal = totalAdditional + totalProducts;
        var total = subtotal;
        var iva = 0;
        var totalIva = subtotal;
        var final = subtotal;
        if (dataProject.discount) {
            total = subtotal - (subtotal * dataProject.discount) / 100;
            final = total;
        }
        if (dataProject.iva) {
            iva = total * 0.16;
            totalIva = total + iva;
            final = totalIva;
        }
        return (
            <StyledView style={styles.containerAdditionals} alignItems="end">
                {dataProject.discount ? (
                    <>
                        <StyledView style={styles.rowTotal}>
                            <H5>Subtotal: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(subtotal)}</H5>
                        </StyledView>
                        <StyledView style={styles.rowTotal}>
                            <H5>Descuento: </H5>
                            <H5 style={styles.marginLeftTotal}>%{dataProject.discount}</H5>
                        </StyledView>
                        <StyledView style={styles.rowTotal}>
                            <H5>Total: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(total)}</H5>
                        </StyledView>
                    </>
                ) : (
                    <StyledView style={styles.rowTotal}>
                        <H5>Total: </H5>
                        <H5 style={styles.marginLeftTotal}>{formatter.format(total)}</H5>
                    </StyledView>
                )}
                {dataProject.iva && (
                    <>
                        <StyledView style={styles.rowTotal}>
                            <H5>Iva: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(iva)}</H5>
                        </StyledView>
                        <StyledView style={styles.rowTotal}>
                            <H5>Total + IVA: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(totalIva)}</H5>
                        </StyledView>
                    </>
                )}
                {dataProject.advance && (
                    <>
                        <StyledView style={styles.rowTotal}>
                            <H5>Anticipo: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(dataProject.advance)}</H5>
                        </StyledView>
                        <StyledView style={styles.rowTotal}>
                            <H5>Total - Anticipo: </H5>
                            <H5 style={styles.marginLeftTotal}>{formatter.format(final - dataProject.advance)}</H5>
                        </StyledView>
                    </>
                )}
            </StyledView>
        );
    };
    useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            if (!!accessToken) {
                toggleIsLoading();
                fetchDataDetail();
            }
        });
        return unsubscribe;
    }, [navigation]);

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!alert}
                size="medium"
                title={translate("word", "INFO")}
                titleColor="info"
                onAccept={removeAlert}
                onPressOut={removeAlert}
                onRequestClose={removeAlert}
            >
                {alert}
            </StyledModal>
            <StyledModal
                modalType="alert"
                visible={!!deleteProductModal}
                size="medium"
                title={translate("word", "Alerta")}
                titleColor="danger"
                onAccept={confirmDelete}
                onPressOut={toggleDeleteProductModal}
                onRequestClose={toggleDeleteProductModal}
            >
                <StyledView style={styles.container100} alignItems="end">
                    <Pressable onPress={toggleDeleteProductModal}>
                        <StyledIcon name="close" iconType="FontAwesome" color={Colors.DARK_GRAY} />
                    </Pressable>
                </StyledView>
                {translate("sentence", "ARE_YOU_SURE_YOU_WANT_DELETE_PRODUCT")}
            </StyledModal>
            <StyledModal
                visible={!!updateModal}
                size={!isMobile ? "web" : "large"}
                title={translate("sentence", "UPDATE_PROJECT")}
                cardStyle={
                    !isMobile
                        ? { maxWidth: "50%", height: "80%" }
                        : { height: "85%", marginTop: "auto", backgroundColor: Colors.LIGHT_GRAY }
                }
                onAccept={toggleUpdateModal}
                onRequestClose={toggleUpdateModal}
            >
                <StyledView style={styles.container100} alignItems="end">
                    <Pressable onPress={toggleUpdateModal}>
                        <StyledIcon name="close" iconType="FontAwesome" color={Colors.DARK_GRAY} />
                    </Pressable>
                </StyledView>
                <H3>{translate("sentence", "UPDATE_PROJECT")}</H3>
                <ScrollView style={styles.containerForm}>
                    <ProjectForm
                        update={true}
                        isLoading={isLoading}
                        project={dataProject}
                        onSubmit={fetchUpdateProject}
                        isActive={dataProject.status == 0}
                        {...{ additionalsProject, setAdditionalsProject, setTotalAdditional }}
                    />
                </ScrollView>
            </StyledModal>
            <FormProduct
                visible={createModal}
                toggle={toggleCreateModal}
                project_id={route.params?.project_id}
                {...{ isLoading, setIsLoading, setError, setAlert, fetchDataDetail, additionals, setAdditionals }}
            />
            <FormProduct
                selectedProduct={selectedProduct}
                isActive={dataProject.status}
                visible={updateProductModal}
                toggle={toggleUpdateProductModal}
                project_id={route.params?.project_id}
                {...{ isLoading, setIsLoading, setError, setAlert, fetchDataDetail, additionals, setAdditionals }}
            />
            <Screen style={!isMobile ? styles.screen : styles.screenMobile}>
                <Container>
                    <ViewBackButton
                        navigation={navigation}
                        mainPage={"Projects"}
                        textStatus={
                            dataProject.status == 0 ? translate("verb", "ACTIVES") : translate("verb", "HISTORIC")
                        }
                        textPage="Proyectos"
                    />
                    {!isMobile ? (
                        <>
                            <Card size="large" style={styles.titleCard}>
                                <StyledView
                                    style={styles.container100}
                                    flexDirection="row"
                                    justifyContent="space-around"
                                    alignItems="center"
                                >
                                    <StyledView style={styles.title}>
                                        <H1 size={50} color={Colors.BLACK}>
                                            {dataProject.name}
                                        </H1>
                                    </StyledView>
                                    <StyledView>
                                        <StyledView
                                            style={styles.containerInfo}
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <H4 color={Colors.BLACK}>{translate("word", "CLIENT_NAME")}:</H4>
                                            <StyledText style={styles.textInfo}>{dataProject.client_name}</StyledText>
                                        </StyledView>
                                        <StyledView
                                            style={styles.containerInfo}
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <H4 color={Colors.BLACK}>{translate("word", "CLIENT_PHONE")}:</H4>
                                            <StyledText style={styles.textInfo}>{dataProject.client_phone}</StyledText>
                                        </StyledView>
                                        <StyledView
                                            style={styles.containerInfo}
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <H4 color={Colors.BLACK}>{translate("word", "CLIENT_EMAIL")}:</H4>
                                            <StyledText style={styles.textInfo}>{dataProject.client_email}</StyledText>
                                        </StyledView>
                                        <StyledView
                                            style={styles.containerInfo}
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <H4 color={Colors.BLACK}>{translate("word", "DIRECTION")}:</H4>
                                            <StyledText style={styles.textInfo}>
                                                {dataProject.client_street} #{dataProject.client_number}{" "}
                                                {dataProject.client_suburb} {dataProject.client_state}
                                            </StyledText>
                                        </StyledView>
                                        <StyledView
                                            style={styles.containerInfo}
                                            flexDirection="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <H4 color={Colors.BLACK}>{translate("sentence", "ESTATUS")}:</H4>
                                            <StyledText style={styles.textInfo}>
                                                {dataProject.status == 0
                                                    ? translate("verb", "ACTIVE")
                                                    : translate("verb", "FINISHED")}
                                            </StyledText>
                                        </StyledView>
                                    </StyledView>
                                </StyledView>
                                <StyledView style={styles.container100} alignItems="end">
                                    <StyledView flexDirection="row" justifyContent="space-between" alignItems="center">
                                        <StyledButton
                                            style={styles.tableButtonContainer}
                                            buttonType="white"
                                            textSize={16}
                                            textFont="regular"
                                            textColor="nummaBlue"
                                            onPress={() => {
                                                downloadProject(false);
                                            }}
                                        >
                                            {translate("sentence", "DOWNLOAD_PDF")}{" "}
                                            <StyledIcon name="download" iconType="FontAwesome" color="nummaBlue" />
                                        </StyledButton>
                                        <StyledButton
                                            style={styles.tableButtonContainer}
                                            buttonType="white"
                                            textSize={16}
                                            textFont="regular"
                                            textColor="nummaBlue"
                                            onPress={() => {
                                                downloadProject(true);
                                            }}
                                        >
                                            {translate("sentence", "DOWNLOAD_QUOTE_PDF")}{" "}
                                            <StyledIcon name="download" iconType="FontAwesome" color="nummaBlue" />
                                        </StyledButton>
                                        <StyledButton
                                            buttonType="nummaBlue"
                                            textSize={16}
                                            style={styles.addButtonContainer}
                                            onPress={toggleUpdateModal}
                                        >
                                            {translate("verb", "EDIT")}
                                        </StyledButton>
                                    </StyledView>
                                </StyledView>
                            </Card>
                            <Card size="large" style={styles.bodyCard}>
                                {!isLoading && dataTablePersiana.length != 0 && (
                                    <StyledView style={styles.container100}>
                                        <H5>Persiana</H5>
                                        <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                            <Row
                                                data={tableHeadPersiana}
                                                style={StyleSheet.flatten(styles.header)}
                                                textStyle={StyleSheet.flatten(styles.textHeader)}
                                            />
                                            <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                <Rows
                                                    data={dataTablePersiana}
                                                    style={StyleSheet.flatten(styles.row)}
                                                    textStyle={StyleSheet.flatten(styles.text)}
                                                />
                                            </TableWrapper>
                                        </Table>
                                    </StyledView>
                                )}
                                {!isLoading && dataTableT90.length != 0 && (
                                    <StyledView style={styles.container100}>
                                        <H5>Toldo 90</H5>
                                        <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                            <Row
                                                data={tableHeadT90}
                                                style={StyleSheet.flatten(styles.header)}
                                                textStyle={StyleSheet.flatten(styles.textHeader)}
                                            />
                                            <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                <Rows
                                                    data={dataTableT90}
                                                    style={StyleSheet.flatten(styles.row)}
                                                    textStyle={StyleSheet.flatten(styles.text)}
                                                />
                                            </TableWrapper>
                                        </Table>
                                    </StyledView>
                                )}
                                {!isLoading && dataTableTvertical.length != 0 && (
                                    <StyledView style={styles.container100}>
                                        <H5>Toldo vertical</H5>
                                        <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                            <Row
                                                data={tableHeadTvertical}
                                                style={StyleSheet.flatten(styles.header)}
                                                textStyle={StyleSheet.flatten(styles.textHeader)}
                                            />
                                            <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                <Rows
                                                    data={dataTableTvertical}
                                                    style={StyleSheet.flatten(styles.row)}
                                                    textStyle={StyleSheet.flatten(styles.text)}
                                                />
                                            </TableWrapper>
                                        </Table>
                                    </StyledView>
                                )}
                                {!isLoading && dataTableTproyeccion.length != 0 && (
                                    <StyledView style={styles.container100}>
                                        <H5>Toldo de proyeccion</H5>
                                        <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                            <Row
                                                data={tableHeadTproyeccion}
                                                style={StyleSheet.flatten(styles.header)}
                                                textStyle={StyleSheet.flatten(styles.textHeader)}
                                            />
                                            <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                <Rows
                                                    data={dataTableTproyeccion}
                                                    style={StyleSheet.flatten(styles.row)}
                                                    textStyle={StyleSheet.flatten(styles.text)}
                                                />
                                            </TableWrapper>
                                        </Table>
                                    </StyledView>
                                )}
                                {!isLoading && dataTableCortina.length != 0 && (
                                    <StyledView style={styles.container100}>
                                        <H5>Cortina</H5>
                                        <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                            <Row
                                                data={tableHeadCortina}
                                                style={StyleSheet.flatten(styles.header)}
                                                textStyle={StyleSheet.flatten(styles.textHeader)}
                                            />
                                            <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                <Rows
                                                    data={dataTableCortina}
                                                    style={StyleSheet.flatten(styles.row)}
                                                    textStyle={StyleSheet.flatten(styles.text)}
                                                />
                                            </TableWrapper>
                                        </Table>
                                    </StyledView>
                                )}
                                {!isLoading && totalProducts == 0 && (
                                    <StyledText style={styles.centerText}>No hay productos</StyledText>
                                )}
                                {!isLoading && totalProducts != 0 && (
                                    <StyledView style={styles.container100} alignItems="end">
                                        <H5>Total productos: {formatter.format(totalProducts)}</H5>
                                    </StyledView>
                                )}
                                <StyledView style={styles.containerAdditionals}>
                                    {dataTableAdditionals.length !== 0 ? (
                                        <StyledView>
                                            <H4 style={styles.titleAdditionals}>Adicionales del proyecto</H4>
                                            <Table borderStyle={{ borderWidth: 1, borderColor: Colors.LIGHT_GRAY }}>
                                                <Row
                                                    data={tableHeadAdditionals}
                                                    style={StyleSheet.flatten(styles.header)}
                                                    textStyle={StyleSheet.flatten(styles.textHeader)}
                                                />
                                                <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                    <Rows
                                                        data={dataTableAdditionals}
                                                        style={StyleSheet.flatten(styles.row)}
                                                        textStyle={StyleSheet.flatten(styles.text)}
                                                    />
                                                </TableWrapper>
                                            </Table>
                                        </StyledView>
                                    ) : (
                                        <StyledText style={styles.centerText}>
                                            No hay adicionales del proyecto
                                        </StyledText>
                                    )}
                                </StyledView>
                                {!isLoading && totalAdditional != 0 && (
                                    <StyledView style={styles.container100} alignItems="end">
                                        <H5>Total Adicionales: {formatter.format(totalAdditional)}</H5>
                                    </StyledView>
                                )}
                                {totalDisplay()}
                                {dataProject.status == 0 && (
                                    <StyledView style={styles.containerAdd}>
                                        <StyledButton
                                            buttonType="nummaBlue"
                                            textSize={16}
                                            style={styles.addButtonContainer}
                                            onPress={toggleCreateModal}
                                        >
                                            {translate("sentence", "ADD_PRODUCT")}
                                        </StyledButton>
                                    </StyledView>
                                )}
                            </Card>
                        </>
                    ) : (
                        <Card size="large" style={styles.titleCardMobile}>
                            <StyledView style={styles.containerTextMobile}>
                                <H5 color={Colors.BLACK}>{dataProject.name}</H5>
                            </StyledView>
                            <StyledView style={styles.containerTextMobile}>
                                <StyledText size={14} color={Colors.GRAY}>
                                    {dataProject.client_name}
                                </StyledText>
                            </StyledView>
                            {!isLoading && (
                                <>
                                    {dataProject.client_phone && (
                                        <StyledView style={styles.containerTextMobile}>
                                            <StyledText
                                                size={14}
                                                color={Colors.GRAY}
                                                onPress={() => {
                                                    Linking.openURL(`tel:${dataProject.client_phone}`);
                                                }}
                                            >
                                                Tel. {dataProject.client_phone}
                                            </StyledText>
                                        </StyledView>
                                    )}
                                    {(dataProject.client_street ||
                                        dataProject.client_number ||
                                        dataProject.client_suburb) && (
                                        <StyledView style={styles.containerTextMobile}>
                                            <StyledText size={14} color={Colors.GRAY}>
                                                <StyledIcon
                                                    name="location-outline"
                                                    iconType="Ionicons"
                                                    color={Colors.GRAY}
                                                />{" "}
                                                {dataProject.client_street}{" "}
                                                {dataProject.client_number && `#${dataProject.client_number}`}{" "}
                                                {dataProject.client_suburb && `Col. ${dataProject.client_suburb}.`}
                                            </StyledText>
                                        </StyledView>
                                    )}
                                    {dataProject.zip_code && (
                                        <StyledView style={styles.containerTextMobile}>
                                            <StyledText size={14} color={Colors.GRAY}>
                                                CP. {dataProject.zip_code}
                                            </StyledText>
                                        </StyledView>
                                    )}
                                    {(dataProject.client_state || dataProject.client_city) && (
                                        <StyledView style={styles.containerTextMobile}>
                                            <StyledText size={14} color={Colors.GRAY}>
                                                {dataProject.client_state && dataProject.client_state
                                                    ? `${dataProject.client_city}, ${dataProject.client_state}`
                                                    : dataProject.client_state
                                                    ? dataProject.client_state
                                                    : dataProject.client_city}
                                            </StyledText>
                                        </StyledView>
                                    )}
                                </>
                            )}
                            <StyledView style={styles.containerProjectMobile} alignItems="center">
                                <StyledView flexDirection="row" justifyContent="space-between" alignItems="center">
                                    <StyledButton
                                        style={styles.tableButtonContainer}
                                        buttonType="lightGray"
                                        textSize={12}
                                        textFont="regular"
                                        textColor="nummaBlue"
                                        onPress={() => {
                                            downloadProject(false);
                                        }}
                                    >
                                        {translate("sentence", "DOWNLOAD_PDF")}{" "}
                                        <StyledIcon name="download" iconType="FontAwesome" color="nummaBlue" />
                                    </StyledButton>
                                    <StyledButton
                                        style={styles.tableButtonContainer}
                                        buttonType="lightGray"
                                        textSize={12}
                                        textFont="regular"
                                        textColor="nummaBlue"
                                        onPress={() => {
                                            downloadProject(true);
                                        }}
                                    >
                                        {translate("sentence", "DOWNLOAD_QUOTE_PDF")}{" "}
                                        <StyledIcon name="download" iconType="FontAwesome" color="nummaBlue" />
                                    </StyledButton>
                                    <StyledButton
                                        buttonType="nummaBlue"
                                        textSize={12}
                                        style={styles.addButtonContainer}
                                        onPress={toggleUpdateModal}
                                    >
                                        {translate("verb", "EDIT")}
                                    </StyledButton>
                                </StyledView>
                            </StyledView>
                            <StyledView style={styles.container100}>
                                {!isLoading && (
                                    <>
                                        {dataTablePersiana.length != 0 &&
                                            dataTablePersiana?.map((persiana, index) => {
                                                return (
                                                    <CardProductMobile
                                                        key={index}
                                                        typeProduct={1}
                                                        dataArray={persiana}
                                                    />
                                                );
                                            })}
                                        {dataTableT90.length !== 0 &&
                                            dataTableT90?.map((T90, index) => {
                                                return (
                                                    <CardProductMobile key={index} typeProduct={2} dataArray={T90} />
                                                );
                                            })}
                                        {dataTableTvertical.length !== 0 &&
                                            dataTableTvertical?.map((TVertical, index) => {
                                                return (
                                                    <CardProductMobile
                                                        key={index}
                                                        typeProduct={3}
                                                        dataArray={TVertical}
                                                    />
                                                );
                                            })}
                                        {dataTableTproyeccion.length !== 0 &&
                                            dataTableTproyeccion?.map((TProyeccion, index) => {
                                                return (
                                                    <CardProductMobile
                                                        key={index}
                                                        typeProduct={4}
                                                        dataArray={TProyeccion}
                                                    />
                                                );
                                            })}
                                        {dataTableCortina.length !== 0 &&
                                            dataTableCortina?.map((Cortina, index) => {
                                                return (
                                                    <CardProductMobile
                                                        key={index}
                                                        typeProduct={5}
                                                        dataArray={Cortina}
                                                    />
                                                );
                                            })}
                                        {totalProducts == 0 && (
                                            <StyledText style={styles.centerText}>No hay productos</StyledText>
                                        )}
                                        {totalProducts != 0 && (
                                            <StyledView style={styles.container100} alignItems="end">
                                                <H5>Total productos: {formatter.format(totalProducts)}</H5>
                                            </StyledView>
                                        )}

                                        <StyledView style={styles.containerAdditionals}>
                                            {dataTableAdditionals.length !== 0 ? (
                                                <StyledView>
                                                    <H4 style={styles.titleAdditionals}>Adicionales del proyecto</H4>
                                                    <Table
                                                        borderStyle={{
                                                            borderWidth: 1,
                                                            borderColor: Colors.LIGHT_GRAY,
                                                        }}
                                                    >
                                                        <Row
                                                            data={tableHeadAdditionals}
                                                            style={StyleSheet.flatten(styles.header)}
                                                            textStyle={StyleSheet.flatten(styles.textHeader)}
                                                        />
                                                        <TableWrapper style={StyleSheet.flatten(styles.dataWrapper)}>
                                                            <Rows
                                                                data={dataTableAdditionals}
                                                                style={StyleSheet.flatten(styles.row)}
                                                                textStyle={StyleSheet.flatten(styles.text)}
                                                            />
                                                        </TableWrapper>
                                                    </Table>
                                                </StyledView>
                                            ) : (
                                                <StyledText style={styles.centerText}>
                                                    No hay adicionales del proyecto
                                                </StyledText>
                                            )}
                                        </StyledView>

                                        {totalAdditional != 0 && (
                                            <StyledView style={styles.container100} alignItems="end">
                                                <H5>Total Adicionales: {formatter.format(totalAdditional)}</H5>
                                            </StyledView>
                                        )}
                                        {totalDisplay()}
                                        {dataProject.status == 0 && (
                                            <>
                                                <StyledView style={styles.containerAddMobile}>
                                                    <StyledButton
                                                        buttonType="lightGray"
                                                        textColor="primary"
                                                        textSize={16}
                                                        style={styles.addButtonContainer}
                                                        onPress={() => toggleCreateModal()}
                                                    >
                                                        <StyledIcon
                                                            name="plus-circle"
                                                            size={14}
                                                            iconType="FontAwesome5"
                                                            color="primary"
                                                        />{" "}
                                                        {translate("sentence", "ADD_PRODUCT")}
                                                    </StyledButton>
                                                </StyledView>
                                            </>
                                        )}
                                    </>
                                )}
                            </StyledView>
                        </Card>
                    )}
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    screen: {},
    titleCard: {
        alignItems: "start",
        marginTop: 30,
    },
    title: {
        marginLeft: 20,
    },
    bodyCard: {
        marginTop: 30,
        paddingHorizontal: 30,
        paddingVertical: 30,
    },
    container100: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
    },
    containerAdditionals: {
        width: "100%",
        marginTop: 30,
    },
    titleAdditionals: {
        marginBottom: 30,
    },
    containerInfo: {
        borderBottomWidth: 1,
        borderBottomColor: Colors.LIGHT_GRAY,
        paddingVertical: 5,
    },
    textInfo: {
        marginLeft: 10,
    },
    header: {
        height: 50,
        backgroundColor: Colors.WHITE,
    },
    textHeader: {
        textAlign: "center",
        fontFamily: Fonts.PRIMARY.BOLD,
    },
    text: {
        textAlign: "center",
    },
    dataWrapper: {
        marginTop: -1,
    },
    row: {
        height: 40,
        backgroundColor: Colors.WHITE,
    },
    containerAdd: {
        alignSelf: "start",
        marginTop: 10,
    },
    addButtonContainer: {
        paddingVertical: 5,
    },
    tableButtonContainer: {
        height: 35,
        paddingVertical: 5,
        color: Colors.WHITE,
    },
    centerText: {
        alignSelf: "center",
    },
    containerForm: {
        width: "100%",
        marginTop: "auto",
        marginBottom: "auto",
        paddingRight: 6,
    },
    screenMobile: {
        paddingHorizontal: 0,
        paddingBottom: 0,
    },
    containerTextMobile: {
        width: "100%",
        textAlign: "center",
    },
    titleCardMobile: {
        alignItems: "start",
        marginTop: 30,
        backgroundColor: Colors.LIGHT_GRAY,
        paddingHorizontal: "5%",
    },
    containerProjectMobile: {
        width: "100%",
        marginVertical: 15,
    },
    containerProductLineMobile: {
        width: "90%",
        borderBottomColor: Colors.LIGHT_GRAY,
        borderBottomWidth: 1,
        paddingVertical: 5,
    },
    containerProductInnerMobile: {
        width: "80%",
    },
    containerCardProduct: {
        width: "100%",
        marginVertical: 15,
    },
    containerAddMobile: {
        alignSelf: "center",
        marginTop: 10,
    },
    tableButtonContainerMobile: {
        paddingVertical: 1,
        color: Colors.WHITE,
    },
    rowTotal: {
        display: "inline",
    },
    marginLeftTotal: {
        width: 150,
        display: "inline-block",
        textAlign: "end",
    },
});

export default DetailsScreen;
