import { isValidElement } from 'react';
import { StyleSheet } from 'react-native';

import { Colors } from '../../constants';

import Loading from './Loading';
import { StyledModal, StyledText } from './Styled';

const LoadingModal = (props) => {
    const { children } = props;

    const content = isValidElement(children) ? (
        children
    ) : (
        <StyledText font="bold" size={16} color="darkGray" style={props.textStyle}>
            {children ?? `${'CARGANDO'}...`}
        </StyledText>
    );

    return (
        <StyledModal
            animationType="fade"
            {...props}
            containerStyle={{ ...styles.container, ...props.containerStyle }}
            cardStyle={{ ...styles.card, ...props.cardStyle }}
        >
            {content}
            <Loading
                size={props.loadingIconSize ?? 'large'}
                color={props.loadingIconColor ?? Colors.PRIMARY}
                style={{ ...styles.loadingIcon, ...props.loadingIconStyle }}
            />
        </StyledModal>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    card: {
        paddingHorizontal: 25,
        paddingVertical: 25,
        borderRadius: 10
    },
    loadingIcon: {
        marginTop: 20
    }
});

export default LoadingModal;
