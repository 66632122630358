import { Platform } from 'react-native';

import { Colors } from '../constants';

const isIos = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';

const app = {
    primary: Colors.PRIMARY,
    secondary: Colors.SECONDARY,
    success: Colors.SUCCESS,
    danger: Colors.DANGER,
    warning: Colors.WARNING,
    info: Colors.INFO,
    default: Colors.DEFAULT,
    nummaBlue: Colors.NUMMA_BLUE,
    nummaYellow: Colors.NUMMA_YELLOW,
    skyBlue: Colors.SKY_BLUE,
    aquaBlue: Colors.AQUA_BLUE,
    orange: Colors.ORANGE,
    cherry: Colors.CHERRY,
    gray: Colors.GRAY,
    shadowGray: Colors.SHADOW_GRAY,
    darkGray: Colors.DARK_GRAY,
    lightGray: Colors.LIGHT_GRAY,
    black: Colors.BLACK,
    white: Colors.WHITE
};

const screen = { background: Colors.SECONDARY };

const header = {
    background: isIos ? Colors.WHITE : Colors.WHITE,
    tint: isIos ? Colors.WHITE : Colors.WHITE
};

const bottomTabs = {
    background: isAndroid ? Colors.WHITE : Colors.WHITE,
    tint: isAndroid ? Colors.WHITE : Colors.WHITE,
    activeTint: isAndroid ? Colors.PRIMARY : Colors.PRIMARY
};

const card = {
    background: Colors.WHITE,
    shadow: Colors.BLACK,
    shadowOpacity: 0.25
};

const h1 = { font: Colors.PRIMARY };

const h2 = { font: Colors.PRIMARY };

const h2Border = { backgroundColor: Colors.ORANGE };

const h3 = { font: Colors.PRIMARY };

const h4 = { font: Colors.PRIMARY };

const h5 = { font: Colors.BLACK };

const h6 = { font: Colors.PRIMARY };

const paragraph = { font: Colors.DARK_GRAY };

const label = { font: Colors.GRAY };

const text = { font: Colors.DARK_GRAY };

const inputContainer = {
    borderColor: Colors.GRAY,
    background: Colors.WHITE
};

const input = {
    font: Colors.DARK_GRAY,
    placeholderText: Colors.GRAY,
    selection: Colors.GRAY,
    opacity: 0.7
};

const inputIcon = {
    icon: Colors.GRAY,
    opacity: 0.7
};

const checkbox = {
    opacity: 0.5
};

const checkboxLabel = {
    font: Colors.DARK_GRAY,
    opacity: 0.5
};

const toggle = {
    trackColor: { false: Colors.GRAY, true: Colors.DARK_GRAY },
    thumbColor: isIos ? '' : Colors.DARK_GRAY,
    opacity: 0.5
};

const toggleLabel = {
    font: Colors.DARK_GRAY,
    opacity: 0.5
};

const buttonContainer = {
    background: Colors.GRAY,
    opacity: 0.7
};

const buttonText = {
    font: Colors.DARK_GRAY,
    opacity: 0.7
};

const fieldTitle = { font: Colors.DARK_GRAY };

const fieldLabel = { font: Colors.GRAY };

const listItemText = { font: Colors.DARK_GRAY };

const selectListSectionTitle = { font: Colors.BLACK };

const selectListItemTouchable = {
    backgroundColor: 'transparent',
    underlayColor: Colors.LIGHT_GRAY,
    opacity: 1
};

const selectListItemTitle = { font: Colors.DARK_GRAY };

const selectListItemTitleIcon = { icon: Colors.GRAY };

const selectListItemCheckIcon = { icon: Colors.ORANGE };

const settingsListSectionTitle = { font: Colors.BLACK };

const settingsListItemTouchable = {
    backgroundColor: 'transparent',
    underlayColor: Colors.LIGHT_GRAY,
    opacity: 1
};

const settingsListItemTitle = { font: Colors.DARK_GRAY };

const settingsListItemTitleIcon = { icon: Colors.GRAY };

const settingsListItemInfo = { font: Colors.GRAY };

const settingsListItemNavArrowIcon = { icon: Colors.GRAY };

const accordionHeader = {
    borderColor: Colors.GRAY,
    backgroundColor: Colors.WHITE
};

const accordionTitle = { font: Colors.DARK_GRAY };

const accordionOpenIcon = { icon: Colors.DARK_GRAY };

const accordionCloseIcon = { icon: Colors.DARK_GRAY };

const accordionBody = {
    borderColor: Colors.GRAY,
    backgroundColor: Colors.WHITE
};

const accordionDescription = { font: Colors.DARK_GRAY };

const progressBar = { backgroundColor: Colors.LIGHT_GRAY };

const icon = { icon: Colors.WHITE };

const divider = { backgroundColor: Colors.LIGHT_GRAY };

const touchableHighlight = { underlayColor: Colors.LIGHT_GRAY };

export {
    app,
    screen,
    header,
    bottomTabs,
    card,
    h1,
    h2,
    h2Border,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    inputContainer,
    input,
    inputIcon,
    checkbox,
    checkboxLabel,
    toggle,
    toggleLabel,
    buttonContainer,
    buttonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectListSectionTitle,
    selectListItemTouchable,
    selectListItemTitle,
    selectListItemTitleIcon,
    selectListItemCheckIcon,
    settingsListSectionTitle,
    settingsListItemTouchable,
    settingsListItemTitle,
    settingsListItemTitleIcon,
    settingsListItemInfo,
    settingsListItemNavArrowIcon,
    accordionHeader,
    accordionTitle,
    accordionOpenIcon,
    accordionCloseIcon,
    accordionBody,
    accordionDescription,
    progressBar,
    icon,
    divider,
    touchableHighlight
};

export default {
    app,
    screen,
    header,
    bottomTabs,
    card,
    h1,
    h2,
    h2Border,
    h3,
    h4,
    h5,
    h6,
    paragraph,
    label,
    text,
    inputContainer,
    input,
    inputIcon,
    checkbox,
    checkboxLabel,
    toggle,
    toggleLabel,
    buttonContainer,
    buttonText,
    fieldTitle,
    fieldLabel,
    listItemText,
    selectListSectionTitle,
    selectListItemTouchable,
    selectListItemTitle,
    selectListItemTitleIcon,
    selectListItemCheckIcon,
    settingsListSectionTitle,
    settingsListItemTouchable,
    settingsListItemTitle,
    settingsListItemTitleIcon,
    settingsListItemInfo,
    settingsListItemNavArrowIcon,
    accordionHeader,
    accordionTitle,
    accordionOpenIcon,
    accordionCloseIcon,
    accordionBody,
    accordionDescription,
    progressBar,
    icon,
    divider,
    touchableHighlight
};
