import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Platform, Image, View } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { Colors, Spacing, Typography } from '../../styles';

import { MenuButton } from '../../components/Layout/Header';
import { ProfileButton } from '../../components/Layout/Header';
import CalculatorScreen from '../../screens/calculator/CalculatorScreen';

const Stack = createStackNavigator();

const defaultScreenOptions = (navData) => {
    return {
        headerShown: false 
    };
};

const CalculatorNavigator = ({navigation}) => {

    const accessToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (!accessToken){
                navigation.navigate('AuthNav');
            }
        });
    
        return unsubscribe;
    }, [navigation]);

    return (
        <Stack.Navigator screenOptions={ defaultScreenOptions } >
            <Stack.Screen name="Calculator" component={CalculatorScreen} />
        </Stack.Navigator>
    );
};

export default CalculatorNavigator;
