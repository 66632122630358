import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Platform, Image } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import { Colors, Spacing, Typography } from '../../styles';

import { BackButton } from '../../components/Layout/Header';

import ProfileScreen from '../../screens/Profile/ProfileScreen';
import AccountScreen from '../../screens/Profile/AccountScreen';
import SecurityScreen from '../../screens/Profile/SecurityScreen';
import UpdateEmailScreen from '../../screens/Profile/UpdateEmailScreen';
import VerifyEmailScreen from '../../screens/Profile/VerifyEmailScreen';
import UpdatePasswordScreen from '../../screens/Profile/UpdatePasswordScreen';
import PricesScreen from '../../screens/Profile/PricesScreen';

const Stack = createStackNavigator();

const defaultScreenOptions = (navData) => {
    return {
        headerStyle: {
            backgroundColor: Colors.header.background
        },
        headerTitle: () => (
            <Image
                style={{
                    width: Spacing.headerLogo.width,
                    height: Spacing.headerLogo.height,
                    marginBottom: Spacing.headerLogo.marginBottom
                }}
                source={
                    Platform.OS === 'ios'
                        ? require('../../assets/images/logo-white.png')
                        : require('../../assets/images/logo.png')
                }
            />
        ),
        headerTitleStyle: { ...Typography.headerTitle },
        headerTitleAlign: 'center',
        headerBackTitleStyle: { ...Typography.headerBackTitle },
        headerTintColor: Colors.header.tint,
        headerLeft: () => <BackButton {...navData} />,
        gestureDirection: 'horizontal-inverted',
        headerShown: false
    };
};

const ProfileNavigator = ({navigation}) => {

    const accessToken = useSelector((state) => state.auth.token);

    useEffect(() => {
        const unsubscribe = navigation.addListener('focus', () => {
            if (!accessToken) {
                navigation.navigate('AuthNav');
            }
        });

        return unsubscribe;
    }, [navigation]);

    return (
        <Stack.Navigator screenOptions={defaultScreenOptions}>
            <Stack.Screen name="Profile" component={ProfileScreen} />
            <Stack.Screen name="Account" component={AccountScreen} />
            <Stack.Screen name="Security" component={SecurityScreen} />
            <Stack.Screen name="UpdateEmail" component={UpdateEmailScreen} />
            <Stack.Screen name="VerifyEmail" component={VerifyEmailScreen} />
            <Stack.Screen name="UpdatePassword" component={UpdatePasswordScreen} />
            <Stack.Screen name="Prices" component={PricesScreen} />
        </Stack.Navigator>
    );
};

export default ProfileNavigator;
