const PRIMARY = '#DA6A16';
const SECONDARY = '#ffede1';

const SUCCESS = '#28a745';
const DANGER = '#dc3545';
const WARNING = '#ffc107';
const INFO = '#17a2b8';
const DEFAULT = '#6c757d';

const NUMMA_BLUE = '#6596D0';
const NUMMA_YELLOW = '#fff7d7';
const NUMMA_DARK_YELLOW = '#FED636';
const SKY_BLUE = '#c2fafb';
const AQUA_BLUE = '#59c3de';
const ORANGE = '#ff693d';
const CHERRY = '#9f2544';
const GRAY = '#7f7f7f';
const SHADOW_GRAY = '#dddddd';
const DARK_GRAY = '#303030';
const LIGHT_GRAY = '#f7f7fa';
const BLACK = '#000000';
const WHITE = '#ffffff';

export {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    DANGER,
    WARNING,
    INFO,
    DEFAULT,
    NUMMA_BLUE,
    NUMMA_YELLOW,
    NUMMA_DARK_YELLOW,
    SKY_BLUE,
    AQUA_BLUE,
    ORANGE,
    CHERRY,
    GRAY,
    SHADOW_GRAY,
    DARK_GRAY,
    LIGHT_GRAY,
    BLACK,
    WHITE
};

export default {
    PRIMARY,
    SECONDARY,
    SUCCESS,
    DANGER,
    WARNING,
    INFO,
    DEFAULT,
    NUMMA_BLUE,
    NUMMA_YELLOW,
    NUMMA_DARK_YELLOW,
    SKY_BLUE,
    AQUA_BLUE,
    ORANGE,
    CHERRY,
    GRAY,
    SHADOW_GRAY,
    DARK_GRAY,
    LIGHT_GRAY,
    BLACK,
    WHITE
};
