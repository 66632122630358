import { Platform, StyleSheet, KeyboardAvoidingView } from 'react-native';

import { Spacing } from '../../styles';

const AvoidKeyboard = ({ children }) => {
    return (
        <KeyboardAvoidingView
            style={styles.view}
            behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
            keyboardVerticalOffset={Spacing.keyboard.offset}
        >
            {children}
        </KeyboardAvoidingView>
    );
};

const styles = StyleSheet.create({
    view: {
        flex: 1
    }
});

export default AvoidKeyboard;
