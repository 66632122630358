import Colors from './colors';
import Spacing from './spacing';

const screen = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: Spacing.screen.paddingHorizontal,
    paddingVertical: Spacing.screen.paddingVertical,
    backgroundColor: Colors.screen.background
};

const container = {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.container.width
};

const containerWeb = {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Spacing.containerWeb.width
};

const fluidView = {
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    width: Spacing.fluidView.width
};

const modal = {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
};

export { screen, container, containerWeb, fluidView, modal };

export default { screen, container, containerWeb, fluidView, modal };
