import { Platform } from 'react-native';

const isIos = Platform.OS === 'ios';

const header = {
    back: isIos ? 'ios-chevron-back' : 'md-chevron-back',
    menu: isIos ? 'ios-menu' : 'md-menu',
    profile: isIos ? 'ios-person-circle-outline' : 'md-person-circle-outline',
    close: isIos ? 'ios-close' : 'md-close'
};

const bottomTabs = {
    home: isIos ? 'ios-home-outline' : 'md-home-outline',
    today: isIos ? 'ios-calendar-outline' : 'md-calendar-outline',
    explore: isIos ? 'ios-search-outline' : 'md-search-outline',
    properties: isIos ? 'ios-list-outline' : 'md-list-outline',
    services: isIos ? 'ios-construct-outline' : 'md-construct-outline',
    messages: isIos
        ? 'ios-chatbox-ellipses-outline'
        : 'md-chatbox-ellipses-outline'
};

const modal = {
    close: isIos ? 'ios-close' : 'md-close'
};

const input = {
    showPassword: isIos ? 'ios-eye' : 'md-eye',
    hidePassword: isIos ? 'ios-eye-off' : 'md-eye-off'
};

const selectList = {
    check: isIos ? 'ios-checkmark-sharp' : 'md-checkmark-sharp'
};

const settingsList = {
    navArrow: isIos ? 'ios-chevron-forward' : 'md-chevron-forward'
};

const accordion = {
    open: isIos ? 'ios-chevron-down' : 'md-chevron-down',
    close: isIos ? 'ios-chevron-up' : 'md-chevron-up'
};

export {
    header,
    bottomTabs,
    modal,
    input,
    selectList,
    settingsList,
    accordion
};

export default {
    header,
    bottomTabs,
    modal,
    input,
    selectList,
    settingsList,
    accordion
};
