import { useState, useEffect } from "react";
import { Platform, StyleSheet, useWindowDimensions } from "react-native";
import { useSelector, useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";

import { RegularExpressions } from "../../constants";

import { Spacing } from "../../styles";

import { useLanguage } from "../../hooks";

import { appActions } from "../../store/slices/app";

import User from "../../models/User";

import { Wrapper, Screen, Container, FluidView, LoadingModal, Field } from "../../components/UI";
import { StyledModal, StyledButton } from "../../components/UI/Styled";
import FormTextInput from "../../components/Profile/FormTextInput";
import { BackButton } from "../../components/Layout/Header";
import { StyledView } from "../../components/UI/Styled";
import FormButton from "../../components/Profile/FormButton";

const isIos = Platform.OS === "ios";

const UpdatePasswordScreen = ({ navigation }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showCurrentPassword, setShowCurrentPassword] = useState(true);
    const [showPassword, setShowPassword] = useState(true);
    const [showConfirmPassword, setShowConfirmPassword] = useState(true);

    const accessToken = useSelector((state) => state.auth.token);

    const dispatch = useDispatch();

    const layout = useWindowDimensions();

    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm({
        mode: "onBlur",
        defaultValues: { currentPassword: "", password: "", confirmPassword: "" },
    });

    const { translate } = useLanguage();

    const user = new User({
        apiConfig: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    const toggleIsLoading = () => {
        setIsLoading((prevIsLoading) => !prevIsLoading);
    };

    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword((prevShowCurrentPassword) => !prevShowCurrentPassword);
    };

    const toggleShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword((prevShowConfirmPassword) => !prevShowConfirmPassword);
    };

    const removeError = () => {
        setError(null);
    };

    const cancelHandler = () => {
        navigation.goBack();
    };

    const saveHandler = async (formData) => {
        try {
            toggleIsLoading();

            await user.updateMyPassword({
                actual_password: formData.currentPassword,
                password: formData.password,
                confirm_password: formData.confirmPassword,
            });

            toggleIsLoading();

            navigation.navigate("Security");
        } catch (e) {
            toggleIsLoading();

            switch (e?.response?.status) {
                case 400:
                    setError(translate("sentence", "WRONG_CURRENT_PASSWORD"));
                    break;
                case 401:
                    dispatch(appActions.notReady());
                    break;
                default:
                    setError(translate("sentence", "SOMETHING_WENT_WRONG"));
                    break;
            }
        }
    };

    const CancelButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={cancelHandler}
            style={styles.cancelButtonContainer}
        >
            {translate("verb", "CANCEL")}
        </StyledButton>
    );

    const SaveButton = (
        <StyledButton
            textFont={"bold"}
            textSize={18}
            textColor={isIos ? "white" : "white"}
            onPress={handleSubmit(saveHandler)}
            style={styles.saveButtonContainer}
        >
            {translate("verb", "SAVE")}
        </StyledButton>
    );

    useEffect(() => {
        navigation.setOptions({
            headerLeft: () => CancelButton,
            headerRight: () => SaveButton,
        });
    }, []);

    return (
        <Wrapper>
            <LoadingModal visible={isLoading} />
            <StyledModal
                modalType="alert"
                visible={!!error}
                size="medium"
                title={translate("word", "ERROR")}
                titleColor="danger"
                onAccept={removeError}
                onPressOut={removeError}
                onRequestClose={removeError}
            >
                {error}
            </StyledModal>
            <Screen>
                <Container style={styles.container}>
                    <StyledView style={styles.backButton}>
                        <BackButton style={styles.backButton} {...{ navigation }} />
                    </StyledView>
                    <FluidView style={layout.width > 768 ? styles.inputsContainer : styles.inputsContainerMobile}>
                        <Controller
                            control={control}
                            name="currentPassword"
                            rules={{
                                required: true,
                            }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    title={translate("word", "CURRENT_PASSWORD")}
                                    label={
                                        errors.currentPassword?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_YOUR_PASSWORD")
                                            : ""
                                    }
                                    labelColor={errors.currentPassword ? "danger" : undefined}
                                    style={styles.formField}
                                >
                                    <FormTextInput
                                        inputType={errors.currentPassword ? "danger" : ""}
                                        showPassword={showCurrentPassword}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        placeholder={translate("word", "CURRENT_PASSWORD")}
                                        value={value}
                                        onShowPassword={toggleShowCurrentPassword}
                                        onHidePassword={toggleShowCurrentPassword}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="password"
                            rules={{
                                required: true,
                                pattern: RegularExpressions.MODERATE_PASSWORD,
                            }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    title={translate("word", "PASSWORD")}
                                    label={
                                        errors.password?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_YOUR_PASSWORD")
                                            : errors.password?.type === "pattern"
                                            ? translate("sentence", "PLEASE_ENTER_A_MODERATE_PASSWORD")
                                            : ""
                                    }
                                    labelColor={errors.password ? "danger" : undefined}
                                    style={styles.formField}
                                >
                                    <FormTextInput
                                        inputType={errors.password ? "danger" : ""}
                                        showPassword={showPassword}
                                        returnKeyType="next"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        placeholder={translate("word", "PASSWORD")}
                                        value={value}
                                        onShowPassword={toggleShowPassword}
                                        onHidePassword={toggleShowPassword}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                        <Controller
                            control={control}
                            name="confirmPassword"
                            rules={{
                                required: true,
                                validate: (value) => value === getValues("password"),
                            }}
                            render={({ field: { value, onChange, onBlur } }) => (
                                <Field
                                    title={translate("word", "CONFIRM_PASSWORD")}
                                    label={
                                        errors.confirmPassword?.type === "required"
                                            ? translate("sentence", "PLEASE_ENTER_YOUR_PASSWORD")
                                            : errors.confirmPassword?.type === "validate"
                                            ? translate("sentence", "PASSWORDS_DO_NOT_MATCH")
                                            : ""
                                    }
                                    labelColor={errors.confirmPassword ? "danger" : undefined}
                                >
                                    <FormTextInput
                                        inputType={errors.confirmPassword ? "danger" : ""}
                                        showPassword={showConfirmPassword}
                                        returnKeyType="done"
                                        autoCorrect={false}
                                        autoCapitalize="none"
                                        placeholder={translate("word", "CONFIRM_PASSWORD")}
                                        value={value}
                                        onShowPassword={toggleShowConfirmPassword}
                                        onHidePassword={toggleShowConfirmPassword}
                                        onChangeText={onChange}
                                        onBlur={onBlur}
                                    />
                                </Field>
                            )}
                        />
                    </FluidView>
                    <FormButton
                        disabled={isLoading}
                        isLoading={isLoading}
                        style={styles.formButton}
                        onPress={handleSubmit(saveHandler)}
                    >
                        {translate("sentence", "SAVE")}
                    </FormButton>
                </Container>
            </Screen>
        </Wrapper>
    );
};

const styles = StyleSheet.create({
    cancelButtonContainer: {
        marginLeft: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    saveButtonContainer: {
        marginRight: 20,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderRadius: 0,
        backgroundColor: "transparent",
    },
    container: {
        maxWidth: Spacing.screen.maxWidth,
    },
    inputsContainer: {
        marginTop: 20,
    },
    formField: {
        marginBottom: 20,
    },
    backButton: {
        alignSelf: "start",
    },
    formButton: {
        marginTop: 20,
        borderRadius: 30,
    },
    inputsContainerMobile: {
        marginTop: 50
    }
});

export default UpdatePasswordScreen;
