import { useState } from 'react';
import { Provider } from 'react-redux';

import store from './src/store';


import AppNavigator from './src/navigation/AppNavigator';

import LoadingScreen from './src/screens/LoadingScreen';

const App = () => {
    const [assetsLoaded, setAssetsLoaded] = useState(false);

    if (!assetsLoaded) {
        return (
            <LoadingScreen
                onFinish={() => {
                    setAssetsLoaded(true);
                }}
                onError={() => { }}
            />
        );
    }

    return (
        <Provider store={store}>
            <AppNavigator />
        </Provider>
    );
};

export default App;
