import { useState } from "react";
import { StyleSheet, Image, TouchableOpacity, useWindowDimensions, TouchableWithoutFeedback } from "react-native";
import { useSelector } from "react-redux";
import { useRoute } from "@react-navigation/native";

import { Colors } from "../../../constants";

import { StyledView, StyledLink, StyledIcon, StyledText } from "../../UI/Styled";

const Navbar = (props) => {
    const { whiteNavbar = false } = props;
    const userState = useSelector((state) => state.user);
    const layout = useWindowDimensions();
    const [isActiveMenu, setisActiveMenu] = useState(false);
    const route = useRoute();

    const routeName = () => {
        let name = "";
        switch (route.name) {
            case "Projects":
            case "Details":
                name = "Proyectos";
                break;
            case "Profile":
            case "Account":
            case "Security":
            case "UpdateEmail":
            case "VerifyEmail":
            case "UpdatePassword":
                name = "Mi perfil";
                break;
            case "Calculator":
                name = "Calculadora";
                break;
        }
        return name;
    };

    const changeMenu = () => {
        setisActiveMenu(!isActiveMenu);
    };

    return layout.width > 768 ? (
        <StyledView style={styles.container} flexDirection="row" justifyContent="space-around" alignItems="center">
            <StyledView>
                <Image source={require("../../../assets/images/logo.png")} style={styles.logo} />
            </StyledView>
            <StyledView style={styles.links} flexDirection="row" justifyContent="space-around" alignItems="center">
                <StyledLink to={{ screen: "ProjectNav" }}>PROYECTOS</StyledLink>
                <StyledLink to={{ screen: "CalculatorNav" }}>CALCULADORA</StyledLink>
                <StyledLink to={{ screen: "ProfileNav" }}>MI PERFIL</StyledLink>
            </StyledView>
        </StyledView>
    ) : (
        <>
            <TouchableWithoutFeedback onPress={changeMenu}>
                <StyledView style={isActiveMenu ? styles.overlayActive : styles.overlay} />
            </TouchableWithoutFeedback>
            <StyledView style={whiteNavbar ? styles.containerMenuWhite : styles.containerMenu}>
                <StyledView style={isActiveMenu ? styles.activeMenu : styles.menuMobil}>
                    <StyledLink
                        style={routeName() == "Mi perfil" ? styles.menuItemActive : styles.menuItem}
                        onPress={changeMenu}
                        to={{ screen: "ProfileNav" }}
                    >
                        <StyledView width="100%" flexDirection="row" justifyContent="left" alignItems="center">
                            <StyledView>
                                <StyledIcon
                                    iconType="FontAwesome"
                                    style={styles.iconMenu}
                                    name="user-circle-o"
                                    size={25}
                                    color={Colors.NUMMA_DARK_YELLOW}
                                />
                            </StyledView>
                            <StyledView>
                                {(userState.firstName || userState.lastName) && (
                                    <StyledText font="bold" style={styles.menuText}>
                                        {userState.firstName ?? ""} {userState.lastName ?? ""}
                                    </StyledText>
                                )}
                                <StyledText style={styles.menuText}>{userState.email}</StyledText>
                            </StyledView>
                        </StyledView>
                    </StyledLink>
                    <StyledLink
                        style={routeName() == "Proyectos" ? styles.menuItemActive : styles.menuItem}
                        onPress={changeMenu}
                        to={{ screen: "ProjectNav" }}
                    >
                        <StyledView width="100%" flexDirection="row" justifyContent="left" alignItems="center">
                            <StyledView>
                                <StyledIcon
                                    iconType="FontAwesome"
                                    style={styles.iconMenu}
                                    name="folder"
                                    size={25}
                                    color={Colors.NUMMA_DARK_YELLOW}
                                />
                            </StyledView>
                            <StyledView>
                                <StyledText style={styles.menuText}>Proyectos</StyledText>
                            </StyledView>
                        </StyledView>
                    </StyledLink>
                    <StyledLink
                        style={routeName() == "Calculadora" ? styles.menuItemActive : styles.menuItem}
                        onPress={changeMenu}
                        to={{ screen: "CalculatorNav" }}
                    >
                        <StyledView width="100%" flexDirection="row" justifyContent="left" alignItems="center">
                            <StyledView>
                                <StyledIcon
                                    iconType="FontAwesome"
                                    style={styles.iconMenu}
                                    name="calculator"
                                    size={25}
                                    color={Colors.NUMMA_DARK_YELLOW}
                                />
                            </StyledView>
                            <StyledView>
                                <StyledText style={styles.menuText}>Calculadora</StyledText>
                            </StyledView>
                        </StyledView>
                    </StyledLink>
                    <StyledView style={styles.menuLogoContainer}>
                        <Image source={require("../../../assets/images/logo.png")} style={styles.menuLogo} />
                    </StyledView>
                </StyledView>
                <StyledView style={styles.containerIconMenu}>
                    <TouchableOpacity accessible={false} onPress={changeMenu}>
                        <StyledIcon iconType="FontAwesome" name="bars" size={30} color="back" />
                    </TouchableOpacity>
                </StyledView>
                <StyledView style={styles.containerTitle}>
                    <StyledText font="title" size={30}>
                        {routeName()}
                    </StyledText>
                </StyledView>
                <StyledView style={styles.containerMobil}>
                    <Image source={require("../../../assets/images/logo-white.png")} style={styles.logoMobil} />
                </StyledView>
            </StyledView>
        </>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 120,
        backgroundColor: Colors.WHITE,
    },
    containerMenu: {
        opacity: 0.95,
        height: 60,
        backgroundColor: Colors.SECONDARY,
        position: "fixed",
        display: "block",
        position: "relative",
        zIndex: 2,
    },
    containerMenuWhite: {
        opacity: 0.95,
        height: 70,
        backgroundColor: Colors.WHITE,
        position: "fixed",
        display: "block",
        position: "relative",
        zIndex: 2,
    },
    logo: {
        height: 100,
        width: 140,
    },
    logoMobil: {
        height: 50,
        width: 50,
        float: "right",
    },
    logoMenu: {
        height: 50,
        width: 50,
    },
    containerIconMenu: {
        float: "left",
        display: "block",
        position: "absolute",
        top: 15,
        left: 10,
        zIndex: 5,
    },
    containerTitle: {
        display: "block",
        position: "absolute",
        top: 10,
        left: 0,
        right: 0,
        zIndex: 0,
        textAlign: "center",
    },
    containerMobil: {
        float: "right",
        display: "block",
        position: "absolute",
        top: 10,
        right: 10,
    },
    links: {
        width: "70%",
    },
    menuMobil: {
        transform: "translate(-150%)",
        paddingTop: 70,
        backgroundColor: "#fff",
        height: "100vh",
        width: "75vw",
        maxWidth: 300,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10,
        transition: "transform 0.5s ease-in-out",
        boxShadow: "5px 0px 10px 0px #aaa",
        zIndex: 1,
    },
    activeMenu: {
        transform: "translate(0)",
        paddingTop: 70,
        backgroundColor: "#fff",
        height: "100vh",
        width: "75vw",
        maxWidth: 300,
        display: "flex",
        flexDirection: "column",
        paddingLeft: 10,
        paddingRight: 10,
        transition: "transform 0.5s ease-in-out",
        boxShadow: "5px 0px 10px 0px #aaa",
        zIndex: 1,
    },
    menuText: {
        marginLeft: 10,
    },
    menuItem: {
        marginBottom: 10,
        fontSize: "1.3rem",
        fontWeight: 500,
        padding: 10,
    },
    menuItemActive: {
        marginBottom: 10,
        fontSize: "1.3rem",
        fontWeight: 500,
        padding: 10,
        backgroundColor: Colors.NUMMA_BLUE,
        borderRadius: 10,
    },
    menuLogoContainer: {
        width: 150,
        height: 50,
        position: "absolute",
        bottom: 0,
    },
    menuLogo: {
        height: "100%",
        width: "100%",
    },
    overlayActive: {
        visibility: "visible",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        opacity: 0.8,
        backgroundColor: "rgb(255,255,255)",
        zIndex: 1,
        backdropFilter: "blur(3px)",
        transition: "visibility 0.5s, opacity 0.5s",
    },
    overlay: {
        visibility: "hidden",
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        backgroundColor: "rgb(255,255,255)",
        zIndex: 1,
        backdropFilter: "blur(0px)",
        transition: "visibility 0.5s, opacity 0.5s",
    },
});

export default Navbar;
