import { useState } from 'react';
import {
  Platform,
  StyleSheet,
  TouchableWithoutFeedback,
  Image,
  useWindowDimensions
} from 'react-native';
import * as ImagePicker from 'expo-image-picker';

import { Colors } from '../../../constants';

import { useLanguage } from '../../../hooks';

import { FluidView } from '../../UI';
import {
  StyledView,
  StyledModal,
  StyledText,
  StyledIcon,
} from '../../UI/Styled';

const isIos = Platform.OS === 'ios';

const ProfilePicture = (props) => {
  const { profilePicture, onImageTaken, onImagePicked } = props;

  const [changeProfilePicture, setChangeProfilePicture] = useState(false);

  const { translate } = useLanguage();

  const layout = useWindowDimensions();

  const toggleChangeProfilePicture = () => {
    setChangeProfilePicture(
      (prevChangeProfilePicture) => !prevChangeProfilePicture
    );
  };

  const takeImageHandler = async () => {
    const permissionResult = await ImagePicker.requestCameraPermissionsAsync();

    if (permissionResult.granted === false) {
      return;
    }

    const image = await ImagePicker.launchCameraAsync({
      allowsEditing: true,
      aspect: [16, 9],
      quality: 0.5,
      base64: true
    });

    toggleChangeProfilePicture();

    if (!image?.cancelled && typeof onImageTaken === 'function') {
      onImageTaken(image.base64);
    }
  };

  const pickImageHandler = async () => {
    const permissionResult =
      await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permissionResult.granted === false) {
      return;
    }

    const image = await ImagePicker.launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [16, 9],
      quality: 0.5,
      base64: true
    });

    // toggleChangeProfilePicture();

    if (!image?.cancelled && typeof onImagePicked === 'function') {
      onImagePicked(image.base64);
    }
  };

  return (
    <StyledView style={props.style}>
      <StyledModal
        modalType="modal"
        visible={!!changeProfilePicture}
        size="large"
        onClose={toggleChangeProfilePicture}
        onPressOut={toggleChangeProfilePicture}
        onRequestClose={toggleChangeProfilePicture}
      >
        <FluidView
          flexDirection="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <StyledView>
            <TouchableWithoutFeedback
              accessible={false}
              onPress={pickImageHandler}
            >
              <StyledView style={styles.profilePictureIcon}>
                <StyledIcon
                  name={isIos ? 'ios-image' : 'md-image'}
                  size={25}
                  color="orange"
                />
              </StyledView>
            </TouchableWithoutFeedback>
            <StyledText
              font="bold"
              size={16}
              color="darkGray"
              style={styles.profilePictureIconText}
            >
              {translate('word', 'GALLERY')}
            </StyledText>
          </StyledView>
        </FluidView>
      </StyledModal>
      <StyledView alignItems="center">
        {profilePicture ? (
          <TouchableWithoutFeedback
            accessible={false}
            onPress={pickImageHandler}
          >
            <Image
              source={{ uri: profilePicture }}
              style={layout.width > 768 ? styles.profilePicture : styles.profilePictureMobile}
            />
          </TouchableWithoutFeedback>
        ) : (
          <Image
            source={require('../../../assets/images/profile-picture.png')}
            style={layout.width > 768 ? styles.profilePicture : styles.profilePictureMobile}
          />
        )}
        <TouchableWithoutFeedback
          accessible={false}
          onPress={pickImageHandler}
        >
          <StyledText
            font="bold"
            size={18}
            color="nummaBlue"
            style={styles.changeProfilePictureText}
          >
            {translate('sentence', 'CHANGE_PROFILE_PICTURE')}
          </StyledText>
        </TouchableWithoutFeedback>
      </StyledView>
    </StyledView>
  );
};

const styles = StyleSheet.create({
  profilePicture: {
    width: 300,
    height: 300,
    borderRadius: "100%",
    overflow: 'hidden'
  },
  changeProfilePictureText: {
    marginTop: 10
  },
  profilePictureIcon: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: Colors.LIGHT_GRAY,
    borderWidth: 1,
    borderRadius: 30,
    borderColor: Colors.DARK_GRAY,
    overflow: 'hidden'
  },
  profilePictureIconText: {
    marginTop: 10
  },
  profilePictureMobile: {
    width: 150  ,
    height: 150,
    borderRadius: "100%",
    overflow: 'hidden'
  },
});

export default ProfilePicture;
